export abstract class ArrayUtil {
  static sortAscending(list: string[]) {
    return list.sort((a, b) => Number(a) - Number(b));
  }

  /**
   * @description Remove an element from an array if it already exists, or add it if it doesn't
   * @param array Array
   * @param element Element
   */
  static toggle<T>(array: T[], element: T) {
    const arrayCopy = [...array];
    const index = arrayCopy.indexOf(element);

    if (index < 0) arrayCopy.push(element);
    else arrayCopy.splice(index, 1);

    return arrayCopy;
  }
}
