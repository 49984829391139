import { Calculation } from '../../store/domain/calculation';
import { toJS } from 'mobx';
//@ts-ignore
import CalculateMeasuresWorker from './calculate-measures.worker';
import {
  CalculateMeasuresPostMessageEventType,
  ICalculateMeasuresMessage,
  ICalculateMeasuresPostMessage
} from './calculate-measures-messages';
import { store } from '../../store/store';

export abstract class CalculateMeasuresHandler {
  static calculateMeasures(fileOrUrl: string | File, calculation: Calculation) {
    const worker: Worker = new CalculateMeasuresWorker();
    calculation.worker = worker;

    worker.postMessage({
      measures: toJS(calculation.measures),
      filters: toJS(calculation.activeFilters, { recurseEverything: true }),
      fileOrUrl,
      modelJson: toJS(calculation.model, { recurseEverything: true }),
      scenarioType: calculation.scenarioType,
      scenarios: toJS(calculation.activeScenarios, { recurseEverything: true }),
      debugger: toJS(store.debugger, { recurseEverything: true }),
      ByRowMeasures: toJS(store.algorithmMeasure.ByRowMeasures)
    } as ICalculateMeasuresMessage);

    worker.onmessage = (message: { data: ICalculateMeasuresPostMessage }) => {
      const { data } = message;

      switch (data.type) {
        case CalculateMeasuresPostMessageEventType.NewCalculation: {
          calculation.values.push(data.calculationValue);
          break;
        }

        case CalculateMeasuresPostMessageEventType.Done: {
          calculation.completeCalculatingMeasures(
            data.summaryValues,
            data.population,
            data.filteredOutRowCount
          );

          worker.terminate();
          break;
        }

        default:
          calculation.error = data.error;
          calculation.cancel();
          break;
      }
    };
  }
}
