import './by-row-tab.scss';

import React from 'react';

import { Calculation } from '../../../store/domain/calculation';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { c } from '../../../store/domain/c';
import { H5, HTMLTable } from '@blueprintjs/core';
import { AlgorithmMeasure } from '../../../store/domain/algorithm-measure';
import { Store } from '../../../store/store';

export interface IByRowTabProps {
  calculation: Calculation;
  algorithmMeasure?: AlgorithmMeasure;
}

@inject((store: Store) => {
  return {
    algorithmMeasure: store.algorithmMeasure
  };
})
@observer
@autobind
export class ByRowTab extends React.Component<IByRowTabProps> {
  public render() {
    const byRowMeasures = this.props.calculation.measures.filter((measure) =>
      this.props.algorithmMeasure!.ByRowMeasures.includes(measure)
    );

    return (
      <div>
        <H5>By Row Measures</H5>
        <HTMLTable bordered condensed striped>
          <thead>
            <tr>
              <th>Measure</th>
              <th>Label</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {byRowMeasures.map((measure) => (
              <tr key={measure}>
                <td>{measure}</td>
                <td>{c.measureToCsvHeaderMap[measure]}</td>
                <td>{this.props.calculation.getMeasureMean(measure)}</td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </div>
    );
  }
}
