import './respect-input-file-callout.scss';

import React from 'react';
import { Intent, Callout, Button, Divider } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { AnalysisData } from '../../../store/domain/analysis-data';
import { autobind } from 'core-decorators';
import { FileType } from '../../../store/domain/file-type';
import { OpenVariableManagerButton } from '../OpenVariableManagerButton';
import { RESPECTInputFileResultDrawer } from '../RESPECTInputFileResultDrawer';

export interface IRESPECTInputFileCalloutProps {
  analysisData: AnalysisData;
}

interface IRESPECTInputFileCalloutState {
  isOpen: boolean;
}

@observer
@autobind
export class RESPECTInputFileCallout extends React.Component<
  IRESPECTInputFileCalloutProps,
  IRESPECTInputFileCalloutState
> {
  constructor(props: IRESPECTInputFileCalloutProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  render() {
    const selectedAnalysisDataFile = this.props.analysisData!.selectedAnalysisDataFile!;

    let intent: Intent;
    let message: string;
    let icon: 'error' | 'warning-sign' | 'tick' | 'time';

    if (selectedAnalysisDataFile.hasLoadedVariables) {
      if (selectedAnalysisDataFile.fileType === FileType.None) {
        intent = Intent.DANGER;
        icon = 'error';
        message = 'The file type must be set on this file before it can be used.';
      } else {
        if (selectedAnalysisDataFile.hasAllVariables) {
          intent = Intent.SUCCESS;
          icon = 'tick';
          message = 'The selected file has all required and recommended variables.';
        } else if (selectedAnalysisDataFile.hasRequiredVariables) {
          intent = Intent.WARNING;
          icon = 'warning-sign';
          message =
            'The selected file is missing recommended variables. Calculations may be less accurate.';
        } else {
          intent = Intent.DANGER;
          icon = 'error';
          message =
            "The selected file is missing required variables, and can't be used for calculations.";
        }
      }
    } else {
      intent = Intent.NONE;
      icon = 'time';
      message = 'Loading file...';
    }

    return (
      <Callout intent={intent} icon={icon} title={selectedAnalysisDataFile.name}>
        <div>{message}</div>
        <div className='separated-buttons-container'>
          <Button text='Show file details' onClick={this.toggleInputFileResultDrawer} />
          <Divider className='standard-vertical-rule' />
          <OpenVariableManagerButton analysisDataFile={selectedAnalysisDataFile!} />
        </div>
        {this.state.isOpen && (
          <RESPECTInputFileResultDrawer
            isOpen={this.state.isOpen}
            onClose={this.toggleInputFileResultDrawer}
            analysisDataFile={this.props.analysisData.selectedAnalysisDataFile!}
          />
        )}
      </Callout>
    );
  }

  private toggleInputFileResultDrawer() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
}
