import { Measures } from '../../store/domain/measures';
import { IModelJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json';
import { CalculationValue } from '../../store/domain/calculation-value';
import { IFilter } from '../../store/domain/filter';
import { Scenario, ScenarioTypes } from '../../store/domain/scenario';
import { Debugger } from '../../store/domain/debugger';
import { ICoxSurvivalAlgorithmJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json/json-cox-survival-algorithm';
import { SummaryCalculationValue } from '../../store/domain/summary-calculation-value';

export interface ICalculateMeasuresMessage {
  fileOrUrl: string | File;
  measures: Measures[];
  ByRowMeasures: Measures[];
  modelJson: IModelJson<ICoxSurvivalAlgorithmJson>;
  filters: IFilter[];
  scenarioType: ScenarioTypes;
  scenarios: Scenario[];
  debugger: Debugger;
}

export enum CalculateMeasuresPostMessageEventType {
  ProgressUpdate,
  NewCalculation,
  Error,
  Done
}

export interface ICalculateMeasuresNewCalculationMessage {
  type: CalculateMeasuresPostMessageEventType.NewCalculation;
  calculationValue: CalculationValue;
}

export interface ICalculateMeasuresErrorMessage {
  type: CalculateMeasuresPostMessageEventType.Error;
  error: any;
}

export interface ICalculateMeasuresPostDoneMessage {
  type: CalculateMeasuresPostMessageEventType.Done;
  summaryValues: SummaryCalculationValue;
  population: number;
  filteredOutRowCount: number;
}

export type ICalculateMeasuresPostMessage =
  | ICalculateMeasuresPostDoneMessage
  | ICalculateMeasuresNewCalculationMessage
  | ICalculateMeasuresErrorMessage;
