import './calculation-builder-menu.scss';

import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Calculation } from '../../../store/domain/calculation';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Tutorial } from '../../../store/domain/tutorial';
import { CalculationToggles } from '../CalculationToggles';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculationBuilderMenuProps {
  analysisDataFile: AnalysisDataFile;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class CalculationBuilderMenu extends React.Component<ICalculationBuilderMenuProps> {
  render() {
    const { analysisDataFile } = this.props;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.CalculationOptions}
        tutorialMessage={this.renderTutorialMessage()}
        isInteractive={false}
        showContinueButton
      >
        <div className='calculation-builder-menu'>
          <Button
            className='reset-calculation-button'
            text={this.props.tutorial!.resetButtonText}
            intent={Intent.WARNING}
            onClick={() => (analysisDataFile.activeCalculation = new Calculation())}
            small
          />
          <CalculationToggles analysisDataFile={analysisDataFile} />
        </div>
      </TutorialStep>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          We can already start the calculation, but let's look at the{' '}
          <strong>Calculation options</strong>.
        </div>
        <br />
        <div>
          The <i>{this.props.tutorial!.resetButtonText}</i> button will reset the calculation form.
        </div>
      </div>
    );
  }
}
