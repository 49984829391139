import './licenses-button.scss';

import React from 'react';
import { Dialog, H4, Button } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { observer } from 'mobx-react';

export interface ILicensesButtonProps {}

interface ILicensesButtonState {
  isDialogOpen: boolean;
}

@autobind
@observer
export class LicensesButton extends React.Component<ILicensesButtonProps, ILicensesButtonState> {
  constructor(props: ILicensesButtonProps) {
    super(props);

    this.state = {
      isDialogOpen: false
    };
  }

  render() {
    return (
      <div>
        <Button onClick={this.openDialog} text='Licenses' small />
        {this.renderDialog()}
      </div>
    );
  }

  private renderDialog() {
    return (
      <Dialog
        className='standard-dialog'
        isOpen={this.state.isDialogOpen}
        title='Licenses'
        portalContainer={document.body}
        onClose={this.closeDialog}
        lazy
      >
        <div className='standard-dialog-content'>{this.renderStatsCanLicense()}</div>
      </Dialog>
    );
  }

  private renderStatsCanLicense() {
    return (
      <div>
        <H4>Statistics Canada</H4>
        <p>
          Source from Statistics Canada, Canadian Community Health Survey 2013 PUMF, accessed Jan
          2020. Reproduced and distributed on an "as is" basis with the permission of Statistics
          Canada.
        </p>
        <p>
          Adapted from Statistics Canada, Canadian Community Health Surveys 2013 PUMF, accessed Jan
          2020. This does not constitute an endorsement by Statistics Canada of this product.
        </p>
        <p>See documentation for additional details.</p>
      </div>
    );
  }

  private closeDialog() {
    this.setState({
      isDialogOpen: false
    });
  }

  private openDialog() {
    this.setState({
      isDialogOpen: true
    });
  }
}
