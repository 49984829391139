import './category-search.scss';

import classNames from 'classnames';
import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { MenuItem, Tag } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Filter } from '../../../store/domain/filter';
import { ArrayUtil } from '../../../store/domain/utils/array-util';
import { TutorialContext } from '../Tutorial/Tutorial';

const maxCategories = 200;

const CategoryMultiSelect = MultiSelect.ofType<string>();

export interface ICategorySearchProps {
  analysisDataFile: AnalysisDataFile;
  filter: Filter;
}

interface ICategorySearchState {
  categoryQuery: string; // Category search query, text displayed in search input
  activeCategory: string; // Category from results list that is highlighted using cursor or keyboard
}

@observer
@autobind
export class CategorySearch extends React.Component<ICategorySearchProps, ICategorySearchState> {
  constructor(props: ICategorySearchProps) {
    super(props);

    this.state = {
      categoryQuery: '',
      activeCategory: ''
    };
  }

  public render() {
    const { filter, analysisDataFile } = this.props;
    const normalizedQuery = this.state.categoryQuery.toLowerCase();
    let tooManyResultsCount = 0;

    const allVariableCategories = analysisDataFile.getFoundCategoriesForVariable(
      filter.variableName
    );
    const matchingCategories = this.getMatchingCategories(allVariableCategories, normalizedQuery);

    const categoriesToDisplay = matchingCategories.length > maxCategories ? [] : matchingCategories;

    if (matchingCategories.length > maxCategories) tooManyResultsCount = matchingCategories.length;

    const noResults = this.getNoResults(
      allVariableCategories,
      matchingCategories,
      tooManyResultsCount
    );

    return (
      <TutorialContext.Consumer>
        {({ getIsActive }) => (
          <CategoryMultiSelect
            selectedItems={filter.categories}
            query={this.state.categoryQuery}
            onQueryChange={this.onQueryChange}
            items={categoriesToDisplay}
            itemRenderer={this.renderItem}
            tagRenderer={this.renderTag}
            onItemSelect={this.onItemSelect}
            activeItem={this.state.activeCategory}
            onActiveItemChange={this.onActiveItemChange}
            noResults={noResults}
            popoverProps={{
              portalContainer: document.body,
              minimal: true,
              portalClassName: classNames({
                'tutorial-is-modal': getIsActive()
              }),
              popoverClassName: 'standard-select',
              targetClassName: 'multi-select'
            }}
            tagInputProps={{
              onRemove: this.onRemoveTag
            }}
            placeholder='Search categories'
          />
        )}
      </TutorialContext.Consumer>
    );
  }

  /**
   * @description Reset the state of this component if the underlying filter object
   * changes. This is to prevent issues where the UI displays information from a filter
   * that no longer represents the active filter for this component. This can be removed
   * if each filter has a field to uniquely identify itself
   * @param prevProps Previous props
   */
  public componentDidUpdate(prevProps: ICategorySearchProps) {
    if (this.props.filter !== prevProps.filter) this.resetState();
  }

  /**
   * @description Get all categories that match a given query. Search for matching category value
   * or category label
   * @param variableCategories Possible categories for variable
   * @param query Query
   */
  private getMatchingCategories(variableCategories: string[], query: string) {
    const { filter, analysisDataFile } = this.props;

    const matchingCategories = variableCategories.filter((category) => {
      // Remove categories that have already been selected
      if (filter.categories.includes(category)) return false;
      if (category.toLowerCase().includes(query)) return true;

      const label = analysisDataFile
        .getLabelForCategory(filter.variableName, category)
        .toLowerCase();

      return label.includes(query);
    });

    return ArrayUtil.sortAscending(matchingCategories);
  }

  private renderItem(category: string) {
    return (
      <MenuItem
        key={category}
        text={this.props.analysisDataFile.getLabelForCategory(
          this.props.filter.variableName,
          category
        )}
        onClick={() => this.onItemSelect(category)}
        active={category === this.state.activeCategory}
        shouldDismissPopover={false}
        label={category}
      />
    );
  }

  private renderTag(category: string) {
    const label = this.props.analysisDataFile.getLabelForCategory(
      this.props.filter.variableName,
      category
    );
    let text = '';

    if (label) text = `${label} `;
    text += `(${category})`;

    return (
      <Tag className='category-tag' key={category}>
        {text}
      </Tag>
    );
  }

  private onRemoveTag(_category: string, index: number) {
    this.props.filter.categories.splice(index, 1);
  }

  private onQueryChange(query: string) {
    this.setState({
      categoryQuery: query
    });
  }

  private onItemSelect(category: string) {
    if (!category) return;

    this.resetState();
    this.props.filter.categories.push(category);
  }

  private onActiveItemChange(category: string | null) {
    if (category) {
      this.setState({
        activeCategory: category
      });
    }
  }

  private getNoResults(
    allVariableCategories: string[],
    matchingCategories: string[],
    tooManyResultsCount: number
  ) {
    const { filter } = this.props;

    if (tooManyResultsCount) {
      return (
        <span>
          Too many results: {tooManyResultsCount}
          <br />
          Please narrow the search or use Continuous
        </span>
      );
    }

    if (filter.categories.length === allVariableCategories.length) {
      return 'All categories found in file have been selected';
    } else if (matchingCategories.length === 0) {
      return 'No matching categories found';
    } else if (allVariableCategories.length === 0) {
      return 'No categories found in file for this variable';
    }

    return '';
  }

  private resetState() {
    this.setState({
      categoryQuery: '',
      activeCategory: ''
    });
  }
}
