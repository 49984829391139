import './measure-selection.scss';

import React from 'react';
import { H6 } from '@blueprintjs/core';
import { Measures } from '../../../store/domain/measures';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Tutorial } from '../../../store/domain/tutorial';
import { Collapsible } from '../Collapsible';
import { MeasureCheckbox } from '../MeasureCheckbox';
import { Store } from '../../../store/store';
import { AlgorithmMeasure } from '../../../store/domain/algorithm-measure';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IMeasureSelectionProps {
  analysisDataFile: AnalysisDataFile;
  algorithmMeasure?: AlgorithmMeasure;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    algorithmMeasure: store.algorithmMeasure,
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class MeasureSelection extends React.Component<IMeasureSelectionProps> {
  render() {
    const { analysisDataFile } = this.props;
    const measure = this.props.algorithmMeasure!;
    const selectedCalculations = analysisDataFile.activeCalculation.activeMeasures.join(', ');

    return (
      <TutorialStep
        stepComponent={TutorialSteps.SelectMeasure}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={false}
        isTutorialStepComplete={this.isTutorialStepComplete}
      >
        <Collapsible header='Calculations' summaryMessage={selectedCalculations} startOpen>
          <div className='calculation-measures-container'>
            {this.renderArrangedMeasuresCheckboxes(measure.SummaryMeasures)}
            {this.renderArrangedMeasuresCheckboxes(measure.ByRowMeasures)}
          </div>
        </Collapsible>
      </TutorialStep>
    );
  }

  private renderArrangedMeasuresCheckboxes(measures: Measures[]) {
    const { analysisDataFile } = this.props;

    const measureType =
      measures === this.props.algorithmMeasure!.SummaryMeasures ? 'Summary' : 'By Row';

    return (
      <div className='calculation-measure-column'>
        <H6>{measureType} measures</H6>
        {measures.map((measure) => (
          <MeasureCheckbox key={measure} analysisDataFile={analysisDataFile} measure={measure} />
        ))}
      </div>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          There are two types of <strong>calculations</strong>: <i>Summary measures</i>, and{' '}
          <i>By Row measures</i>
        </div>
        <br />
        <div>
          <i>Summary measures</i> are used to calculate results for an entire population. There is
          one final result.
        </div>
        <div>
          <i>By Row measures</i> are used to calculate results for each individual entry (row) in
          the file.
        </div>
        <div>
          There are <i>n</i> final results, where <i>n</i> is the number of rows in the file after
          filtering.
        </div>
        <br />
        <div>
          Select the <i>{this.props.tutorial!.measureToClick}</i> measure to continue.
        </div>
      </div>
    );
  }

  private isTutorialStepComplete() {
    const { measures } = this.props.analysisDataFile.activeCalculation;

    return measures.length === 1 && measures.includes(this.props.tutorial!.measureToClick);
  }
}
