import { IVariableValue } from '../../store/domain/analysis-data-file';
import { Measures } from '../../store/domain/measures';
import { IModelJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json';
import { Strata } from '../../store/domain/stratum';
import { IFilter } from '../../store/domain/filter';
import { Debugger } from '../../store/domain/debugger';
import { ICoxSurvivalAlgorithmJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json/json-cox-survival-algorithm';

export interface IStratifyInitialMessage {
  fileOrUrl: string | File;
  modelJson: IModelJson<ICoxSurvivalAlgorithmJson>;
  categoricalVariableValues: IVariableValue;
  stratifications: string[];
  measures: Measures[];
  filters: IFilter[];
  debugger: Debugger;
}

export enum StratifyEventType {
  ProgressUpdate,
  Error,
  Done
}

export interface IStratifyDoneMessage {
  type: StratifyEventType.Done;
  strata: Strata;
}

export interface IStratifyErrorMessage {
  type: StratifyEventType.Error;
  error: any;
}

export type IStratifyMessage = IStratifyDoneMessage | IStratifyErrorMessage;
