import './closable-message-manager.scss';

import React from 'react';
import { autobind } from 'core-decorators';
import { ClosableMessage } from '../ClosableMessage/ClosableMessage';
import { closableMessages } from './closable-messages';
import { computed } from 'mobx';

const closedMessageKey = 'closed-welcome-messages';

export interface IClosableMessageManagerProps {}

export interface IClosableMessageManagerState {
  closedMessages: string[];
}

@autobind
export class ClosableMessageManager extends React.Component<
  IClosableMessageManagerProps,
  IClosableMessageManagerState
> {
  constructor(props: IClosableMessageManagerProps) {
    super(props);

    this.state = {
      closedMessages: this.savedClosedMessages
    };
  }

  render() {
    const openMessages = closableMessages.filter(
      closableMessage => !this.state.closedMessages.includes(closableMessage.message)
    );

    return openMessages.map(closableMessage => (
      <ClosableMessage
        key={closableMessage.message}
        {...closableMessage}
        onClose={() => this.onClose(closableMessage.message)}
      />
    ));
  }

  private onClose(message: string) {
    const closedMessages = this.savedClosedMessages;
    closedMessages.push(message);

    localStorage.setItem(closedMessageKey, JSON.stringify(closedMessages));
    this.setState({
      closedMessages
    });
  }

  @computed
  get savedClosedMessages(): string[] {
    const messages = localStorage.getItem(closedMessageKey);
    if (typeof messages === 'string') {
      try {
        return JSON.parse(messages);
      } catch (e) {}
    }
    return [];
  }
}
