import './helper-icon.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Popover, PopoverInteractionKind, Intent, Icon } from '@blueprintjs/core';

export interface IHelperIconProps {
  content: JSX.Element | string;
}

@observer
@autobind
export class HelperIcon extends React.Component<IHelperIconProps> {
  render() {
    return (
      <Popover
        popoverClassName='standard-popover'
        portalContainer={document.body}
        content={this.props.content}
        interactionKind={PopoverInteractionKind.HOVER}
        minimal
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        <Icon icon='help' className='helper-icon' intent={Intent.PRIMARY} />
      </Popover>
    );
  }
}
