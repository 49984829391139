import './app.scss';

import React from 'react';
import { autobind } from 'core-decorators';
import { Provider, observer } from 'mobx-react';

import { ConfigurationMenu } from '../ConfigurationMenu';
import { store } from '../../../store/store';
import { AppToaster } from '../AppToaster';
import { ResultManager } from '../../dumb/ResultManager';
import { WelcomeMessage } from '../../dumb/WelcomeMessage';
import { Tutorial } from '../../dumb/Tutorial/Tutorial';
import { TutorialStep } from '../../dumb/Tutorial/TutorialStep';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Footer } from '../../dumb/Footer';
import { Calculation } from '../../../store/domain/calculation';
import { c } from '../../../store/domain/c';
import { DebugManager } from '../../dumb/DebugManager';
import { NotificationDialog } from '../../dumb/NotificationDialog';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

// Tutorial state before starting it
type preTutorialState = {
  selectedAnalysisDataFile?: AnalysisDataFile;
  activeCalculation?: Calculation;
};

@autobind
@observer
export class App extends React.Component {
  private preTutorialState?: preTutorialState;

  render() {
    const selectedAnalysisDataFile = store.analysisData.selectedAnalysisDataFile;
    const canDisplayResult =
      selectedAnalysisDataFile &&
      selectedAnalysisDataFile.selectedCalculation &&
      !selectedAnalysisDataFile.selectedCalculation.isProcessing;

    return (
      <Provider {...store}>
        <Tutorial onStart={this.onTutorialStart} onEnd={this.onTutorialEnd}>
          {this.renderSelfContainedTutorialSteps()}
          <div className='app-container'>
            {store.debugger.isEnabled && (
              <DebugManager debugger={store.debugger} analysisDataFile={selectedAnalysisDataFile} />
            )}
            <NotificationDialog
              analysisDataFile={selectedAnalysisDataFile}
              notificationPrompt={store.notificationPrompt}
              tutorial={store.tutorial}
            />
            <AppToaster />
            <WelcomeMessage appName={store.appName} />
            <ConfigurationMenu />
            {canDisplayResult && <ResultManager analysisDataFile={selectedAnalysisDataFile!} />}
            <Footer appVersion={store.appVersion} engineVersion={store.engineVersion} />
          </div>
        </Tutorial>
      </Provider>
    );
  }

  /**
   * @description Add listener to ask user if they're sure they want to leave before leaving or
   * refreshing page
   */
  componentDidMount() {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      const message = 'You may have unsaved data, are you sure you want to leave?';
      e.preventDefault();

      // Only works for IE, other browsers will show their own message to the user
      e.returnValue = message;
      return message;
    };
  }

  private renderStartTutorialMessage() {
    return (
      <div>
        <div>
          Welcome to the {c.groupName} {store.appName} tutorial.
        </div>
        <div>
          This will take you through a test run of the tool, and explain the basics along the way.
        </div>
        <br />
        <div>
          Use the buttons on the right to view the documentation or exit the tutorial at any point.
        </div>
      </div>
    );
  }

  private onTutorialStart() {
    const { selectedAnalysisDataFile } = store.analysisData;
    let activeCalculation;

    if (selectedAnalysisDataFile) {
      activeCalculation = selectedAnalysisDataFile.activeCalculation;
      selectedAnalysisDataFile.activeCalculation = new Calculation();
      selectedAnalysisDataFile.isSelected = false;
    }

    this.preTutorialState = {
      selectedAnalysisDataFile,
      activeCalculation
    };

    store.tutorial.isActive = true;
  }

  private onTutorialEnd() {
    if (this.preTutorialState) {
      const { selectedAnalysisDataFile, activeCalculation } = this.preTutorialState;

      if (selectedAnalysisDataFile) {
        store.analysisData.setSelectedAnalysisDataFile(selectedAnalysisDataFile);

        if (activeCalculation) {
          selectedAnalysisDataFile.activeCalculation = activeCalculation;
        }
      }

      this.preTutorialState = undefined;
    }

    store.tutorial.isActive = false;
  }

  private renderSelfContainedTutorialSteps() {
    return (
      <div>
        <TutorialStep
          tutorialMessage={this.renderStartTutorialMessage()}
          stepComponent={TutorialSteps.Start}
          showContinueButton
        />
        <TutorialStep
          stepComponent={TutorialSteps.Complete}
          tutorialMessage={this.renderProcessingCalculationTutorialMessage()}
          showContinueButton={false}
        />
      </div>
    );
  }

  private renderProcessingCalculationTutorialMessage() {
    return (
      <div>
        <div>And that's it! You've completed the tutorial.</div>
        <div>
          Now you can run more calculations on the sample files, or use your own local files.
        </div>
        <br />
        <div>
          If you need help with the tool, you can always re-run this tutorial or refer to our{' '}
          <a target='_blank' href={c.documentationUrl}>
            guidance material
          </a>
          .
        </div>
        <br />
        <div>
          Click the <i>Exit tutorial</i> button to close the tutorial.
        </div>
      </div>
    );
  }
}
