import { VariableLabel } from '../../store/domain/variable-label';

export interface IVariableLabelInitialMessage {
  variables: string[];
}

export enum VariableLabelMessageEventType {
  Done,
  Error
}

export interface IVariableLabelDoneMessage {
  type: VariableLabelMessageEventType.Done;
  variableLabels: VariableLabel[];
}

export interface IVariableLabelErrorMessage {
  type: VariableLabelMessageEventType.Error;
  error: any;
}

export type VariableLabelPostMessage = IVariableLabelDoneMessage | IVariableLabelErrorMessage;
