import './documentation-link.scss';

import React from 'react';
import { c } from '../../../store/domain/c';

export interface IDocumentationLinkProps {}

export class DocumentationLink extends React.Component<IDocumentationLinkProps> {
  render() {
    return (
      <a target='_blank' href={c.documentationUrl}>
        Documentation
      </a>
    );
  }
}
