import './files-table.scss';

import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { FileRow } from '../FileRow';
import { observer, inject } from 'mobx-react';
import { AnalysisData } from '../../../store/domain/analysis-data';
import { autobind } from 'core-decorators';
import { Tutorial } from '../../../store/domain/tutorial';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IFilesTableProps {
  analysisData: AnalysisData;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class FilesTable extends React.Component<IFilesTableProps> {
  render() {
    const { analysisData } = this.props;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.FileManager}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={false}
        isTutorialStepComplete={this.isTutorialStepComplete}
      >
        <HTMLTable className='file-manager-table' bordered condensed striped>
          <thead>
            <tr>
              <th>Select</th>
              <th>File type</th>
              <th>Name</th>
              <th>Calculations performed</th>
            </tr>
          </thead>
          <tbody>
            {analysisData.sortedAnalysisDataFiles.map((file) => (
              <FileRow
                key={file.name.concat(file.isSampleData.toString())}
                analysisDataFile={file}
                onClick={() => analysisData.setSelectedAnalysisDataFile(file)}
              />
            ))}
          </tbody>
        </HTMLTable>
      </TutorialStep>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          The <strong>File Manager</strong> is where you can select a file to use for calculations.
        </div>
        <div>
          You can select either a local file on your computer or use one of the sample files
          provided by the application.
        </div>
        <br />
        <div>
          Select the <i>{this.props.tutorial!.fileToClick}</i> sample file to continue.
        </div>
      </div>
    );
  }

  private isTutorialStepComplete() {
    const { selectedAnalysisDataFile } = this.props.analysisData!;

    return (
      !!selectedAnalysisDataFile &&
      selectedAnalysisDataFile.name === this.props.tutorial!.fileToClick &&
      selectedAnalysisDataFile.isSampleData
    );
  }
}
