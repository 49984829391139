import { IVariableValue } from '../../store/domain/analysis-data-file';

export enum ReadMportFileRequestType {
  GetUniqueVariableValues,
  GetNumberOfRowsForStrata
}

export interface IReadMportFileInputMessage {
  type: ReadMportFileRequestType;
  fileOrUrl: string | File;
}

export enum ReadMportFileEventType {
  Progress,
  Done,
  Error
}

export interface IReadMportFileProgressMessage {
  type: ReadMportFileEventType.Progress;
  progress: number;
  headers: string[];
}

export interface IReadMportFileDoneMessage {
  type: ReadMportFileEventType.Done;
  categoricalVariableValues: IVariableValue;
}

export type IReadInputFilesMessage = IReadMportFileProgressMessage | IReadMportFileDoneMessage;
