import './respect-input-file-result-drawer.scss';

import React from 'react';
import { Drawer, HTMLTable, Callout, Icon, Intent, H4, Position, Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { c } from '../../../store/domain/c';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { RecordVariableCountPopover } from '../RecordVariableCountPopover';
import { DownloadFile } from '../../../store/domain/download-file';

const variablesToDisplayInTable = [
  ...c.respectRecognizedVariablesWithNumberValues,
  ...c.respectRecognizedVariablesWithStringValues,
  ...c.respectDateVariables
].sort();

export interface IRESPECTInputFileResultDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  analysisDataFile: AnalysisDataFile;
}

interface IRESPECTInputFileResultDrawerState {
  isDownloading: boolean;
}

@observer
@autobind
export class RESPECTInputFileResultDrawer extends React.Component<
  IRESPECTInputFileResultDrawerProps,
  IRESPECTInputFileResultDrawerState
> {
  constructor(props: IRESPECTInputFileResultDrawerProps) {
    super(props);

    this.state = {
      isDownloading: false
    };
  }

  render() {
    const { analysisDataFile } = this.props;
    const unrecognizedVariables = analysisDataFile.variables.filter(
      (variable) => !c.allRecognizedRespectVariables.includes(variable)
    );

    return (
      <Drawer
        isOpen={this.props.isOpen}
        portalContainer={document.body}
        title='File details'
        onClose={this.props.onClose}
        canOutsideClickClose={false}
        position={Position.LEFT}
        lazy
      >
        <div>
          {this.renderCountCallouts()}
          <Button
            className='download-converted-file'
            text='Download converted file'
            intent={Intent.SUCCESS}
            onClick={this.onDownloadClick}
            loading={this.state.isDownloading}
          />
          {this.renderVariableStatsTable()}
          {unrecognizedVariables.length > 0 &&
            this.renderUnrecognizedVariableList(unrecognizedVariables)}
        </div>
      </Drawer>
    );
  }

  private async onDownloadClick() {
    this.setState({
      isDownloading: true
    });

    await DownloadFile.downloadConvertedFile(this.props.analysisDataFile);

    this.setState({
      isDownloading: false
    });
  }

  private renderVariableStatsTable() {
    const { analysisDataFile } = this.props;
    const { recordCount } = analysisDataFile;

    return (
      <HTMLTable className='respect-input-file-table' bordered condensed striped>
        <thead>
          <tr>
            <th>Found?</th>
            <th>Variable</th>
            <th>Records missing variable</th>
            <th>Min</th>
            <th>Max</th>
            <th>Mean</th>
          </tr>
        </thead>
        <tbody>
          {variablesToDisplayInTable.map((variable) => {
            const isNumberVariable = c.respectRecognizedVariablesWithNumberValues.includes(
              variable
            );
            const variableWasFound = analysisDataFile.variables.includes(variable);
            const recordsWithVariableCount = variableWasFound
              ? analysisDataFile.recordsWithVariableMap.get(variable)!
              : '-';
            const anyRecordsHaveVariable = variableWasFound && recordsWithVariableCount !== 0;
            const recordsMissingVariableCount = variableWasFound
              ? recordCount - (recordsWithVariableCount as number)
              : '-';

            let recordsWithVariableCountIcon: JSX.Element | null = null;

            if (variableWasFound) {
              if (recordsWithVariableCount < recordCount) {
                const countIntent = recordsWithVariableCount > 0 ? Intent.WARNING : Intent.DANGER;

                recordsWithVariableCountIcon = <RecordVariableCountPopover intent={countIntent} />;
              }
            }

            let min: string | number = '-';
            let max: string | number = '-';
            let mean: string | number = '-';

            if (isNumberVariable && anyRecordsHaveVariable) {
              const variableValues = analysisDataFile.variableValueMap[variable];

              min = variableValues.min;
              max = variableValues.max;
              mean = variableValues.mean;
            }

            return (
              <tr key={variable}>
                <td>{this.renderBooleanIcon(variableWasFound)}</td>
                <td>{variable}</td>
                <td>
                  {recordsMissingVariableCount} {recordsWithVariableCountIcon}
                </td>
                <td>{min}</td>
                <td>{max}</td>
                <td>{mean}</td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    );
  }

  private renderBooleanIcon(variableWasFound: boolean) {
    const icon = variableWasFound ? 'tick' : 'issue';
    const intent = variableWasFound ? Intent.SUCCESS : Intent.DANGER;
    return <Icon icon={icon} intent={intent} />;
  }

  private renderCountCallouts() {
    const { recordCount, expectedRecordCount } = this.props.analysisDataFile;

    const hasIncorrectNumberOfResidents =
      expectedRecordCount > 0 && expectedRecordCount !== recordCount;

    const recordCountMessage =
      recordCount === 0 ? 'No records were found' : `Records found: ${recordCount}`;

    const intent = recordCount === 0 ? Intent.DANGER : Intent.PRIMARY;
    return (
      <div>
        <Callout intent={intent}>{recordCountMessage}</Callout>
        {hasIncorrectNumberOfResidents && (
          <Callout intent={Intent.DANGER}>
            {expectedRecordCount - recordCount} records couldn't be read.
          </Callout>
        )}
      </div>
    );
  }

  private renderUnrecognizedVariableList(unrecognizedVariables: string[]) {
    return (
      <div>
        <H4>Unrecognized variables</H4>
        <ul>
          {unrecognizedVariables.map((variable) => (
            <li key={variable}>{variable}</li>
          ))}
        </ul>
      </div>
    );
  }
}
