import React from 'react';
import { TutorialStatusModal } from './TutorialStatusModal';
import { autobind } from 'core-decorators';
import { Position } from '@blueprintjs/core';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

// All this info will be passed to the TutorialStatusModal component as props
export interface ITutorialStep {
  tutorialMessage?: string | React.ReactElement;
  showContinueButton: boolean;
  instructions?: React.ReactElement;
  position?: Position;

  /**
   * Whether the user can interact with the component highlighted in this
   * step
   *
   * @type {boolean}
   * @memberof ITutorialStepProps
   */
  isInteractive?: boolean;
}

export interface ITutorialContext {
  activeTutorialComponent: TutorialSteps;
  startTutorial: () => void;
  exitTutorial: () => void;
  nextStep: () => void;
  getIsActive: () => boolean;
  addStep: (step: TutorialSteps, stepInfo: ITutorialStep) => void;
}

export const TutorialContext = React.createContext<ITutorialContext>({} as any);

export interface ITutorialProps {
  // Run before the tutorial starts. Use this as an opportunity for eg. to change the app state to something suitable to the tutorial state
  onStart: () => void;
  // Run after the tutorial is stopped. Use this as an opportunity for eg. to revert the app state to what it was before the tutorial
  onEnd: () => void;
}

/**
 * @export
 * @interface ITutorialState
 */
export interface ITutorialState extends ITutorialContext {}

/**
 * Container component for a tutorial. Should be above the component tree of
 * all TutorialStep components
 *
 * @export
 * @class Tutorial
 * @extends {React.Component}
 */
@autobind
export class Tutorial extends React.Component<ITutorialProps, ITutorialState> {
  private steps: ITutorialStep[] = [];

  constructor(props: ITutorialProps) {
    super(props);

    this.state = {
      activeTutorialComponent: TutorialSteps.Inactive,
      startTutorial: this.startTutorial,
      exitTutorial: this.exitTutorial,
      nextStep: this.nextStep,
      addStep: this.addStep,
      getIsActive: this.getIsActive
    };
  }

  render() {
    // Render the Provider for the TutorialContext
    // Render the TutorialStatusModal if the tutorial is active
    return (
      <TutorialContext.Provider value={this.state}>
        {this.getIsActive() && (
          <TutorialStatusModal {...this.steps[this.state.activeTutorialComponent]} />
        )}
        {this.props.children}
      </TutorialContext.Provider>
    );
  }

  private startTutorial() {
    this.props.onStart();

    // Reset the current step tracker to the initial step component
    this.setState({
      activeTutorialComponent: TutorialSteps.Start
    });
  }

  private exitTutorial() {
    this.setState({
      activeTutorialComponent: TutorialSteps.Inactive
    });
    this.steps = [];

    this.props.onEnd();
  }

  private nextStep() {
    // Increment the step counter but only if the tutorial is active
    if (this.getIsActive()) {
      if (this.hasCompletedAllSteps()) {
        this.exitTutorial();
      } else {
        this.setState({
          activeTutorialComponent: this.state.activeTutorialComponent + 1
        });
      }
    }
  }

  private addStep(step: TutorialSteps, stepInfo: ITutorialStep) {
    this.steps[step] = stepInfo;
    /* Force re-render tutorial in order to re-render modal. If currentStepIndex is set before
    addStep is called, an empty step will be sent to the modal. Usually happens when the action
    that caused nextStep triggers the initial render of the now active step.
    This will re-render the modal once addStep is called */
    this.forceUpdate();
  }

  private getIsActive() {
    return this.state.activeTutorialComponent !== TutorialSteps.Inactive;
  }

  private hasCompletedAllSteps() {
    return this.state.activeTutorialComponent === this.steps.length + 1;
  }
}
