import './filter-manager.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Intent, Divider, Callout } from '@blueprintjs/core';
import { FilterView } from '../FilterView';
import { Collapsible } from '../Collapsible';
import { Tutorial } from '../../../store/domain/tutorial';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { AddFilterButton } from '../AddFilterButton';
import { HelperContainer } from '../HelperContainer';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

const maxRecommendedFilters = 3;

export interface IFilterManagerProps {
  analysisDataFile: AnalysisDataFile;
  tutorial?: Tutorial;
}

interface IFilterManagerState {
  isCollapsibleOpen: boolean;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class FilterManager extends React.Component<IFilterManagerProps, IFilterManagerState> {
  constructor(props: IFilterManagerProps) {
    super(props);

    this.state = {
      isCollapsibleOpen: false
    };
  }

  render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;
    const { filters } = activeCalculation;

    const hasMaxRecommendedFilters = filters.length > maxRecommendedFilters;

    const usedVariables = filters.map((filter) => filter.variableName).filter(Boolean);

    const filterNames = filters
      .filter((filter) => !filter.errorMessage)
      .map((filter) => filter.variableName)
      .join(', ');

    const helperContent = (
      <div>
        <div>
          Filters are <strong>inclusive</strong>.
        </div>
        <div>
          For example, to only calculate on Males, select the <i>DHH_SEX</i> variable, then select
          the <i>Male</i> category.
        </div>
      </div>
    );

    return (
      <TutorialStep
        stepComponent={TutorialSteps.FilterManager}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={false}
        isTutorialStepComplete={this.isTutorialStepComplete}
      >
        <Collapsible
          header='Filters'
          summaryMessage={filterNames}
          onClick={this.onCollapsibleClick}
          startOpen
        >
          <div className='filter-manager'>
            <HelperContainer
              className='add-filter-button-container'
              content={helperContent}
              alignment='center'
            >
              <AddFilterButton calculation={activeCalculation} />
            </HelperContainer>
            {filters.map((filter, index) => (
              <div key={index}>
                <FilterView
                  analysisDataFile={this.props.analysisDataFile}
                  filter={filter}
                  usedVariables={usedVariables}
                />
                {index < filters.length - 1 && <Divider />}
              </div>
            ))}
            {hasMaxRecommendedFilters && this.renderTooManyFiltersWarning()}
          </div>
        </Collapsible>
      </TutorialStep>
    );
  }

  private renderTooManyFiltersWarning() {
    return (
      <Callout intent={Intent.WARNING}>
        A maximum of 3 filters is recommended to prevent results with small sample sizes.
      </Callout>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          <strong>Filters</strong> can be used to filter the input from the selected file.
        </div>
        <div>
          For example, they can be used to only calculate on <i>Female</i>s in the selected file.
        </div>
        <div>
          You can filter based on any variables found in the file, including your own custom ones.
        </div>
        <br />
        <div>
          Click <i>{this.props.tutorial!.addFilterButtonText}</i> to create a new filter.
        </div>
      </div>
    );
  }

  private onCollapsibleClick(isOpen: boolean) {
    this.setState({
      isCollapsibleOpen: isOpen
    });
  }

  private isTutorialStepComplete() {
    return this.props.analysisDataFile.activeCalculation.filters.length > 0;
  }
}
