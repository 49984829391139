import './notification-toggle-button.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Button } from '@blueprintjs/core';
import { Store } from '../../../store/store';
import { NotificationPrompt } from '../../../store/domain/notification-prompt';
import { Tutorial } from '../../../store/domain/tutorial';

export interface INotificationToggleButtonProps {
  notificationPrompt?: NotificationPrompt;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    notificationPrompt: store.notificationPrompt,
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class NotificationToggleButton extends React.Component<INotificationToggleButtonProps> {
  render() {
    return (
      <Button
        icon='notifications'
        text={this.props.tutorial!.enableNotificationsText}
        onClick={this.requestNotificationPermission}
      />
    );
  }

  private async requestNotificationPermission() {
    const notificationPrompt = this.props.notificationPrompt!;
    notificationPrompt.permission = await Notification.requestPermission();
  }
}
