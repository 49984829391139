import './overview-tab.scss';

import React from 'react';

import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { H5, Icon, Intent } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Calculation } from '../../../store/domain/calculation';
import { Filter, VariableType } from '../../../store/domain/filter';
import { c } from '../../../store/domain/c';

export interface IOverviewTabProps {
  calculation: Calculation;
  analysisDataFile: AnalysisDataFile;
}

@observer
@autobind
export class OverviewTab extends React.Component<IOverviewTabProps> {
  render() {
    const { calculation } = this.props;

    return (
      <div className='overview-tab'>
        <div>{this.renderIssuesMessage()}</div>
        <div>
          <H5>Measures</H5>
          {calculation.measures.map((measure) => (
            <div key={measure}>{measure}</div>
          ))}
        </div>
        <div>
          <H5>Filters</H5>
          {calculation.activeFilters.length > 0 ? this.renderFilters() : c.genericEmptyMessage}
        </div>
        <div>
          <H5>Rows</H5>
          <div>Sample size: {calculation.sampleSize}</div>
          <div>Rows filtered out: {calculation.filteredOutRowCount}</div>
          <div>Errors: {calculation.errors.length}</div>
          <div>Warnings: {calculation.warnings.length}</div>
        </div>
      </div>
    );
  }

  private renderIssuesMessage() {
    const { calculation } = this.props;

    let message: string;
    let icon: 'error' | 'warning-sign' | 'tick';
    let intent: Intent;

    if (calculation.errors.length > 0) {
      message = 'Errors found in calculation, check the Log tab for details.';
      icon = 'error';
      intent = Intent.DANGER;
    } else if (calculation.warnings.length > 0) {
      message = 'Warnings found in calculation, check the Log tab for details.';
      icon = 'warning-sign';
      intent = Intent.WARNING;
    } else {
      message = 'There were no errors or warnings in the calculation.';
      icon = 'tick';
      intent = Intent.SUCCESS;
    }

    return (
      <div>
        <Icon icon={icon} intent={intent} /> {message}
      </div>
    );
  }

  private renderFilters() {
    const { activeFilters } = this.props.calculation;

    return (
      <ul>
        {activeFilters.map((filter) => (
          <li key={filter._key}>
            {filter.variableName}: {this.renderFilterProperties(filter)}
          </li>
        ))}
      </ul>
    );
  }

  private renderFilterProperties(filter: Filter) {
    if (filter.type === VariableType.Categorical) {
      return filter.categories
        .map((category) => {
          const categoryLabel = this.props.analysisDataFile.getLabelForCategory(
            filter.variableName,
            category
          );

          if (categoryLabel) return `${categoryLabel} (${category})`;
          return `(${category})`;
        })
        .join(', ');
    }

    return `${filter.min} - ${filter.max}`;
  }
}
