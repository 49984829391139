import { Calculation } from '../../store/domain/calculation';
import { AnalysisDataFile } from '../../store/domain/analysis-data-file';
import { toJS } from 'mobx';
//@ts-ignore
import MakeCsvExportFileWorker from './make-csv-export-file.worker';
import {
  MakeCsvExportFileEvent,
  MakeCsvExportFilePostMessage,
  PostMessageEventType
} from './make-csv-export-file-events';
import { Measures } from '../../store/domain/measures';
import { WorkerUtil } from '../../store/domain/utils/worker-util';
import { store } from '../../store/store';

export abstract class MakeCsvExportFileHandler {
  /**
   * @description Create a worker to build an output file for a calculation, and download it when
   * it's ready
   * @param analysisDataFile Analysis data file
   * @param calculation Calculation
   */
  static buildInput(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    measures: Measures[]
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const worker: Worker = new MakeCsvExportFileWorker();
      const onWorkerError = WorkerUtil.onError(worker, reject);
      const rows: string[] = [];
      worker.onerror = onWorkerError;

      worker.postMessage({
        fileOrUrl: analysisDataFile.fileOrUrl,
        outputValues: toJS(calculation.values, { recurseEverything: true }),
        outputSummaryValues: toJS(calculation.summaryValues, { recurseEverything: true }),
        measures: toJS(measures),
        SummaryMeasures: toJS(store.algorithmMeasure.SummaryMeasures)
      } as MakeCsvExportFileEvent);

      worker.onmessage = (message: { data: MakeCsvExportFilePostMessage }) => {
        switch (message.data.type) {
          case PostMessageEventType.NewRow: {
            rows.push(message.data.row);
            break;
          }

          case PostMessageEventType.EndFile: {
            worker.terminate();
            return resolve(rows.join('\n'));
          }

          default:
            onWorkerError(
              new Error(
                `Unknown message type: ${(message.data as MakeCsvExportFilePostMessage).type}`
              )
            );
        }
      };
    });
  }
}
