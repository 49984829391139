export enum Measures {
  // MPoRT
  LifeExpectancySummary = 'Life expectancy at birth (population)',
  DeathsFiveYears = 'Deaths (Five years)',
  FiveYearMortalityRiskSummary = '5-year mortality risk (summary)',

  LifeExpectancyByRow = 'Remaining life expectancy (individual)',
  FiveYearMortalityRiskByRow = '5-year mortality risk (by row)',

  // RESPECT
  TwentyFifthPercentileSurvivalTime = '25th percentile survival time',
  SeventyFifthPercentileSurvivalTime = '75th percentile survival time',

  SurvivalTime = 'Survival time',
  ThreeMonthRisk = '3 month risk',
  SixMonthRisk = '6 month risk',
  OneYearRisk = '1 year risk'
}

export const LifeExpectancyMeasures = [
  Measures.LifeExpectancyByRow,
  Measures.LifeExpectancySummary
];

export const RiskMeasures = [
  Measures.FiveYearMortalityRiskByRow,
  Measures.FiveYearMortalityRiskSummary,
  Measures.DeathsFiveYears
];
