import './calculate-button-callout.scss';

import React from 'react';
import { Intent, Callout } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { ScenarioTypes } from '../../../store/domain/scenario';
import { c } from '../../../store/domain/c';
import { AlgorithmMeasure } from '../../../store/domain/algorithm-measure';
import { Store } from '../../../store/store';

export interface ICalculateButtonCalloutProps {
  analysisDataFile: AnalysisDataFile;
  isCalculationNameDuplicate: () => boolean;
  algorithmMeasure?: AlgorithmMeasure;
}

@inject((store: Store) => {
  return {
    algorithmMeasure: store.algorithmMeasure
  };
})
@observer
@autobind
export class CalculateButtonCallout extends React.Component<ICalculateButtonCalloutProps> {
  constructor(props: ICalculateButtonCalloutProps) {
    super(props);

    this.state = {
      hasClickedCalculateButton: false
    };
  }

  render() {
    const { activeCalculation } = this.props.analysisDataFile;

    let intent: Intent;
    let message: string | JSX.Element = '';
    const hasSelectedDisabledMeasures = activeCalculation.measures.some((measure) =>
      this.props.algorithmMeasure!.ByRowMeasures.includes(measure)
    );

    if (activeCalculation.activeMeasures.length === 0) {
      if (hasSelectedDisabledMeasures) {
        message =
          'Please select different measures, the selected By Row measures are being ignored because stratifications have been selected.';
      } else {
        message = 'Please select at least one measure for the calculation.';
      }

      intent = Intent.DANGER;
    } else if (this.props.isCalculationNameDuplicate()) {
      message = 'A calculation exists with the same name, please choose another name.';
      intent = Intent.DANGER;
    } else {
      const warningMessages: string[] = [];

      if (activeCalculation.name.trim().length === 0) {
        warningMessages.push('a calculation name is recommended');
      }
      if (activeCalculation.isStratified && hasSelectedDisabledMeasures) {
        warningMessages.push(
          'all selected By Row measures will be ignored because calculation contains stratifications'
        );
      }
      if (activeCalculation.scenarioType !== ScenarioTypes.None) {
        if (activeCalculation.canRunScenarios) {
          const unusableMeasuresForScenarios = activeCalculation.activeMeasures.filter(
            (measure) => !c.usableMeasuresForScenarios.includes(measure)
          );

          if (unusableMeasuresForScenarios.length > 0) {
            warningMessages.push(
              `the following selected measures won\'t be included in the scenario calculation: ${unusableMeasuresForScenarios.join(
                ', '
              )}`
            );
          }
        } else {
          warningMessages.push('scenarios are disabled, please check the scenario for details');
        }
      }

      if (warningMessages.length > 0) {
        if (warningMessages.length === 1) {
          message = `Ready to calculate, but ${warningMessages[0]}.`;
        } else {
          message = (
            <div>
              Ready to calculate, but:
              <ul>
                {warningMessages.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            </div>
          );
        }
        intent = Intent.WARNING;
      } else {
        message = 'Ready to calculate.';
        intent = Intent.SUCCESS;
      }
    }

    return <Callout intent={intent}>{message}</Callout>;
  }
}
