import './continuous-options.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Intent, NumericInput } from '@blueprintjs/core';
import { Filter } from '../../../store/domain/filter';
import { FilterIssueManager } from '../FilterIssueManager';

export interface IContinuousOptionsProps {
  analysisDataFile: AnalysisDataFile;
  filter: Filter;
}

@observer
@autobind
export class ContinuousOptions extends React.Component<IContinuousOptionsProps> {
  public render() {
    const { analysisDataFile, filter } = this.props;

    const minFromFile = analysisDataFile.getVariableMin(filter.variableName);
    const maxFromFile = analysisDataFile.getVariableMax(filter.variableName);

    return (
      <div>
        <div className='continuous-options'>
          <div className='option-input'>
            <NumericInput
              placeholder='Min'
              onValueChange={this.onValueChangeMin}
              value={filter.min}
              intent={Intent.NONE}
            />
          </div>
          <div className='option-input'>
            <NumericInput
              placeholder='Max'
              onValueChange={this.onValueChangeMax}
              value={filter.max}
              intent={Intent.NONE}
            />
          </div>
          <FilterIssueManager filter={filter} analysisDataFile={analysisDataFile} />
        </div>
        Variable range found in file: {minFromFile} - {maxFromFile}
      </div>
    );
  }

  private onValueChangeMin(valueAsNumber: number) {
    this.props.filter.min = valueAsNumber;
  }

  private onValueChangeMax(valueAsNumber: number) {
    this.props.filter.max = valueAsNumber;
  }
}
