import './filter-issue-manager.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Popover, PopoverInteractionKind, Tag, Intent } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Filter } from '../../../store/domain/filter';

export interface IFilterIssueManagerProps {
  filter: Filter;
  analysisDataFile: AnalysisDataFile;
}

@observer
@autobind
export class FilterIssueManager extends React.Component<IFilterIssueManagerProps> {
  public render () {
    const { filter } = this.props;

    return (
      <div>
        {filter.errorMessage && this.renderErrorTagContent()}
      </div>
    );
  }

  private renderErrorTagContent () {
    const { filter } = this.props;

    const content = <div>
      <div>{filter.errorMessage}</div>
      <div>This filter won't be used until issue is resolved.</div>
    </div>;

    return (
      <Popover
        popoverClassName='standard-popover'
        portalContainer={document.body}
        canEscapeKeyClose={false}
        content={content}
        interactionKind={PopoverInteractionKind.HOVER}
        minimal
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        <Tag
          icon='error'
          intent={Intent.DANGER}
          large
        />
      </Popover>
    );
  }
}
