import './variable-manager.scss';

import classNames from 'classnames';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { HTMLTable, H6, Drawer } from '@blueprintjs/core';
import { VariableRow } from '../VariableRow';
import { Tutorial } from '../../../store/domain/tutorial';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { DataField } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/data-field';
import { TutorialPosition } from '../Tutorial/TutorialStatusModal';
import { TutorialContext } from '../Tutorial/Tutorial';
import { HelperContainer } from '../HelperContainer';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IVariableManagerProps {
  analysisDataFile: AnalysisDataFile;
  isOpen: boolean;
  onClose: () => void;
  tutorial?: Tutorial;
}

interface IVariableManagerState {
  hasClickedTutorialVariable: boolean;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class VariableManager extends React.Component<IVariableManagerProps, IVariableManagerState> {
  constructor(props: IVariableManagerProps) {
    super(props);

    this.state = {
      hasClickedTutorialVariable: false
    };
  }

  render() {
    return (
      <TutorialContext.Consumer>
        {({ getIsActive }) => (
          <Drawer
            isOpen={this.props.isOpen}
            portalContainer={document.body}
            title='Variables'
            onClose={this.props.onClose}
            canOutsideClickClose={false}
            portalClassName={classNames({
              'tutorial-is-modal': getIsActive()
            })}
            lazy
          >
            <TutorialStep
              stepComponent={TutorialSteps.VariableManager}
              tutorialMessage={this.renderTutorialOpenMessage()}
              showContinueButton={false}
              isTutorialStepComplete={this.isTutorialStepOpenComplete}
              position={TutorialPosition.TopLeft}
            >
              <TutorialStep
                stepComponent={TutorialSteps.VariableManagerClose}
                tutorialMessage={this.renderTutorialCloseMessage()}
                showContinueButton={false}
                isTutorialStepComplete={this.isTutorialStepCloseComplete}
                position={TutorialPosition.TopLeft}
              >
                <div className='variable-manager'>
                  {this.renderVariablesTable(true)}
                  {this.renderVariablesTable(false)}
                  {this.renderIgnoredVariablesCount()}
                </div>
              </TutorialStep>
            </TutorialStep>
          </Drawer>
        )}
      </TutorialContext.Consumer>
    );
  }

  /**
   * @description Render variables table with variable type explanation
   * @param renderRequiredVariables Whether to render required or recommended variables
   */
  private renderVariablesTable(renderRequiredVariables: boolean) {
    const { analysisDataFile } = this.props;
    const variableHelperContent = renderRequiredVariables
      ? this.renderRequiredContent()
      : this.renderRecommendedContent();

    const variables = renderRequiredVariables
      ? analysisDataFile.requiredVariables
      : analysisDataFile.recommendedOnlyVariables;

    const message = renderRequiredVariables ? 'Required' : 'Recommended';

    return (
      <div>
        <HelperContainer content={variableHelperContent} alignment='baseline'>
          <H6>{message} for calculation of outcomes</H6>
        </HelperContainer>
        <HTMLTable className='variable-manager-table' bordered condensed striped>
          <thead>
            <tr>
              <th>Found</th>
              <th>Name</th>
              <th>Label</th>
              <th>Description</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {variables.map((variable) => (
              <VariableRow
                key={variable.name}
                onDetailsClick={() => this.onVariableDetailsClick(variable)}
                variable={variable}
                analysisDataFile={analysisDataFile}
              />
            ))}
          </tbody>
        </HTMLTable>
      </div>
    );
  }

  private renderIgnoredVariablesCount() {
    const { analysisDataFile } = this.props;
    const variableHelperContent = this.renderAdditionalVariablesContent();

    return (
      <div className='ignored-variables'>
        <HelperContainer content={variableHelperContent} alignment='baseline'>
          <H6>Additional variables: {analysisDataFile.ignoredVariables.length}</H6>
        </HelperContainer>
      </div>
    );
  }

  private onVariableDetailsClick(variable: DataField) {
    if (variable.name === this.props.tutorial!.variableToClick) {
      this.setState({
        hasClickedTutorialVariable: true
      });
    }
  }

  private renderRequiredContent() {
    return (
      <div>
        <div>Required variables are mandatory for calculations.</div>
        <br />
        <div>
          If any required variables aren't found in a file, the file can't be used for any
          calculations.
        </div>
      </div>
    );
  }

  private renderRecommendedContent() {
    return (
      <div>
        <div>Recommended variables are optional for calculations.</div>
        <br />
        <div>
          If any recommended variables aren't found in a file, the file can still be used for
          calculations. However, calculation results will be less accurate.
        </div>
      </div>
    );
  }

  private renderAdditionalVariablesContent() {
    return (
      <div>
        <div>
          Additional variables aren't used to calculate outcomes, but can be used in filters or
          stratifications.
        </div>
        <div>
          This list includes all variables that were found in the file that aren't required or
          recommended.
        </div>
      </div>
    );
  }

  private isTutorialStepOpenComplete() {
    return this.state.hasClickedTutorialVariable;
  }

  private isTutorialStepCloseComplete() {
    return !this.props.isOpen;
  }

  private renderTutorialOpenMessage() {
    return (
      <div>
        <div>
          Some variables are mandatory for calculations. If any are missing from the file, the file
          can't be used for calculations.
        </div>
        <div>
          Many variables are optional. If any are missing, the file can still be used but the
          results will be less accurate.
        </div>
        <br />
        <div>
          Additional variables are displayed at the bottom. These can be used for filtering and
          stratifying.
        </div>
        <br />
        <div>
          You can click on the details button for any variable to get more information about it.
        </div>
        <br />
        <div>
          Click on the details button for the <i>{this.props.tutorial!.variableToClick}</i> variable
          to continue.
        </div>
      </div>
    );
  }

  private renderTutorialCloseMessage() {
    return (
      <div>
        <div>
          Click the <i>X</i> in the corner to close the <i>Variable Manager</i>.
        </div>
      </div>
    );
  }
}
