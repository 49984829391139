import './result-manager.scss';

import React from 'react';
import { Card, Intent, H3, Callout, Button, ButtonGroup } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { observer } from 'mobx-react';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { ResultTabs } from '../ResultTabs';
import { DownloadFilesDialog } from '../DownloadFilesDialog';
import { StringAsHtml } from '../StringAsHtml';
import { TutorialSteps } from '../../../store/domain/tutorial-step';
import { DownloadButton } from '../../smart/DownloadButton';
import { DownloadFileTypes } from '../../../store/domain/download-file';

export interface IResultManagerProps {
  analysisDataFile: AnalysisDataFile;
}

interface IResultManagerState {
  isDownloadDialogOpen: boolean;
}

@observer
@autobind
export class ResultManager extends React.Component<IResultManagerProps, IResultManagerState> {
  constructor(props: IResultManagerProps) {
    super(props);

    this.state = {
      isDownloadDialogOpen: false
    };
  }

  render() {
    const { analysisDataFile } = this.props;
    const calculation = analysisDataFile.selectedCalculation!;
    const { hasEmptySampleSize } = calculation;
    const shouldRenderSampleSizeWarning = !hasEmptySampleSize && calculation.hasSmallSampleSize;

    let advancedDownloadButtonIntent: Intent = Intent.SUCCESS;
    if (calculation.hasSmallSampleSize) advancedDownloadButtonIntent = Intent.WARNING;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.Result}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton
      >
        <Card className='result-manager'>
          <DownloadFilesDialog
            analysisDataFile={this.props.analysisDataFile}
            calculation={calculation}
            isOpen={this.state.isDownloadDialogOpen}
            onClose={this.onDownloadFilesDialogClose}
          />
          <H3>Results:</H3>
          {hasEmptySampleSize && this.renderEmptySampleSizeError()}
          {shouldRenderSampleSizeWarning && this.renderSampleSizeWarning()}
          <ButtonGroup className='download-results-button-container'>
            <DownloadButton
              analysisDataFile={analysisDataFile}
              scenarioMeasuresToInclude={calculation.activeMeasuresUsableForScenarios}
              measuresToInclude={calculation.measures}
              selectedDownloadFileType={DownloadFileTypes.Full}
            />
            <Button
              text='...'
              onClick={this.onAdvancedDownloadClick}
              intent={advancedDownloadButtonIntent}
            />
          </ButtonGroup>
          <StringAsHtml message={calculation.description} />
          <ResultTabs analysisDataFile={analysisDataFile} />
        </Card>
      </TutorialStep>
    );
  }

  private onAdvancedDownloadClick() {
    this.setState({
      isDownloadDialogOpen: true
    });
  }

  private onDownloadFilesDialogClose() {
    this.setState({
      isDownloadDialogOpen: false
    });
  }

  private renderSampleSizeWarning() {
    const { smallestSampleSize } = this.props.analysisDataFile.selectedCalculation!;

    return <Callout intent={Intent.WARNING}>Small sample size: {smallestSampleSize}</Callout>;
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          The <strong>Results</strong> component is where you can view and download calculation
          summary information.
        </div>
        <div>
          It contains calculation results, error and warning logs, and other data about the
          calculation.
        </div>
      </div>
    );
  }

  private renderEmptySampleSizeError() {
    const calculation = this.props.analysisDataFile.selectedCalculation!;
    const hasFilters = calculation.activeFilters.length > 0;
    const potentialCauses = ['File is empty', 'All rows have an error'];
    if (hasFilters) potentialCauses.push('Filters have filtered out all data');

    const message = calculation.isStratified
      ? 'Some results have sample sizes of 0.'
      : 'Results are empty, with a sample size of 0.';

    return (
      <Callout intent={Intent.DANGER}>
        <div>{message}</div>
        <div>Potential causes:</div>
        <ul>
          {potentialCauses.map((cause) => (
            <li key={cause}>{cause}</li>
          ))}
        </ul>
      </Callout>
    );
  }
}
