import './measure-checkbox.scss';

import React from 'react';
import { Checkbox, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { Measures } from '../../../store/domain/measures';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { AlgorithmMeasure } from '../../../store/domain/algorithm-measure';
import { Store } from '../../../store/store';

export interface IMeasureCheckboxProps {
  analysisDataFile: AnalysisDataFile;
  measure: Measures;
  algorithmMeasure?: AlgorithmMeasure;
}

@inject((store: Store) => {
  return {
    algorithmMeasure: store.algorithmMeasure
  };
})
@observer
@autobind
export class MeasureCheckbox extends React.Component<IMeasureCheckboxProps> {
  public render() {
    const { isStratified } = this.props.analysisDataFile.activeCalculation;
    const isByRowMeasure = this.props.algorithmMeasure!.ByRowMeasures.includes(this.props.measure);
    const shouldRenderPopover = isByRowMeasure && isStratified;
    const checkbox = this.renderCheckbox();

    if (shouldRenderPopover) {
      const content = (
        <div>
          <div>
            <i>By Row</i> measures can't be selected when using stratifications.
          </div>
        </div>
      );

      return (
        <Popover
          popoverClassName='standard-popover'
          portalContainer={document.body}
          content={content}
          interactionKind={PopoverInteractionKind.HOVER}
          minimal
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {checkbox}
        </Popover>
      );
    }

    return checkbox;
  }

  private renderCheckbox() {
    const { measure } = this.props;

    return (
      <Checkbox
        onChange={() => this.toggleMeasure(measure)}
        name={measure}
        label={measure}
        disabled={this.isMeasureDisabled(measure)}
        checked={this.isMeasureSelected(measure)}
      />
    );
  }

  /**
   * @description Whether a certain measure is disabled
   * @param measure Measure
   */
  private isMeasureDisabled(measure: Measures) {
    const { analysisDataFile } = this.props;

    if (this.props.algorithmMeasure!.ByRowMeasures.includes(measure)) {
      if (analysisDataFile.activeCalculation.isStratified) return true;
    }

    return false;
  }

  /**
   * @description Add or remove a configuration option
   */
  private toggleMeasure(measure: Measures) {
    const { activeCalculation } = this.props.analysisDataFile;

    if (this.isMeasureSelected(measure)) activeCalculation.removeMeasure(measure);
    else activeCalculation.addMeasure(measure);
  }

  private isMeasureSelected(measure: Measures) {
    return this.props.analysisDataFile.activeCalculation.measures.includes(measure);
  }
}
