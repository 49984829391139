import { observable, computed } from 'mobx';

export enum VariableType {
  Categorical = 'Categorical',
  Continuous = 'Continuous'
}

export interface IFilter {
  variableName: string;
  min: number;
  max: number;
  categories: string[];
  type: VariableType;
}

export class Filter implements IFilter {
  _key: number = Math.random();
  @observable
  variableName: string = '';
  @observable
  min: number = 0;
  @observable
  max: number = 0;
  @observable
  categories: string[] = [];
  @observable
  type: VariableType = VariableType.Categorical;

  @computed
  get isActive() {
    return !this.errorMessage && !!this.variableName;
  }

  @computed
  get errorMessage() {
    return this.type === VariableType.Categorical
      ? this.categoricalErrorMessage
      : this.continuousErrorMessage;
  }

  @computed
  private get categoricalErrorMessage() {
    if (this.categories.length === 0) return 'No categories have been selected';
    return '';
  }

  @computed
  private get continuousErrorMessage() {
    if (this.min > this.max) return 'Minimum is greater than maximum';
    return '';
  }
}
