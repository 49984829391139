import './download-files-table.scss';

import React from 'react';
import {
  Button,
  Intent,
  HTMLTable,
  Popover,
  PopoverInteractionKind,
  Icon
} from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { observer, inject } from 'mobx-react';
import { DownloadFileTypes } from '../../../store/domain/download-file';
import { autobind } from 'core-decorators';
import { AlgorithmMeasure } from '../../../store/domain/algorithm-measure';
import { Store } from '../../../store/store';

export interface IDownloadFilesTableProps {
  analysisDataFile: AnalysisDataFile;
  downloadFileType: DownloadFileTypes;
  onDownloadFileTypeSelect: (downloadFileType: DownloadFileTypes) => void;
  algorithmMeasure?: AlgorithmMeasure;
}

@inject((store: Store) => {
  return {
    algorithmMeasure: store.algorithmMeasure
  };
})
@autobind
@observer
export class DownloadFilesTable extends React.Component<IDownloadFilesTableProps> {
  render() {
    const { isStratified } = this.props.analysisDataFile.selectedCalculation!;

    return (
      <HTMLTable bordered condensed striped>
        <thead>
          <tr>
            <th>Status</th>
            <th>Download</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {!isStratified && this.renderFileRow(DownloadFileTypes.Input)}
          {!isStratified && this.renderFileRow(DownloadFileTypes.Summary)}
          {isStratified && this.renderStratificationFileRow()}
        </tbody>
      </HTMLTable>
    );
  }

  private renderFileRow(downloadFileType: DownloadFileTypes) {
    const calculation = this.props.analysisDataFile.selectedCalculation!;
    let intent: Intent = Intent.NONE;
    if (this.props.downloadFileType === downloadFileType) intent = Intent.PRIMARY;

    return (
      <tr>
        <td>
          <div className='centered-content'>
            {calculation.hasSmallSampleSize
              ? this.renderSampleSizeWarningIcon()
              : this.renderOkIcon()}
          </div>
        </td>
        <td>
          <div className='centered-content'>
            <Button
              text='Download...'
              onClick={() => this.props.onDownloadFileTypeSelect(downloadFileType)}
              intent={intent}
            />
          </div>
        </td>
        <td>{this.getDownloadFileTypeDescription(downloadFileType)}</td>
      </tr>
    );
  }

  private renderStratificationFileRow() {
    return (
      <tr>
        <td>
          <div className='centered-content'>
            {this.canDownloadLongTable() ? this.renderSampleSizeWarningIcon() : this.renderOkIcon()}
          </div>
        </td>
        <td>
          <div className='centered-content'>{this.renderStratificationDownloadButton()}</div>
        </td>
        <td>Stratifications: long table</td>
      </tr>
    );
  }

  /**
   * @description Render stratification download button. If the calculation has no stratifications,
   * disable the button with an on-hover popover explaining that there were no stratifications
   */
  private renderStratificationDownloadButton() {
    let intent: Intent = Intent.NONE;
    if (this.props.downloadFileType === DownloadFileTypes.LongTable) intent = Intent.PRIMARY;

    const isDisabled = !this.canDownloadLongTable();

    const stratificationButton = (
      <Button
        text='Download...'
        onClick={() => this.props.onDownloadFileTypeSelect(DownloadFileTypes.LongTable)}
        intent={intent}
        disabled={isDisabled}
      />
    );

    if (isDisabled) {
      return (
        <Popover
          popoverClassName='standard-popover'
          portalContainer={document.body}
          canEscapeKeyClose={false}
          content={this.renderLongTableDisabledReasons()}
          interactionKind={PopoverInteractionKind.HOVER}
          minimal
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {stratificationButton}
        </Popover>
      );
    }

    return stratificationButton;
  }

  private renderSampleSizeWarningIcon() {
    const { smallestSampleSize } = this.props.analysisDataFile.selectedCalculation!;

    return (
      <Popover
        popoverClassName='standard-popover'
        portalContainer={document.body}
        canEscapeKeyClose={false}
        content={`Results contain small sample sizes: ${smallestSampleSize}`}
        interactionKind={PopoverInteractionKind.HOVER}
        minimal
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        <Icon icon='warning-sign' intent={Intent.WARNING} />
      </Popover>
    );
  }

  private renderOkIcon() {
    return <Icon icon='tick' intent={Intent.SUCCESS} />;
  }

  private getDownloadFileTypeDescription(downloadFileType: DownloadFileTypes) {
    switch (downloadFileType) {
      case DownloadFileTypes.Input:
        return 'Input file with calculation results';
      case DownloadFileTypes.Summary:
        return 'Population summary';
      default:
        throw new Error(`Unrecognized download file type: ${downloadFileType}`);
    }
  }

  private renderLongTableDisabledReasons() {
    const reasons: string[] = [];

    if (!this.calculationHasSummaryMeasure()) {
      reasons.push('No summary measures were selected for this calculation');
    }
    if (!this.hasStratifications()) {
      reasons.push('No stratifications were selected for this calculation');
    }

    return (
      <div>
        File unavailable because:
        <ul>
          {reasons.map((reason) => (
            <li key={reason}>{reason}</li>
          ))}
        </ul>
      </div>
    );
  }

  private canDownloadLongTable() {
    return this.calculationHasSummaryMeasure() && this.hasStratifications();
  }

  private calculationHasSummaryMeasure() {
    return this.props.analysisDataFile.selectedCalculation!.measures.some((measure) =>
      this.props.algorithmMeasure!.SummaryMeasures.includes(measure)
    );
  }

  private hasStratifications() {
    return this.props.analysisDataFile.selectedCalculation!.stratifications.length > 0;
  }
}
