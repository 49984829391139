import './record-variable-count-popover.scss';

import React from 'react';
import { autobind } from 'core-decorators';
import { Popover, Intent, PopoverInteractionKind, Icon } from '@blueprintjs/core';

export interface IRecordVariableCountPopoverProps {
  intent: Intent;
}

@autobind
export class RecordVariableCountPopover extends React.Component<IRecordVariableCountPopoverProps> {
  render() {
    const { intent } = this.props;
    const content =
      intent === Intent.WARNING
        ? "Variable was found in some records, but was missing or couldn't be read in others."
        : "Variable is missing from or couldn't be read in any records.";
    const icon = intent === Intent.WARNING ? 'warning-sign' : 'issue';

    return (
      <Popover
        popoverClassName='standard-popover'
        portalContainer={document.body}
        content={content}
        canEscapeKeyClose={false}
        minimal
        interactionKind={PopoverInteractionKind.HOVER}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        <Icon icon={icon} intent={intent} />
      </Popover>
    );
  }
}
