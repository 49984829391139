import './debug-manager.scss';

import Draggable from 'react-draggable';
import classNames from 'classnames';
import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Debugger } from '../../../store/domain/debugger';
import { Intent, Callout, H4, Icon, Button } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';

export interface IDebugManagerProps {
  debugger: Debugger;
  analysisDataFile?: AnalysisDataFile;
}

@autobind
@observer
export class DebugManager extends React.Component<IDebugManagerProps> {
  render() {
    const { analysisDataFile } = this.props;

    let shouldRenderErrorMessage;
    if (analysisDataFile) {
      shouldRenderErrorMessage = analysisDataFile.activeCalculation.activeMeasures.length > 1;
    }

    return (
      <Draggable handle='.draggable-handle'>
        <div className={classNames('debug-manager', { opaque: shouldRenderErrorMessage })}>
          <Icon icon='move' className='draggable-handle' />
          <H4>Debugger</H4>
          <div>
            <Button
              text='Close debugger'
              intent={Intent.DANGER}
              small
              onClick={this.props.debugger.toggle}
            />
          </div>
          {shouldRenderErrorMessage && this.renderErrorMessage()}
        </div>
      </Draggable>
    );
  }

  private renderErrorMessage() {
    return (
      <div>
        <Callout intent={Intent.DANGER}>Please select exactly one measure in calculation</Callout>
      </div>
    );
  }
}
