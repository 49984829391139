import './calculation-name-input.scss';

import React from 'react';
import { Intent, InputGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculationNameInputProps {
  analysisDataFile: AnalysisDataFile;
  isCalculationNameDuplicate: () => boolean;
}

@observer
@autobind
export class CalculationNameInput extends React.Component<ICalculationNameInputProps> {
  render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;

    let showContinueButton = false;

    if (activeCalculation.name.trim()) {
      if (!this.props.isCalculationNameDuplicate()) {
        showContinueButton = true;
      }
    }

    return (
      <TutorialStep
        stepComponent={TutorialSteps.CalculationName}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={showContinueButton}
        instructions={this.renderTutorialInstructions()}
      >
        {this.renderInputGroup()}
      </TutorialStep>
    );
  }

  private onCalculationNameChange(e: React.FormEvent<HTMLInputElement>) {
    this.props.analysisDataFile.activeCalculation.name = e.currentTarget.value;
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          Each calculation should have a <strong>Calculation name</strong>.
        </div>
        <div>
          This field is not mandatory, but it helps to distinguish one calculation from another.
        </div>
        <div>A name will be generated automatically if this field is not filled out.</div>
      </div>
    );
  }

  private renderInputGroup() {
    const { activeCalculation } = this.props.analysisDataFile;
    let calculationNameIntent: Intent = Intent.NONE;

    if (activeCalculation.name.trim()) {
      if (this.props.isCalculationNameDuplicate()) {
        calculationNameIntent = Intent.DANGER;
      } else {
        calculationNameIntent = Intent.SUCCESS;
      }
    } else {
      calculationNameIntent = Intent.WARNING;
    }

    return (
      <InputGroup
        className='calculation-name-input'
        type='text'
        placeholder='Name'
        onChange={this.onCalculationNameChange}
        value={activeCalculation.name}
        intent={calculationNameIntent}
        fill
      />
    );
  }

  private renderTutorialInstructions() {
    return <div>Add a calculation name now.</div>;
  }
}
