import { Measures } from './measures';
import { ScenarioMethods } from '@ottawamhealth/pbl-calculator-engine/lib/scenario';
import { Algorithm } from './algorithm';
import { computed } from 'mobx';
import { autobind } from 'core-decorators';

@autobind
export class Tutorial {
  isActive: boolean = false;

  categoryToSelect = '2';
  categoryToSelectLabel = 'Female';
  variableManagerButtonText = 'View variables';
  calculationViewButtonText = 'View';
  newCalculationButtonText = 'Create';
  resetButtonText = 'Reset';
  loadCalculationButtonText = 'Load';
  copyCalculationButtonText = 'Copy';
  searchVariablesText = 'Search variables';
  addFilterButtonText = 'New';
  scenarioName = 'Smoking';
  scenarioMethod = ScenarioMethods.Relative;
  scenarioAmount = -10;
  enableNotificationsText = 'Enable notifications';

  private algorithm: Algorithm;

  constructor(algorithm: Algorithm) {
    this.algorithm = algorithm;
  }

  @computed
  get fileToClick() {
    return this.algorithm.config.tutorialFile;
  }

  @computed
  get measureToClick(): Measures {
    if (this.algorithm.isRespect) return Measures.SixMonthRisk;
    return Measures.DeathsFiveYears;
  }

  @computed
  get stratificationToSelect(): string {
    if (this.algorithm.isMport) return 'DHHGAGE';
    return 'DHHGAGE';
  }

  @computed
  get variableToClick(): string {
    if (this.algorithm.isMport) return 'DHH_SEX';
    return 'DHH_SEX';
  }

  @computed
  get filterToSelect(): string {
    if (this.algorithm.isMport) return 'DHH_SEX';
    return 'DHH_SEX';
  }
}
