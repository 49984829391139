import './helper-container.scss';

import classNames from 'classnames';
import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { HelperIcon } from '../HelperIcon';
import { AlignContentProperty } from 'csstype';

export interface IHelperContainerProps {
  className?: string;
  content: JSX.Element | string;
  alignment: AlignContentProperty;
}

@observer
@autobind
export class HelperContainer extends React.Component<IHelperContainerProps> {
  render() {
    return (
      <div
        className={classNames('helper-container', this.props.className)}
        style={{
          alignItems: this.props.alignment
        }}
      >
        {this.props.children}
        <HelperIcon content={this.props.content} />
      </div>
    );
  }
}
