// @ts-ignore
import ReadRespectFileWorker from './read-respect-file.worker';
import { AnalysisDataFile } from '../../store/domain/analysis-data-file';
import {
  IReadRespectFileInitMessage,
  IReadRespectFilePostMessage,
  IReadRespectFileEventType
} from './read-respect-file-events';
import { IRecord } from '../../store/domain/record';

export abstract class ReadRespectFileHandler {
  static analyzeFile(analysisDataFile: AnalysisDataFile): Promise<void> {
    return new Promise((resolve) => {
      const worker: Worker = new ReadRespectFileWorker();

      worker.postMessage({
        fileOrUrl: analysisDataFile.fileOrUrl
      } as IReadRespectFileInitMessage);

      worker.onmessage = (message: { data: IReadRespectFilePostMessage }) => {
        const { data } = message;

        switch (data.type) {
          case IReadRespectFileEventType.Variables: {
            analysisDataFile.variables.push(...data.variables);
            break;
          }

          case IReadRespectFileEventType.Progress: {
            analysisDataFile.dataLoadingProgress = data.progress;
            break;
          }

          case IReadRespectFileEventType.Done: {
            analysisDataFile.expectedRecordCount = data.expectedRecordCount;
            analysisDataFile.recordCount = data.recordCount;
            analysisDataFile.variableValueMap = data.variableValueMap;
            analysisDataFile.recordsWithVariableMap = data.recordsWithVariableMap;
            analysisDataFile.dataLoadingProgress = 1;
            analysisDataFile.hasLoadedVariables = true;
            worker.terminate();
            return resolve();
          }
        }
      };
    });
  }

  static loadRecords(fileOrUrl: File | string): Promise<IRecord[]> {
    return new Promise((resolve) => {
      const worker: Worker = new ReadRespectFileWorker();
      const records: IRecord[] = [];

      worker.postMessage({
        fileOrUrl
      } as IReadRespectFileInitMessage);

      worker.onmessage = (message: { data: IReadRespectFilePostMessage }) => {
        const { data } = message;

        switch (data.type) {
          case IReadRespectFileEventType.NewRecord: {
            records.push(data.record);
            break;
          }

          case IReadRespectFileEventType.Done: {
            worker.terminate();
            return resolve(records);
          }
        }
      };
    });
  }
}
