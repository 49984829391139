import './calculation-description-popover.scss';

import React from 'react';
import { Intent, Popover, Icon, PopoverInteractionKind } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { StringAsHtml } from '../StringAsHtml';

export interface ICalculationDescriptionPopoverProps {
  description: string;
}

@autobind
@observer
export class CalculationDescriptionPopover extends React.Component<
  ICalculationDescriptionPopoverProps
> {
  render() {
    return (
      <Popover
        popoverClassName='standard-popover'
        portalContainer={document.body}
        content={<StringAsHtml message={this.props.description} />}
        canEscapeKeyClose={false}
        minimal
        interactionKind={PopoverInteractionKind.HOVER}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        <Icon
          className='calculation-description-help-icon'
          icon='info-sign'
          intent={Intent.PRIMARY}
        />
      </Popover>
    );
  }
}
