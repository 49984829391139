import './calculation-started-toast.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { Toast } from '@blueprintjs/core';

export interface ICalculationStartedToastProps {
    calculationName: string;
    fileName: string;
    onDismiss: () => void;
}

export const CalculationStartedToast: React.SFC<
    ICalculationStartedToastProps
> = observer(
    ({
        calculationName,
        fileName,
        onDismiss
    }: ICalculationStartedToastProps) => {
        return (
            <Toast
                className='calculation-started-toast'
                message={
                    <div>
                        <p className='calculation-started-toast__message'>
                            Calculation <b>{calculationName}</b>{' '}
                            started on file <b>{fileName}</b>.
                        </p>
                    </div>
                }
                onDismiss={onDismiss}
                icon='info-sign'
            />
        );
    },
);
