import './welcome-message.scss';

import React from 'react';
import { Card, Intent, H1, Button } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TutorialContext } from '../Tutorial/Tutorial';
import { c } from '../../../store/domain/c';
import { ChangeLog } from '../../smart/ChangeLog';
import { ClosableMessageManager } from '../ClosableMessageManager';
import { DocumentationLink } from '../DocumentationLink';

export interface IWelcomeProps {
  appName: string;
}

@autobind
export class WelcomeMessage extends React.Component<IWelcomeProps> {
  render() {
    return (
      <TutorialContext.Consumer>
        {({ startTutorial }) => (
          <Card>
            <div className='welcome-header'>
              <H1>
                {c.groupName}: {this.props.appName}
              </H1>
              <div className='welcome-buttons'>
                <DocumentationLink />
                <div>
                  <Button
                    onClick={startTutorial}
                    text='Start tutorial'
                    icon='info-sign'
                    intent={Intent.PRIMARY}
                  />
                </div>
                <ChangeLog />
              </div>
            </div>
            <ClosableMessageManager />
          </Card>
        )}
      </TutorialContext.Consumer>
    );
  }
}
