import { observable } from 'mobx';
import { autobind } from 'core-decorators';
import { c } from './c';

declare global {
  interface Window {
    toggleDebugger: () => void;
  }
}

const debugModeKey = 'debug-mode';

@autobind
export class Debugger {
  @observable
  isEnabled: boolean = localStorage.getItem(debugModeKey) === 'true';

  toggle() {
    this.isEnabled = !this.isEnabled;

    if (this.isEnabled) localStorage.setItem(debugModeKey, String(this.isEnabled));
    else localStorage.removeItem(debugModeKey);
    console.log(`Debugger is: ${this.isEnabled ? 'on' : 'off'}`);
  }
}

export const debugger_ = new Debugger();

window.toggleDebugger = debugger_.toggle;

console.log(
  [
    `%cWant to know how ${c.theAppName} calculations work?`,
    'Run this command to enable the debugger, then run calculations:',
    'toggleDebugger()',
    '',
    'When the calculations complete, detailed information about calculations will be logged to the console.',
    '',
    'Re-run the command to disable the debugger, or close the debugger window in the app.'
  ].join('\n'),
  'font-size: 16px;'
);
