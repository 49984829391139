import { observable } from 'mobx';

import { Calculation } from '../domain/calculation';
import { AnalysisDataFile } from '../domain/analysis-data-file';
import { CalculationCompleteToast } from './calculation-complete-toast';
import { CalculationStartedToast } from './calculation-started-toast';

export class AppToaster {
    @observable.shallow
    calculationCompleteToasts: CalculationCompleteToast[] = [];
    @observable.shallow
    calculationStartedToasts: CalculationStartedToast[] = [];

    public addCalculationCompleteToast (
        calculation: Calculation,
        analysisDataFile: AnalysisDataFile,
    ) {
        this.calculationCompleteToasts.push(
            new CalculationCompleteToast(calculation, analysisDataFile),
        );
    }

    public addCalculationStartedToast (calculation: Calculation,
        analysisDataFile: AnalysisDataFile) {
        this.calculationStartedToasts.push(
            new CalculationStartedToast(calculation, analysisDataFile)
        );
    }

    public removeCalculationCompleteToast (
        calculationCompleteToast: CalculationCompleteToast,
    ) {
        this.calculationCompleteToasts = this.calculationCompleteToasts.filter(
            currentCalculationCompleteToast =>
                    currentCalculationCompleteToast !== calculationCompleteToast
        );
    }

    public removeCalculationStartedToast (
        calculationStartedToast: CalculationStartedToast
    ) {
        this.calculationStartedToasts = this.calculationStartedToasts.filter(
            currentCalculationCompleteToast => {
                currentCalculationCompleteToast !== calculationStartedToast
            }
        );
    }
}

export const appToaster = new AppToaster();
