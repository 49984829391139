import { Intent } from '@blueprintjs/core';
import { IClosableMessage } from '../ClosableMessage/ClosableMessage';

export const closableMessages: IClosableMessage[] = [
  {
    message: 'Scenarios are being updated, and have been temporarily disabled.',
    intent: Intent.DANGER
  },
  {
    message:
      "This is an offline application. Files remain on your computer and aren't uploaded or sent anywhere.",
    intent: Intent.PRIMARY
  }
];
