import { FileType } from './store/domain/file-type';
import { AlgorithmType } from './store/domain/algorithm-type';

export const Config: IConfig = require('../dist/json/config.json');

interface ISampleData {
  name: string;
  url: string;
  fileType: FileType;
}

interface IAlgorithm {
  name: AlgorithmType;
  url: string;
  sampleData: ISampleData[];
  tutorialFile: string;
}

interface IConfig {
  algorithms: IAlgorithm[];
}
