import React from 'react';
import classNames from 'classnames';
import { TutorialContext, ITutorialStep } from './Tutorial';
import { TutorialSteps, TutorialContainerChildren } from '../../../store/domain/tutorial-step';

export interface ITutorialStepProps extends ITutorialStep {
  stepComponent: TutorialSteps;
  /**
   * Whether the tutorial should go to the next step. This is called only if
   * this step is currently active. Don't include this prop if the user goes
   * to the next step using the continue button in the TutorialStatusModal
   *
   * @memberof ITutorialStepProps
   */
  isTutorialStepComplete?: () => boolean;
}

export interface ITutorialStepState {
  isActive: boolean;
}

export class TutorialStep extends React.Component<ITutorialStepProps, ITutorialStepState> {
  constructor(props: ITutorialStepProps) {
    super(props);

    this.state = {
      isActive: false
    };
  }

  render() {
    return (
      <TutorialContext.Consumer>
        {({ activeTutorialComponent, getIsActive, addStep, nextStep }) => {
          const tutorialChildren = TutorialContainerChildren[this.props.stepComponent] || [];
          const isChildActive = tutorialChildren.includes(activeTutorialComponent);
          let tutorialActiveClassName = '';

          // Add this step to the tutorial
          addStep(this.props.stepComponent, this.props);

          // If the tutorial is active
          if (getIsActive()) {
            if (this.state.isActive) {
              if (this.props.isInteractive) tutorialActiveClassName = 'tutorial-active-enabled';
              else tutorialActiveClassName = 'tutorial-active-disabled';
            }

            //Keep track of whether this step is currently active
            const isActiveStep = activeTutorialComponent === this.props.stepComponent;

            // If this step is active check if we should be going to the next step
            if (isActiveStep) {
              if (this.props.isTutorialStepComplete && this.props.isTutorialStepComplete()) {
                nextStep();
              }
            }

            // Check whether we should update the state
            if (isActiveStep !== this.state.isActive) {
              this.setState({
                isActive: isActiveStep
              });
            }
          }

          return (
            <div
              className={classNames('tutorial-active-container', tutorialActiveClassName, {
                'tutorial-stacking-container': isChildActive
              })}
            >
              {/* If this step has an active tutorial step as a child, display new grey box within
              this component to grey-out contents, still allowing active step to hover over top
              grey box. Only necessary when this container creates a new stacking context */}
              {isChildActive && <span className='tutorial-stacking-grey-box' />}
              {this.props.children}
            </div>
          );
        }}
      </TutorialContext.Consumer>
    );
  }
}
