import { observable } from 'mobx';
import { IModelJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json';
import { Model, CoxSurvivalAlgorithm } from '@ottawamhealth/pbl-calculator-engine/lib/engine/model';

import { Config } from '../../json';
import { ICoxSurvivalAlgorithmJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json/json-cox-survival-algorithm';

export class Algorithms {
  @observable.shallow
  models: Model<CoxSurvivalAlgorithm>[] = [];
  @observable.shallow
  modelJsons: IModelJson<ICoxSurvivalAlgorithmJson>[] = [];

  fetchAlgorithms() {
    return Promise.all(
      Config.algorithms.map(({ url }) =>
        fetch(url)
          .then((res) => res.json())
          .then((modelJson: IModelJson<ICoxSurvivalAlgorithmJson>) => {
            this.models.push(new Model(modelJson));
            this.modelJsons.push(modelJson);
          })
      )
    );
  }
}

export const algorithms = new Algorithms();
