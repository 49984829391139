import './tutorial.scss';

import classNames from 'classnames';
import React from 'react';
import { Callout, Intent, Button, Position } from '@blueprintjs/core';
import { TutorialContext, ITutorialStep } from './Tutorial';
import { DocumentationLink } from '../DocumentationLink';
import { TutorialStepTitles } from '../../../store/domain/tutorial-step';

export interface ITutorialStatusModalProps extends ITutorialStep {}

export enum TutorialPosition {
  Top = 'top',
  TopLeft = 'top-left'
}

export class TutorialStatusModal extends React.Component<ITutorialStatusModalProps> {
  static defaultProps: Partial<ITutorialStatusModalProps> = {
    isInteractive: true,
    position: Position.TOP
  };

  public render() {
    const showInstructions = !this.props.showContinueButton && this.props.instructions;
    const positionClass = `tutorial-position-${
      this.props.position || TutorialStatusModal.defaultProps.position
    }`;

    return (
      <TutorialContext.Consumer>
        {({ exitTutorial, nextStep, activeTutorialComponent }) => (
          <div>
            {!this.props.isInteractive && <span className='tutorial-disabled-box' />}
            <span className='tutorial-grey-box' />
            <Callout
              id='tutorial-container'
              className={classNames('tutorial-status-modal', positionClass)}
              title={TutorialStepTitles[activeTutorialComponent]}
              intent={Intent.PRIMARY}
              icon='info-sign'
            >
              <div className='tutorial-instructions-container'>
                <div>
                  {this.props.tutorialMessage}
                  {showInstructions && (
                    <div>
                      <br />
                      {this.props.instructions}
                    </div>
                  )}
                </div>
                <div className='tutorial-button-container'>
                  <DocumentationLink />
                  <div>
                    <Button
                      className='tutorial-exit-button'
                      text='Exit tutorial'
                      onClick={exitTutorial}
                    />
                  </div>
                </div>
              </div>
              {this.props.showContinueButton && this.renderContinueButton(nextStep)}
            </Callout>
          </div>
        )}
      </TutorialContext.Consumer>
    );
  }

  private renderContinueButton(nextStep: () => void) {
    return (
      <div>
        <Button
          className='continue-tutorial-button'
          intent={Intent.PRIMARY}
          text='Click here to continue'
          onClick={nextStep}
        />
      </div>
    );
  }
}
