import './filter-view.scss';

import classNames from 'classnames';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { MenuItem, Button } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { Filter, VariableType } from '../../../store/domain/filter';
import { Tutorial } from '../../../store/domain/tutorial';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { FilterOptions } from '../FilterOptions';
import { TutorialContext } from '../Tutorial/Tutorial';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

const VariableSuggest = Suggest.ofType<string>();

export interface IFilterViewProps {
  analysisDataFile: AnalysisDataFile;
  filter: Filter;
  usedVariables: string[]; // Variables that have been selected in other stratification views
  tutorial?: Tutorial;
}

interface IFilterViewState {
  variableQuery: string;
  activeVariable: string;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class FilterView extends React.Component<IFilterViewProps, IFilterViewState> {
  constructor(props: IFilterViewProps) {
    super(props);

    const { variableName } = this.props.filter;

    this.state = {
      variableQuery: variableName,
      activeVariable: variableName
    };
  }

  render() {
    const { filter, analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;

    // Remove variables in other filters from result set, but don't remove variable
    // that's been selected in this filter
    const matchingVariables = analysisDataFile
      .getMatchingVariables(this.state.variableQuery)
      .filter(
        (variable) =>
          !this.props.usedVariables.includes(variable) || this.state.activeVariable === variable
      );

    return (
      <TutorialContext.Consumer>
        {({ getIsActive }) => (
          <div className='filter'>
            <Button icon='trash' onClick={() => activeCalculation.removeFilter(filter)} small />
            <TutorialStep
              stepComponent={TutorialSteps.SelectFilterVariable}
              tutorialMessage={this.renderTutorialMessage()}
              isTutorialStepComplete={() =>
                this.props.filter.variableName === this.props.tutorial!.filterToSelect
              }
              showContinueButton={false}
            >
              <div className='filter-type-variable'>
                <VariableSuggest
                  inputValueRenderer={this.renderInputValue}
                  query={this.state.variableQuery}
                  onQueryChange={this.onQueryChange}
                  items={matchingVariables}
                  itemRenderer={this.renderItem}
                  onItemSelect={this.onItemSelect}
                  activeItem={this.state.activeVariable}
                  onActiveItemChange={this.onActiveItemChange}
                  noResults={
                    filter.variableName
                      ? 'Selected variable was found in file'
                      : 'No matching variables found'
                  }
                  popoverProps={{
                    portalContainer: document.body,
                    portalClassName: classNames({
                      'tutorial-is-modal': getIsActive()
                    }),
                    minimal: true,
                    popoverClassName: 'standard-select'
                  }}
                  inputProps={{
                    placeholder: 'Search variables'
                  }}
                />
                {filter.variableName && this.renderFilterTypeButton()}
              </div>
            </TutorialStep>
            {filter.variableName && (
              <FilterOptions analysisDataFile={analysisDataFile} filter={filter} />
            )}
          </div>
        )}
      </TutorialContext.Consumer>
    );
  }

  private renderItem(variable: string) {
    const variableLabel = this.props.analysisDataFile.getVariableLabels(variable);

    return (
      <MenuItem
        key={variable}
        text={variable}
        onClick={() => this.onItemSelect(variable)}
        active={variable === this.state.activeVariable}
        label={variableLabel.label}
      />
    );
  }

  private renderInputValue(category: string) {
    return category;
  }

  private onQueryChange(query: string) {
    const { filter } = this.props;

    this.setState({
      variableQuery: query
    });

    if (this.state.variableQuery !== this.state.activeVariable) {
      filter.variableName = '';
      filter.categories = [];
    }
  }

  private onItemSelect(variable: string) {
    const { filter, analysisDataFile } = this.props;

    filter.variableName = variable;

    this.setState({
      variableQuery: variable,
      activeVariable: variable
    });

    filter.min = analysisDataFile.getVariableMin(variable);
    filter.max = analysisDataFile.getVariableMax(variable);
  }

  private onActiveItemChange(variable: string | null) {
    if (variable) {
      this.setState({
        activeVariable: variable
      });
    }
  }

  private renderFilterTypeButton() {
    return (
      <Button
        icon='refresh'
        text={this.props.filter.type}
        onClick={this.toggleVariableType}
        small
      />
    );
  }

  private toggleVariableType() {
    const { filter } = this.props;

    const type =
      filter.type === VariableType.Categorical ? VariableType.Continuous : VariableType.Categorical;

    filter.type = type;
  }

  private renderTutorialMessage() {
    const tutorial = this.props.tutorial!;

    return (
      <div>
        <div>
          Now that we've created a <i>filter</i>, let's select a variable name to filter by.
        </div>
        <br />
        <div>
          To select a variable for a filter, click the <i>{tutorial.searchVariablesText}</i> field.
          Then, if you can't see the variable, you can search for it by typing in the variable name.
        </div>
        <div>
          Every variable in the selected file, including custom ones, will appear in this list.
        </div>
        <br />
        <div>
          Select the <i>{tutorial.filterToSelect}</i> variable.
        </div>
      </div>
    );
  }

  public componentDidUpdate(prevProps: IFilterViewProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState({
        variableQuery: this.props.filter.variableName,
        activeVariable: this.props.filter.variableName
      });
    }
  }
}
