import './calculation-import-button.scss';

import React from 'react';
import { Button, FileInput, Dialog } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { RenderUtil } from '../../../store/domain/utils/render-util';
import { Tutorial } from '../../../store/domain/tutorial';
import { Calculation } from '../../../store/domain/calculation';
import { Store } from '../../../store/store';

export interface ICalculationImportButtonProps {
  analysisDataFile: AnalysisDataFile;
  onLoad: () => void;
  tutorial?: Tutorial;
}

interface ICalculationImportButtonState {
  isLoadingCalculation: boolean;
  isLoadingInvalidFile: boolean;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class CalculationImportButton extends React.Component<
  ICalculationImportButtonProps,
  ICalculationImportButtonState
> {
  constructor(props: ICalculationImportButtonProps) {
    super(props);

    this.state = {
      isLoadingCalculation: false,
      isLoadingInvalidFile: false
    };
  }

  private fileInputRef: HTMLInputElement | null = null;

  render() {
    return (
      <div>
        <Button
          icon='upload'
          text={this.props.tutorial!.loadCalculationButtonText}
          onClick={() => this.fileInputRef!.click()}
          loading={this.state.isLoadingCalculation}
        />
        <FileInput
          hidden
          onInputChange={this.onCalculationsUpload}
          inputProps={{
            accept: '.json',
            ref: (ref) => (this.fileInputRef = ref)
          }}
        />
        <Dialog
          className='standard-dialog'
          isOpen={this.state.isLoadingInvalidFile}
          title='Unable to load file'
          portalContainer={document.body}
          onClose={() => this.setIsLoadingInvalidFile(false)}
          lazy
        >
          <div className='standard-dialog-content'>
            Unable to load selected file, unrecognized format.
          </div>
        </Dialog>
      </div>
    );
  }

  private async onCalculationsUpload(e: React.FormEvent<HTMLInputElement>) {
    const { files } = e.currentTarget;
    if (!files || !files.length) return;

    const [file] = files;
    RenderUtil.clearFileInput(e);

    this.setIsLoadingCalculation(true);

    try {
      this.props.analysisDataFile.activeCalculation = await this.importCalculation(file);
    } catch (e) {
      this.setIsLoadingInvalidFile(true);
      this.setIsLoadingCalculation(false);
      return;
    }

    this.setIsLoadingCalculation(false);
    this.props.onLoad();
  }

  private setIsLoadingCalculation(isLoadingCalculation: boolean) {
    this.setState({
      isLoadingCalculation
    });
  }

  private setIsLoadingInvalidFile(isLoadingInvalidFile: boolean) {
    this.setState({
      isLoadingInvalidFile
    });
  }

  private async importCalculation(file: File): Promise<Calculation> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent) => {
        try {
          const loadedCalculation: Calculation = JSON.parse((e.target as any).result as string);

          const calculation = new Calculation();
          calculation.name = loadedCalculation.name;
          calculation.description = loadedCalculation.description;
          calculation.measures = loadedCalculation.measures;
          calculation.stratifications = loadedCalculation.stratifications;
          calculation.filters = loadedCalculation.filters;

          calculation.areFiltersEnabled = calculation.filters.length > 0;
          calculation.areStratificationsEnabled = calculation.stratifications.length > 0;

          resolve(calculation);
        } catch (e) {
          reject(e);
        }
      };

      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
}
