import React from 'react';
import { inject, observer } from 'mobx-react';
import { Toaster, Position } from '@blueprintjs/core';

import { AppToaster as AppToasterStore } from '../../store/ui/app-toaster';
import { CalculationCompleteToast } from '../dumb/CalculationCompleteToast/CalculationCompleteToast';
import { CalculationStartedToast } from '../dumb/CalculationStartedToast/CalculationStartedToast';
import { Store } from '../../store/store';

export interface IAppToasterProps {
    appToaster?: AppToasterStore;
}

@inject((stores: Store) => {
    return {
        appToaster: stores.appToaster
    };
})
@observer
export class AppToaster extends React.Component<IAppToasterProps> {
    render() {
        return (
            <Toaster position={Position.BOTTOM_RIGHT}>
                {this.renderCalculationCompleteToasts()}
                {this.renderCalculationStartedToasts()}
            </Toaster>
        );
    }

    private renderCalculationCompleteToasts () {
        const { appToaster } = this.props;

        return (
            appToaster!.calculationCompleteToasts.map(
                (calculationToast, index) => {
                    return (
                        <CalculationCompleteToast
                            key={index}
                            calculationName={
                                calculationToast.calculation
                                    .name
                            }
                            fileName={
                                calculationToast
                                    .analysisDataFile.name
                            }
                            onDismiss={() => {
                                appToaster!.removeCalculationCompleteToast(
                                    calculationToast,
                                );
                            }}
                        />
                    );
                }
            )
        );
    }

    private renderCalculationStartedToasts () {
        const { appToaster } = this.props;

        return (
            appToaster!.calculationStartedToasts.map(
                (calculationToast, index) => {
                    return (
                        <CalculationStartedToast
                            key={index}
                            calculationName={
                                calculationToast.calculation
                                    .name
                            }
                            fileName={
                                calculationToast
                                    .analysisDataFile.name
                            }
                            onDismiss={() => {
                                appToaster!.removeCalculationStartedToast(
                                    calculationToast,
                                );
                            }}
                        />
                    );
                }
            )
        );
    }
}
