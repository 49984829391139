import { IVariableValueMap } from '../../store/domain/record-variable-map';
import { IRecord } from '../../store/domain/record';

export interface IReadRespectFileInitMessage {
  fileOrUrl: File | string;
}

export enum IReadRespectFileEventType {
  Variables,
  Progress,
  NewRecord,
  Done
}

export interface IReadRespectFileVariablesMessage {
  type: IReadRespectFileEventType.Variables;
  variables: string[];
}

export interface IReadRespectFileProgressMessage {
  type: IReadRespectFileEventType.Progress;
  progress: number;
}

export interface IReadRespectFileNewRecordMessage {
  type: IReadRespectFileEventType.NewRecord;
  record: IRecord;
}

export interface IReadRespectFilePostDoneMessage {
  type: IReadRespectFileEventType.Done;
  expectedRecordCount: number;
  recordCount: number;
  variableValueMap: IVariableValueMap;
  recordsWithVariableMap: Map<string, number>;
}

export type IReadRespectFilePostMessage =
  | IReadRespectFileVariablesMessage
  | IReadRespectFileProgressMessage
  | IReadRespectFileNewRecordMessage
  | IReadRespectFilePostDoneMessage;
