import './health-behaviour-scenario.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Checkbox } from '@blueprintjs/core';
import { Calculation } from '../../../store/domain/calculation';
import { Store } from '../../../store/store';
import { Intervention } from '../../../store/domain/intervention';
import { Scenario } from '../../../store/domain/scenario';
import { IScenario, ScenarioMethods } from '@ottawamhealth/pbl-calculator-engine/lib/scenario';

export interface IHealthBehaviourScenarioProps {
  calculation: Calculation;
  interventions?: Intervention[];
}

@inject((store: Store) => {
  return {
    interventions: store.interventions
  };
})
@observer
@autobind
export class HealthBehaviourScenario extends React.Component<IHealthBehaviourScenarioProps> {
  render() {
    const { calculation } = this.props;

    const interventionsWithAttributionScenarios = this.props.interventions!.filter(intervention =>
      intervention.scenarios.some(scenario => scenario.method === ScenarioMethods.Attribution)
    );

    return (
      <div>
        {interventionsWithAttributionScenarios.map(intervention => (
          <div key={intervention.subject}>
            {intervention.scenarios
              .filter(scenario => scenario.method === ScenarioMethods.Attribution)
              .map(scenario => (
                <Checkbox
                  key={scenario.name}
                  label={scenario.name}
                  onClick={() => this.onClick(scenario)}
                  checked={calculation.hasScenario(scenario)}
                  disabled={!calculation.canRunScenarios}
                  inline
                />
              ))}
          </div>
        ))}
      </div>
    );
  }

  private onClick(scenario: IScenario) {
    const { calculation } = this.props;

    if (calculation.hasScenario(scenario)) {
      const scenarioIndex = calculation.scenarios.findIndex(
        calculationScenario => calculationScenario.originalScenario === scenario
      );

      calculation.scenarios.splice(scenarioIndex, 1);
    } else {
      calculation.scenarios.push(new Scenario(scenario));
    }
  }
}
