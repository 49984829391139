import { Measures } from '../../store/domain/measures';
import { CalculationValue } from '../../store/domain/calculation-value';
import { SummaryCalculationValue } from '../../store/domain/summary-calculation-value';

export interface MakeCsvExportFileEvent {
  fileOrUrl: string | File;
  outputValues: CalculationValue[];
  outputSummaryValues: SummaryCalculationValue;
  measures: Measures[];
  SummaryMeasures: Measures[];
}

export enum PostMessageEventType {
  NewRow,
  EndFile,
  Error
}

export interface BaseMakeCsvExportFilePostMessage<T extends PostMessageEventType> {
  type: T;
}

export interface MakeCsvExportFileNewRowPostMessage
  extends BaseMakeCsvExportFilePostMessage<PostMessageEventType.NewRow> {
  row: string;
}

export interface MakeCsvExportFileEndFilePostMessage
  extends BaseMakeCsvExportFilePostMessage<PostMessageEventType.EndFile> {}

export interface MakeCsvExportFileErrorPostMessage
  extends BaseMakeCsvExportFilePostMessage<PostMessageEventType.Error> {
  error: any;
}

export type MakeCsvExportFilePostMessage =
  | MakeCsvExportFileEndFilePostMessage
  | MakeCsvExportFileErrorPostMessage
  | MakeCsvExportFileNewRowPostMessage;
