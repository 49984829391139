import './intervention-amount-input.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { NumericInput } from '@blueprintjs/core';
import { ScenarioMethods } from '@ottawamhealth/pbl-calculator-engine/lib/scenario';
import { Intervention } from '../../../store/domain/intervention';
import { Scenario } from '../../../store/domain/scenario';
import { WorkerUtil } from '../../../store/domain/utils/worker-util';
import { Calculation } from '../../../store/domain/calculation';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Tutorial } from '../../../store/domain/tutorial';
import { computed } from 'mobx';
import { DocumentationLink } from '../DocumentationLink';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IInterventionAmountInputProps {
  calculation: Calculation;
  intervention: Intervention;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class InterventionAmountInput extends React.Component<IInterventionAmountInputProps> {
  render() {
    const { calculation, intervention } = this.props;
    const scenario = this.getInterventionSelectedScenario(intervention);
    const isEnabled = calculation.isScenarioEnabled && !!scenario && scenario.canUpdate();

    return (
      <TutorialStep
        stepComponent={TutorialSteps.InterventionAmount}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={this.uiScenarioValue === this.props.tutorial!.scenarioAmount}
        instructions={this.renderTutorialInstructions()}
      >
        <div className='intervention-amount-container'>
          <NumericInput
            disabled={!isEnabled}
            value={this.uiScenarioValue}
            onValueChange={value => this.setScenarioValue(scenario!, value)}
            className='intervention-amount-input'
          />
          {/* Extra div added to prevent dblclick events from selecting text after input. e.preventDefault doesn't work */}
          <div></div>
          <div className='scenario-units'>{this.getUnits(scenario)}</div>
        </div>
      </TutorialStep>
    );
  }

  private setScenarioValue(scenario: Scenario, value: number) {
    // If scenario is relative, convert whole number to decimal
    if (scenario.method === ScenarioMethods.Relative) value = value / 100;
    scenario.setValues(WorkerUtil.toPrecision(value));
  }

  private getInterventionSelectedScenario(intervention: Intervention) {
    return this.props.calculation.scenarios.find(scenario =>
      intervention.scenarios.includes(scenario.originalScenario)
    );
  }

  private renderTutorialMessage() {
    const initialPrevalence = 20;
    const absScenarioAmount = Math.abs(this.props.tutorial!.scenarioAmount);
    const prevalenceDifference = absScenarioAmount * (initialPrevalence / 100);
    const newPrevalence = initialPrevalence - prevalenceDifference;

    return (
      <div>
        <div>
          The <i>Amount</i> field determines how much to modify these variables.
        </div>
        <br />
        <div>
          A {absScenarioAmount}% relative reduction reduces the current prevalence of smoking by{' '}
          {absScenarioAmount}%. If current smoking prevalence is {initialPrevalence}% then this
          intervention will reduce the prevalence of current smokers to {newPrevalence}%, a relative
          decrease of {absScenarioAmount}%. Former smokers will increase by the corresponding amount
          ({prevalenceDifference}% in absolute terms).
        </div>
        <div>
          See the <DocumentationLink /> for more details.
        </div>
      </div>
    );
  }

  private renderTutorialInstructions() {
    const tutorial = this.props.tutorial!;

    return (
      <div>
        Enter <strong>{tutorial.scenarioAmount}</strong> to reduce the smoking prevalence by{' '}
        {Math.abs(tutorial.scenarioAmount)}%.
      </div>
    );
  }

  private getUnits(scenario?: Scenario) {
    if (scenario) {
      if (scenario.method === ScenarioMethods.Relative) return '%';
      else if (scenario.originalScenario.units) return scenario.originalScenario.units;
    }
    return '';
  }

  @computed
  private get uiScenarioValue() {
    const { intervention } = this.props;
    const scenario = this.getInterventionSelectedScenario(intervention);

    let value = 0;
    if (scenario) {
      value = scenario.male.variables[0].scenarioValue;
      // If scenario is relative, convert to whole number for clarity
      if (scenario.method === ScenarioMethods.Relative) value = value * 100;
    }
    return WorkerUtil.toPrecision(value);
  }
}
