export abstract class NotificationUtil {
  static notifyIfGranted(title: string, message?: string) {
    if (Notification.permission !== 'granted') return;

    return new Notification(title, {
      body: message,
      requireInteraction: true,
      icon:
        'https://uploads-ssl.webflow.com/5a05f82b0703c2000143c96a/5b55f63286b0555f4dbef5d3_favicon.png'
    });
  }
}
