import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Calculation } from './calculation';
import { AnalysisDataFile } from './analysis-data-file';
import { Measures } from './measures';
import { store } from '../store';
import { outputColumns } from './column';
import { MakeCsvExportFileHandler } from '../../workers/make-csv-export-file/make-csv-export-file-handler';
import { c } from './c';
import { LongTableHandler } from '../../workers/long-table/long-table-handler';
import { FormatUtil } from './utils/format-util';
import { ReadRespectFileHandler } from '../../workers/read-respect-file/read-respect-file-handler';
import { IRecord } from './record';

export enum DownloadFileTypes {
  None = 'None',
  Input = 'Input',
  Summary = 'Summary',
  LongTable = 'LongTable',
  Full = 'Full'
}

export abstract class DownloadFile {
  static downloadFileType(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    measures: Measures[],
    scenarioMeasuresToInclude: Measures[],
    downloadFileType: DownloadFileTypes
  ) {
    switch (downloadFileType) {
      case DownloadFileTypes.Input:
        return this.downloadInput(analysisDataFile, calculation, measures);
      case DownloadFileTypes.Summary:
        return this.downloadSummary(
          analysisDataFile,
          calculation,
          measures,
          scenarioMeasuresToInclude
        );
      case DownloadFileTypes.LongTable:
        return this.downloadLongTable(analysisDataFile, calculation, measures);
      case DownloadFileTypes.Full:
        return this.downloadAll(analysisDataFile, calculation, measures, scenarioMeasuresToInclude);
    }
  }

  static downloadConvertedFile(analysisDataFile: AnalysisDataFile) {
    return this.downloadAsRespect(analysisDataFile);
  }

  static buildOutputFileName(
    inputFileName: string,
    ...suffixes: Array<string | DownloadFileTypes>
  ) {
    let outputFileName = inputFileName.substr(0, inputFileName.lastIndexOf('.'));

    suffixes.forEach((suffix) => (outputFileName = outputFileName.concat('_').concat(suffix)));

    return outputFileName;
  }

  /**
   * @description Create a worker to build an output file for a calculation, and download it when
   * it's ready
   * @param analysisDataFile Analysis data file
   * @param calculation Calculation
   */
  private static async downloadInput(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    measuresToInclude: Measures[]
  ) {
    const fileAsString = await MakeCsvExportFileHandler.buildInput(
      analysisDataFile,
      calculation,
      measuresToInclude
    );
    this.downloadZippedFile(analysisDataFile, calculation, fileAsString, DownloadFileTypes.Input);
  }

  private static downloadSummary(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    measuresToInclude: Measures[],
    scenarioMeasuresToInclude: Measures[]
  ) {
    const fileContent = this.buildCalculationSummaryFile(
      calculation,
      measuresToInclude,
      scenarioMeasuresToInclude
    );
    this.downloadZippedFile(analysisDataFile, calculation, fileContent, DownloadFileTypes.Summary);
  }

  private static async downloadLongTable(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    measuresToInclude: Measures[]
  ) {
    const fileAsString = await LongTableHandler.makeLongTable(
      analysisDataFile,
      calculation,
      measuresToInclude
    );
    this.downloadZippedFile(
      analysisDataFile,
      calculation,
      fileAsString,
      DownloadFileTypes.LongTable
    );
  }

  private static async downloadAll(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    measures: Measures[],
    scenarioMeasures: Measures[]
  ) {
    const outputFiles: string[] = [];
    const downloadFileTypes: DownloadFileTypes[] = [];

    if (calculation.isStratified) {
      outputFiles.push(
        await LongTableHandler.makeLongTable(analysisDataFile, calculation, measures)
      );
      downloadFileTypes.push(DownloadFileTypes.LongTable);
    } else {
      outputFiles.push(
        await MakeCsvExportFileHandler.buildInput(analysisDataFile, calculation, measures)
      );
      downloadFileTypes.push(DownloadFileTypes.Input);

      if (calculation.hasSummaryMeasures) {
        outputFiles.push(this.buildCalculationSummaryFile(calculation, measures, scenarioMeasures));
        downloadFileTypes.push(DownloadFileTypes.Summary);
      }
    }

    await this.downloadZippedFile(analysisDataFile, calculation, outputFiles, downloadFileTypes);
  }

  private static async downloadAsRespect(analysisDataFile: AnalysisDataFile) {
    const records = await ReadRespectFileHandler.loadRecords(analysisDataFile.fileOrUrl);
    const variables = [...c.respectIdVariables, ...analysisDataFile.variables];

    const headers = variables.join(',');
    const rows = this.stringifyRecords(records, variables);

    const fileAsString = [headers, rows].join('\n');
    const fileAsBlob = new Blob([fileAsString], { type: 'text/plain;charset=utf-8' });
    saveAs(fileAsBlob, this.buildOutputFileName(analysisDataFile.name, 'converted.csv'));
  }

  private static async downloadZippedFile(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    files: string | string[],
    downloadFileTypes: DownloadFileTypes | DownloadFileTypes[]
  ) {
    const isDownloadingFull = Array.isArray(files);
    if (!Array.isArray(files)) files = [files];
    if (!Array.isArray(downloadFileTypes)) downloadFileTypes = [downloadFileTypes];

    const zip = new JSZip();
    const filesWithVersions = this.appendVersionsToStrings(files, calculation);
    const fileName = this.buildOutputFileName(analysisDataFile.name, calculation.name);

    filesWithVersions.forEach((file, i) =>
      zip.file(`${fileName}_${downloadFileTypes[i]}.csv`, file)
    );
    zip.file(`${fileName}_calculation.json`, this.buildCalculationFile(calculation));
    const zipContent = await zip.generateAsync({ type: 'blob' });
    const suffix = isDownloadingFull ? DownloadFileTypes.Full : downloadFileTypes[0];
    saveAs(zipContent, `${fileName}_${suffix}.zip`);
  }

  private static buildCalculationSummaryFile(
    calculation: Calculation,
    measuresToInclude: Measures[],
    scenarioMeasuresToInclude: Measures[]
  ) {
    const headers: string[] = [];
    const row: Array<string | number> = [];

    store.algorithmMeasure.SummaryMeasures.forEach((measure) => {
      if (measuresToInclude.includes(measure)) {
        headers.push(c.measureToCsvHeaderMap[measure]);
        row.push(calculation.summaryValues.measureValues[measure]);
      }
    });

    if (calculation.isStratified) {
      scenarioMeasuresToInclude.forEach((measure) => {
        headers.push(`${c.scenarioCsvHeaderPrefix}${c.measureToCsvHeaderMap[measure]}`);
        row.push(calculation.getScenarioMeasureValue(measure));
      });
    }

    headers.push(outputColumns.sampleSize.name, outputColumns.population.name);
    row.push(calculation.population, calculation.sampleSize);

    return headers.join(',').concat('\n').concat(row.join(','));
  }

  /**
   * @description Add app and engine versions to each line in file strings
   * @param files Files as strings
   */
  private static appendVersionsToStrings(files: string[], calculation: Calculation) {
    return files.map((file) => {
      let rowIndex = 0;

      return (
        file
          .trim()
          // Since the file is one string, use 'm' to treat string as multiple lines,
          // each with a '$'. Use 'g' to apply regex to every match instead of only first match
          .replace(/$/gm, () => {
            let suffix = '';

            if (rowIndex === 0)
              suffix += `,${outputColumns.appVersion.name},${outputColumns.engineVersion.name},${outputColumns.algorithmName.name},${outputColumns.algorithmVersion.name}`;
            else
              suffix += `,${store.appVersion},${store.engineVersion},${calculation.model.name},${calculation.model.version}`;

            ++rowIndex;
            return suffix;
          })
      );
    });
  }

  /**
   * @description Stringify a calculation
   * @param calculation Calculation
   */
  private static buildCalculationFile(calculation: Calculation) {
    return FormatUtil.stringify({
      name: calculation.name,
      description: calculation.description,
      measures: calculation.measures,
      stratifications: calculation.stratifications,
      filters: calculation.filters
    });
  }

  private static stringifyRecords(records: IRecord[], variables: string[]) {
    const stringifiedRecords: string[] = [];

    records.forEach((record) =>
      stringifiedRecords.push([...variables.map((variable) => record[variable])].join(','))
    );

    return stringifiedRecords.join('\n');
  }
}
