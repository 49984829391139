import './footer.scss';

import React from 'react';
import { H6, Divider } from '@blueprintjs/core';
import { LicensesButton } from '../LicensesButton';
import { c } from '../../../store/domain/c';

export interface IFooterProps {
  appVersion: string;
  engineVersion: string;
}

export class Footer extends React.Component<IFooterProps> {
  render() {
    return (
      <footer>
        <div className='footer-container'>
          <H6>App version: {this.props.appVersion}</H6>
          <Divider className='footer-divider' />
          <H6>Engine version: {this.props.engineVersion}</H6>
        </div>
        <div className='footer-container'>
          <a href='https://www.projectbiglife.ca'>projectbiglife.ca</a>
          <Divider className='footer-divider' />
          <a href={`mailto:${c.contactEmail}?subject=${c.appName}: Feedback`}>Send feedback</a>
          <Divider className='footer-divider' />
          <LicensesButton />
        </div>
      </footer>
    );
  }
}
