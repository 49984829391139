import './closable-message.scss';

import React from 'react';
import { Callout, Intent, Button } from '@blueprintjs/core';
import { autobind } from 'core-decorators';

export interface IClosableMessage {
  message: string;
  intent: Intent;
}

export interface IClosableMessageProps extends IClosableMessage {
  onClose: () => void;
}

@autobind
export class ClosableMessage extends React.Component<IClosableMessageProps> {
  render() {
    const { message, intent } = this.props;

    return (
      <Callout intent={intent}>
        <div>
          {message}
          <Button
            className='closable-message-close-button'
            icon='small-cross'
            onClick={this.props.onClose}
            small
            minimal
          />
        </div>
      </Callout>
    );
  }
}
