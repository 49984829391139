import './string-as-html.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';

export interface IStringAsHtmlProps {
  message: string;
}

@autobind
@observer
export class StringAsHtml extends React.Component<IStringAsHtmlProps> {
  render() {
    const { message } = this.props;

    return (
      <div>
        {message
          .split(/\n/)
          .map((message, i) =>
            message.trim() ? <div key={i}>{message.trim()}</div> : <br key={i} />
          )}
      </div>
    );
  }
}
