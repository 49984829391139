import './calculation-builder.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { StratificationManager } from '../StratificationManager';
import { FilterManager } from '../FilterManager/FilterManager';
import { CalculationNameInput } from '../CalculationNameInput';
import { MeasureSelection } from '../MeasureSelection';
import { ScenarioManager } from '../ScenarioManager';
import { H4 } from '@blueprintjs/core';
import { CalculationBuilderMenu } from '../CalculationBuilderMenu';
import { CalculationDescriptionTextbox } from '../CalculationDescriptionTextbox';

export interface ICalculationBuilderProps {
  analysisDataFile: AnalysisDataFile;
  isCalculationNameDuplicate: () => boolean;
}

@observer
@autobind
export class CalculationBuilder extends React.Component<ICalculationBuilderProps> {
  render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;

    return (
      <div className='calculation-builder'>
        <div className='calculation-options'>
          <H4>Calculation: {activeCalculation.name}</H4>
          <CalculationNameInput
            isCalculationNameDuplicate={this.props.isCalculationNameDuplicate}
            analysisDataFile={analysisDataFile}
          />
          <CalculationDescriptionTextbox calculation={activeCalculation} />
          <MeasureSelection analysisDataFile={analysisDataFile} />
          {activeCalculation.areFiltersEnabled && (
            <FilterManager analysisDataFile={analysisDataFile} />
          )}
          {activeCalculation.areStratificationsEnabled && (
            <StratificationManager analysisDataFile={analysisDataFile} />
          )}
          {activeCalculation.isScenarioEnabled && (
            <ScenarioManager analysisDataFile={analysisDataFile} />
          )}
        </div>
        <CalculationBuilderMenu analysisDataFile={analysisDataFile} />
      </div>
    );
  }
}
