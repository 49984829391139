import './output-fields-selection.scss';

import React from 'react';
import { HTMLTable, Checkbox } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { observer, inject } from 'mobx-react';
import { Measures } from '../../../store/domain/measures';
import { autobind } from 'core-decorators';
import { DownloadFileTypes } from '../../../store/domain/download-file';
import { outputColumns, IColumn } from '../../../store/domain/column';
import { c } from '../../../store/domain/c';
import { ScenarioTypes } from '../../../store/domain/scenario';
import { AlgorithmMeasure } from '../../../store/domain/algorithm-measure';
import { Store } from '../../../store/store';

export interface IOutputFieldsSelectionProps {
  analysisDataFile: AnalysisDataFile;
  onMeasureToggle: (measure: Measures) => void;
  onScenarioMeasureToggle: (measure: Measures) => void;
  scenarioMeasuresToInclude: Measures[];
  measuresToInclude: Measures[];
  downloadFileType: DownloadFileTypes;
  algorithmMeasure?: AlgorithmMeasure;
}

@inject((store: Store) => {
  return {
    algorithmMeasure: store.algorithmMeasure
  };
})
@autobind
@observer
export class OutputFieldsSelection extends React.Component<IOutputFieldsSelectionProps> {
  render() {
    const { downloadFileType } = this.props;
    const calculation = this.props.analysisDataFile.selectedCalculation!;
    const shouldRenderIssueRows = downloadFileType === DownloadFileTypes.Input;
    const shouldRenderSampleSizeAndPopulationRows = [
      DownloadFileTypes.Summary,
      DownloadFileTypes.LongTable
    ].includes(downloadFileType);
    const shouldRenderScenarioRow =
      downloadFileType === DownloadFileTypes.Summary &&
      calculation.scenarioType !== ScenarioTypes.None;

    return (
      <HTMLTable bordered condensed striped>
        <thead>
          <tr>
            <th>Include in file</th>
            <th>Label</th>
            <th>Column name</th>
          </tr>
        </thead>
        <tbody>
          {this.renderMeasureSelectionRows()}
          {shouldRenderIssueRows && this.renderMandatoryColumnRow(outputColumns.errors)}
          {shouldRenderIssueRows && this.renderMandatoryColumnRow(outputColumns.warnings)}
          {shouldRenderScenarioRow && this.renderDownloadScenarioRows()}
          {shouldRenderSampleSizeAndPopulationRows &&
            this.renderMandatoryColumnRow(outputColumns.population)}
          {shouldRenderSampleSizeAndPopulationRows &&
            this.renderMandatoryColumnRow(outputColumns.sampleSize)}
          {this.renderMandatoryColumnRow(outputColumns.appVersion)}
          {this.renderMandatoryColumnRow(outputColumns.engineVersion)}
        </tbody>
      </HTMLTable>
    );
  }

  /**
   * @description Render measure checkboxes. If the user is downloading the long table, only
   * display summary measures
   */
  private renderMeasureSelectionRows() {
    const selectedCalculation = this.props.analysisDataFile.selectedCalculation!;
    let filteredMeasures = [...selectedCalculation.measures];
    if (this.props.downloadFileType === DownloadFileTypes.LongTable) {
      filteredMeasures = filteredMeasures.filter(measure =>
        this.props.algorithmMeasure!.SummaryMeasures.includes(measure)
      );
    }

    return filteredMeasures.map(measure => (
      <tr key={measure}>
        <td className='centered-content'>
          <Checkbox
            checked={this.props.measuresToInclude.includes(measure)}
            onClick={() => this.props.onMeasureToggle(measure)}
          />
        </td>
        <td>{measure}</td>
        <td>{c.measureToCsvHeaderMap[measure]}</td>
      </tr>
    ));
  }

  private renderMandatoryColumnRow(columnName: IColumn) {
    return (
      <tr>
        <td className='centered-content'>
          <Checkbox checked disabled />
        </td>
        <td>{columnName.label}</td>
        <td>{columnName.name}</td>
      </tr>
    );
  }

  private renderDownloadScenarioRows() {
    const measures = this.props.analysisDataFile.selectedCalculation!
      .activeMeasuresUsableForScenarios;

    return measures.map(measure => (
      <tr key={measure}>
        <td className='centered-content'>
          <Checkbox
            checked={this.props.scenarioMeasuresToInclude.includes(measure)}
            onChange={() => this.props.onScenarioMeasureToggle(measure)}
          />
        </td>
        <td>
          {c.scenarioCsvHeaderPrefix} - {measure}
        </td>
        <td>
          {c.scenarioCsvHeaderPrefix}
          {c.measureToCsvHeaderMap[measure]}
        </td>
      </tr>
    ));
  }
}
