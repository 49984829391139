import './download-button.scss';

import React from 'react';
import { Button, Dialog, Intent, Spinner } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { inject, observer } from 'mobx-react';
import { Measures } from '../../../store/domain/measures';
import { autobind } from 'core-decorators';
import { DownloadFileTypes, DownloadFile } from '../../../store/domain/download-file';
import { computed } from 'mobx';
import { Store } from '../../../store/store';
import { c } from '../../../store/domain/c';

export interface IDownloadButtonProps {
  analysisDataFile: AnalysisDataFile;
  measuresToInclude: Measures[];
  selectedDownloadFileType: DownloadFileTypes;
  scenarioMeasuresToInclude: Measures[];
  filesBeingDownloaded?: string[];
}

interface IDownloadButtonState {
  hasDownloadError: boolean;
}

@inject((store: Store) => {
  return {
    filesBeingDownloaded: store.filesBeingDownloaded
  };
})
@autobind
@observer
export class DownloadButton extends React.Component<IDownloadButtonProps, IDownloadButtonState> {
  constructor(props: IDownloadButtonProps) {
    super(props);

    this.state = {
      hasDownloadError: false
    };
  }

  render() {
    const { analysisDataFile, selectedDownloadFileType } = this.props;
    const calculation = analysisDataFile.selectedCalculation!;
    const hasNoMeasuresSelected = this.props.measuresToInclude.length === 0;
    const isDisabled = hasNoMeasuresSelected || this.isFileBeingDownloaded;

    const text =
      selectedDownloadFileType === DownloadFileTypes.Full
        ? c.downloadResultsText
        : `Download file: ${this.fileName}`;

    let intent: Intent = Intent.SUCCESS;
    if (this.isFileBeingDownloaded) intent = Intent.NONE;
    else if (calculation.hasSmallSampleSize) intent = Intent.WARNING;

    const icon = this.isFileBeingDownloaded ? (
      <Spinner intent={intent} size={Spinner.SIZE_SMALL} />
    ) : (
      'download'
    );

    return (
      <span>
        <Button
          text={text}
          onClick={this.downloadSelectedFile}
          icon={icon}
          intent={intent}
          disabled={isDisabled}
        />
        <Dialog
          isOpen={this.state.hasDownloadError}
          title='Download error'
          onClose={this.onDownloadErrorDialogClose}
          portalContainer={document.body}
          lazy
        >
          <div>There was an error downloading your results.</div>
          <div>
            Due to the nature of {c.theAppName} and the sensitivity of the your data, please{' '}
            <a href={`mailto:${c.contactEmail}?subject=${c.appName}: Download error`}>contact us</a>{' '}
            to resolve this issue.
          </div>
        </Dialog>
      </span>
    );
  }

  private onDownloadErrorDialogClose() {
    this.setState({
      hasDownloadError: false
    });
  }

  /**
   * @description Download a file based on selected file type
   */
  private async downloadSelectedFile() {
    this.onDownloadStart();
    const {
      analysisDataFile,
      measuresToInclude,
      selectedDownloadFileType,
      scenarioMeasuresToInclude
    } = this.props;

    try {
      await DownloadFile.downloadFileType(
        analysisDataFile,
        analysisDataFile.selectedCalculation!,
        measuresToInclude,
        scenarioMeasuresToInclude,
        selectedDownloadFileType
      );
    } catch (e) {
      console.log('Unable to download file:\n', e);
      this.setState({
        hasDownloadError: true
      });
    }

    this.onDownloadComplete();
  }

  private onDownloadStart() {
    this.props.filesBeingDownloaded!.push(this.fileName);
  }

  private onDownloadComplete() {
    const filesBeingDownloaded = this.props.filesBeingDownloaded!;
    const index = filesBeingDownloaded.indexOf(this.fileName);
    filesBeingDownloaded.splice(index, 1);
  }

  @computed
  private get isFileBeingDownloaded() {
    const { analysisDataFile } = this.props;

    const fileName = DownloadFile.buildOutputFileName(
      analysisDataFile.name,
      analysisDataFile.selectedCalculation!.name,
      this.props.selectedDownloadFileType
    ).concat('.zip');

    return this.props.filesBeingDownloaded!.includes(fileName);
  }

  @computed
  private get fileName() {
    const { analysisDataFile, selectedDownloadFileType } = this.props;

    return DownloadFile.buildOutputFileName(
      analysisDataFile.name,
      analysisDataFile.selectedCalculation!.name,
      selectedDownloadFileType
    ).concat('.zip');
  }
}
