import './categorical-options.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { CategorySearch } from '../CategorySearch';
import { Filter } from '../../../store/domain/filter';
import { FilterIssueManager } from '../FilterIssueManager';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Tutorial } from '../../../store/domain/tutorial';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICategoricalOptionsProps {
  analysisDataFile: AnalysisDataFile;
  filter: Filter;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class CategoricalOptions extends React.Component<ICategoricalOptionsProps> {
  render() {
    return (
      <TutorialStep
        stepComponent={TutorialSteps.SelectFilterCategories}
        tutorialMessage={this.renderTutorialMessage()}
        isTutorialStepComplete={this.isTutorialStepComplete}
        showContinueButton={false}
      >
        <div className='categorical-options'>
          <div className='option-input'>
            <CategorySearch
              analysisDataFile={this.props.analysisDataFile}
              filter={this.props.filter}
            />
          </div>
          <FilterIssueManager
            filter={this.props.filter}
            analysisDataFile={this.props.analysisDataFile}
          />
        </div>
      </TutorialStep>
    );
  }

  private renderTutorialMessage() {
    const tutorial = this.props.tutorial!;

    return (
      <div>
        <div>
          We can now select the <i>categories</i> to include in the filter. You can select as many
          categories as you want.
        </div>
        <br />
        <div>
          Select the <i>{tutorial.categoryToSelectLabel}</i> category. Filters are inclusive, so
          only rows with a <i>{tutorial.variableToClick}</i> value of{' '}
          <i>
            {tutorial.categoryToSelectLabel} ({tutorial.categoryToSelect})
          </i>{' '}
          will be used in the calculation.
        </div>
      </div>
    );
  }

  private isTutorialStepComplete() {
    const { categories } = this.props.filter;
    return categories.length === 1 && categories.includes(this.props.tutorial!.categoryToSelect);
  }
}
