import './configuration-menu.scss';

import React from 'react';
import { autobind } from 'core-decorators';
import { inject, observer } from 'mobx-react';
import { transaction } from 'mobx';

import { Store } from '../../../store/store';
import { AnalysisData } from '../../../store/domain/analysis-data';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { DuplicateFilesDialog } from '../DuplicateFilesDialog';
import { FileManager } from '../../dumb/FileManager';
import { CalculationSelection } from '../../dumb/CalculationSelection';
import { RenderUtil } from '../../../store/domain/utils/render-util';

export interface IConfigurationMenuProps {
  analysisData?: AnalysisData;
}

interface IConfigurationMenuState {
  duplicateSelectedFiles: File[];
}

@inject((store: Store) => {
  return {
    analysisData: store.analysisData
  };
})
@observer
@autobind
export class ConfigurationMenu extends React.Component<
  IConfigurationMenuProps,
  IConfigurationMenuState
> {
  constructor(props: IConfigurationMenuProps) {
    super(props);

    this.state = {
      duplicateSelectedFiles: []
    };
  }

  render() {
    const { selectedAnalysisDataFile: analysisDataFile } = this.props.analysisData!;
    let canRunCalculations = false;
    if (analysisDataFile) canRunCalculations = analysisDataFile.canRunCalculations;

    return (
      <div>
        <div className='configuration-menu'>
          <FileManager
            analysisData={this.props.analysisData!}
            onFileInputChange={this.onFileInputChange}
          />
          {canRunCalculations && <CalculationSelection analysisDataFile={analysisDataFile!} />}
        </div>
        <DuplicateFilesDialog
          onCancelClick={this.discardDuplicateSelectedFiles}
          onAcceptClick={() => {
            this.uploadFiles(this.state.duplicateSelectedFiles);
            this.discardDuplicateSelectedFiles();
          }}
          existingFilesWithSameNames={this.state.duplicateSelectedFiles.map(
            (file) => this.props.analysisData!.findUserDataFileWithName(file.name)!
          )}
        />
      </div>
    );
  }

  /**
   * @description Pre-process file, flag missing variables, and determine if it passes validation
   */
  private onFileInputChange(e: React.FormEvent<HTMLInputElement>) {
    const { files } = e.currentTarget;

    if (!files || !files.length) return this.discardDuplicateSelectedFiles();

    const fileList = Array.from(files);

    const duplicateFiles: File[] = [];
    const newFiles: File[] = [];

    fileList.forEach((file) => {
      const fileExists = this.props.analysisData!.findUserDataFileWithName(file.name);
      if (fileExists) duplicateFiles.push(file);
      else newFiles.push(file);
    });

    this.setState({
      duplicateSelectedFiles: duplicateFiles
    });

    this.uploadFiles(newFiles);
    RenderUtil.clearFileInput(e);
  }

  /**
   * @description Upload files. Replaces any uploaded files with the same name.
   */
  private uploadFiles(files: File[]) {
    const analysisData = this.props.analysisData!;

    transaction(() => {
      let lastAnalysisDataFile: AnalysisDataFile | undefined;

      files.forEach((file) => {
        analysisData!.removeUserDataAnalysisFileByName(file.name);

        lastAnalysisDataFile = analysisData!.addNewDataAnalysisFile(file);
      });

      if (analysisData.selectedAnalysisDataFile && lastAnalysisDataFile) {
        analysisData.setSelectedAnalysisDataFile(lastAnalysisDataFile);
      }
    });
  }

  private discardDuplicateSelectedFiles() {
    this.setState({
      duplicateSelectedFiles: []
    });
  }
}
