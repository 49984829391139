import './scenario-description.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { H5 } from '@blueprintjs/core';
import { Calculation } from '../../../store/domain/calculation';
import { ScenarioMethods } from '@ottawamhealth/pbl-calculator-engine/lib/scenario';
import { WorkerUtil } from '../../../store/domain/utils/worker-util';
import { ScenarioTypes } from '../../../store/domain/scenario';

export interface IScenarioDescriptionProps {
  analysisDataFile: AnalysisDataFile;
  calculation: Calculation;
}

const healthBehaviourMethods: ScenarioMethods[] = [ScenarioMethods.Attribution];
const interventionMethods: ScenarioMethods[] = [
  ScenarioMethods.Absolute,
  ScenarioMethods.Relative,
  ScenarioMethods.Target
];

const measuresRegExp = /{{measures}}/g;
const scenarioValueRegExp = /{{scenarioValue}}/g;

@observer
@autobind
export class ScenarioDescription extends React.Component<IScenarioDescriptionProps> {
  render() {
    const { calculation } = this.props;

    // If no measures are selected, have a phrase to replace empty space
    const measures =
      calculation.measures.length > 0
        ? calculation.activeMeasuresUsableForScenarios.join(', ')
        : 'selected measures';

    let scenarioMethods: ScenarioMethods[];
    let descriptionPrefix: string;

    if (calculation.scenarioType === ScenarioTypes.HealthBehaviour) {
      descriptionPrefix = 'Calculate the {{measures}} after changing respondents to meet:';
      scenarioMethods = healthBehaviourMethods;
    } else {
      descriptionPrefix = "Calculate the {{measures}} after changing the respondents':";
      scenarioMethods = interventionMethods;
    }

    const updatedDescriptionPrefix = descriptionPrefix.replace(measuresRegExp, measures);

    return (
      <div>
        <H5>Descriptions</H5>
        <div className='scenario-description-container'>
          <div>{updatedDescriptionPrefix}</div>
          <ul>
            {calculation.scenarios
              .filter(scenario => scenario.description)
              .filter(scenario => scenarioMethods.includes(scenario.method))
              .map((scenario, i) => {
                const { scenarioValue } = scenario.male.variables[0];
                const value =
                  scenario.method === ScenarioMethods.Relative
                    ? WorkerUtil.toPrecision(scenarioValue * 100)
                    : scenarioValue;

                return (
                  <li key={i}>
                    {this.buildDescriptionParts(
                      scenario.description!.split(scenarioValueRegExp),
                      value
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }

  private buildDescriptionParts(messages: string[], scenarioValue: number) {
    const parts: Array<string | number | JSX.Element> = [];

    messages.forEach((message, i) => {
      parts.push(message);
      if (i !== messages.length - 1) parts.push(<u key={message}>{scenarioValue}</u>);
    });

    return parts;
  }
}
