import './download-files-dialog.scss';

import React from 'react';
import { Dialog, Classes, Button, Intent, H5, Callout, Divider } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { observer } from 'mobx-react';
import { Measures } from '../../../store/domain/measures';
import { autobind } from 'core-decorators';
import { DownloadFilesTable } from '../DownloadFilesTable';
import { OutputFieldsSelection } from '../OutputFieldsSelection';
import { DownloadFileTypes } from '../../../store/domain/download-file';
import { Calculation } from '../../../store/domain/calculation';
import { DownloadButton } from '../../smart/DownloadButton';
import { ArrayUtil } from '../../../store/domain/utils/array-util';
import { c } from '../../../store/domain/c';

export interface IDownloadFilesDialogProps {
  isOpen: boolean;
  analysisDataFile: AnalysisDataFile;
  calculation: Calculation;
  onClose: () => void;
}

interface IDownloadFilesDialogState {
  measuresToInclude: Measures[];
  selectedDownloadFileType: DownloadFileTypes;
  scenarioMeasuresToInclude: Measures[];
}

@autobind
@observer
export class DownloadFilesDialog extends React.Component<
  IDownloadFilesDialogProps,
  IDownloadFilesDialogState
> {
  constructor(props: IDownloadFilesDialogProps) {
    super(props);

    this.state = this.buildNewState();
  }

  render() {
    const { analysisDataFile } = this.props;
    const shouldRenderOutputFieldsSelection =
      this.state.selectedDownloadFileType !== DownloadFileTypes.None;

    return (
      <Dialog
        isOpen={this.props.isOpen}
        title={c.downloadResultsText}
        onClose={this.props.onClose}
        portalContainer={document.body}
        lazy
      >
        <div className='download-files-dialog-content'>
          <H5>Download files</H5>
          <DownloadFilesTable
            analysisDataFile={analysisDataFile}
            downloadFileType={this.state.selectedDownloadFileType}
            onDownloadFileTypeSelect={this.onDownloadFileTypeSelect}
          />
          {shouldRenderOutputFieldsSelection && this.renderOutputFieldsSelection()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text='Close' onClick={this.props.onClose} />
          </div>
        </div>
      </Dialog>
    );
  }

  /**
   * @description Reset the state of this component if the calculation has changed
   * @param prevProps Previous props
   */
  public componentDidUpdate(prevProps: IDownloadFilesDialogProps) {
    if (this.props.calculation !== prevProps.calculation) this.resetState();
  }

  private resetState() {
    this.setState(this.buildNewState);
  }

  private buildNewState(): IDownloadFilesDialogState {
    const calculation = this.props.analysisDataFile.selectedCalculation!;

    return {
      measuresToInclude: calculation.measures,
      selectedDownloadFileType: DownloadFileTypes.None,
      scenarioMeasuresToInclude: calculation.activeMeasuresUsableForScenarios
    };
  }

  private renderOutputFieldsSelection() {
    const { analysisDataFile } = this.props;
    const { selectedDownloadFileType } = this.state;
    const calculation = analysisDataFile.selectedCalculation!;

    const renderSampleSizeWarning = calculation.hasSmallSampleSize;
    const hasNoMeasuresSelected = this.state.measuresToInclude.length === 0;

    return (
      <div>
        <Divider />
        <div className='output-fields-selection-container'>
          <H5>Add columns to files</H5>
          <OutputFieldsSelection
            analysisDataFile={analysisDataFile}
            onMeasureToggle={this.onMeasureToggle}
            onScenarioMeasureToggle={this.onDownloadScenarioChange}
            measuresToInclude={this.state.measuresToInclude}
            scenarioMeasuresToInclude={this.state.scenarioMeasuresToInclude}
            downloadFileType={selectedDownloadFileType}
          />
          {renderSampleSizeWarning && this.renderSampleSizeWarning()}
          {hasNoMeasuresSelected && this.renderEmptyMeasureSelectionError()}
          <DownloadButton
            analysisDataFile={analysisDataFile}
            measuresToInclude={this.state.measuresToInclude}
            selectedDownloadFileType={this.state.selectedDownloadFileType}
            scenarioMeasuresToInclude={this.state.scenarioMeasuresToInclude}
          />
        </div>
      </div>
    );
  }

  private renderSampleSizeWarning() {
    const { smallestSampleSize } = this.props.analysisDataFile.selectedCalculation!;

    return (
      <div>
        <div className='download-file-callout'>
          <Callout intent={Intent.WARNING}>
            Results contain small sample sizes: {smallestSampleSize}
          </Callout>
        </div>
      </div>
    );
  }

  private renderEmptyMeasureSelectionError() {
    return (
      <div>
        <div className='download-file-callout'>
          <Callout intent={Intent.DANGER}>Please select at least one measure</Callout>
        </div>
      </div>
    );
  }

  private onMeasureToggle(measure: Measures) {
    this.setState({
      measuresToInclude: ArrayUtil.toggle(this.state.measuresToInclude, measure)
    });
  }

  private onDownloadFileTypeSelect(selectedDownloadFileType: DownloadFileTypes) {
    this.setState({
      selectedDownloadFileType
    });
  }

  private onDownloadScenarioChange(measure: Measures) {
    this.setState({
      scenarioMeasuresToInclude: ArrayUtil.toggle(this.state.scenarioMeasuresToInclude, measure)
    });
  }
}
