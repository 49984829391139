import './calculations-table.scss';

import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { CalculationRow } from '../CalculationRow';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculationsTableProps {
  analysisDataFile: AnalysisDataFile;
}

@observer
@autobind
export class CalculationsTable extends React.Component<ICalculationsTableProps> {
  render() {
    const { analysisDataFile } = this.props;
    // Display the calculations in reverse order to show the newest at the top
    const calculations = [...analysisDataFile.calculations].reverse();
    const mostRecentCalculation = calculations[0];

    return (
      <TutorialStep
        stepComponent={TutorialSteps.CalculationTable}
        tutorialMessage={this.renderTutorialMessage()}
        isInteractive={false}
        showContinueButton
      >
        <div>
          <HTMLTable className='calculations-table' striped bordered condensed>
            <thead>
              <tr>
                <th>Results</th>
                <th>Name</th>
                <th>Measures</th>
                <th>Filters</th>
                <th>Stratifications</th>
                <th>Scenario</th>
              </tr>
            </thead>
            <tbody>
              {calculations.map((calculation) => (
                <CalculationRow
                  key={calculation.name}
                  calculation={calculation}
                  onClick={() => this.onCalculationClick(calculation.name)}
                  isMostRecent={calculation === mostRecentCalculation}
                />
              ))}
            </tbody>
          </HTMLTable>
        </div>
      </TutorialStep>
    );
  }

  private onCalculationClick(calculationName: string) {
    this.props.analysisDataFile!.setCalculationIsSelectedByName(calculationName);
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          This is the <strong>Calculation Table</strong>.
        </div>
        <div>All of the calculations for the selected file are displayed here.</div>
      </div>
    );
  }
}
