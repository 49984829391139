export interface IColumn {
  /**
   * @description Column label. Used within app to describe column. Can contain spaces
   * and special characters
   * Ex: Sample size
   */
  label: string;
  /**
   * @description Column name. Used in output files. Should not contain spaces or
   * special characters
   * Ex: SampleSize
   */
  name: string;
}

interface IColumnMap {
  [name: string]: IColumn;
}

export const outputColumns: IColumnMap = {
  errors: {
    label: 'Errors',
    name: 'Errors'
  },
  warnings: {
    label: 'Warnings',
    name: 'Warnings'
  },
  sampleSize: {
    label: 'Sample size',
    name: 'SampleSize'
  },
  population: {
    label: 'Weighted population',
    name: 'WeightedPopulation'
  },
  appVersion: {
    label: 'App version',
    name: 'AppVersion'
  },
  engineVersion: {
    label: 'Engine version',
    name: 'EngineVersion'
  },
  algorithmName: {
    label: 'Algorithm name',
    name: 'AlgorithmName'
  },
  algorithmVersion: {
    label: 'Algorithm version',
    name: 'AlgorithmVersion'
  }
};
