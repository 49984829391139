import './log-tab.scss';

import React from 'react';
import { HTMLTable, Intent, Icon } from '@blueprintjs/core';

import { observer } from 'mobx-react';
import { WorkerUtil } from '../../../store/domain/utils/worker-util';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { ErrorCode } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/error-code';
import { autobind } from 'core-decorators';
import { CalculationError } from '../../../store/domain/calculation-errors';

export interface ILogTabProps {
  analysisDataFile: AnalysisDataFile;
}

type VariableCount = [string, number][];

const readableErrorCode: { [code in ErrorCode]: string } = {
  [ErrorCode.GreaterThan]: 'Too large',
  [ErrorCode.GreaterThanOrEqualTo]: 'Too large',
  [ErrorCode.LessThan]: 'Too small',
  [ErrorCode.LessThanOrEqualTo]: 'Too small',
  [ErrorCode.NoDatumFound]: 'No value',
  [ErrorCode.InvalidCategory]: 'Invalid category',
  [ErrorCode.NotANumber]: 'Not a number'
};

const allErrorCodes: ErrorCode[] = [
  ErrorCode.InvalidCategory,
  ErrorCode.NoDatumFound,
  ErrorCode.LessThan,
  ErrorCode.GreaterThan,
  ErrorCode.NotANumber
];

@observer
@autobind
export class LogTab extends React.Component<ILogTabProps> {
  render() {
    const calculation = this.props.analysisDataFile.selectedCalculation!;

    return (
      <div>
        <HTMLTable bordered condensed striped>
          <thead>
            <tr>
              <th>Type</th>
              <th>Variable</th>
              <th>Label</th>
              <th>Missing</th>
              {allErrorCodes.map((errorCode) => (
                <th key={errorCode}>{readableErrorCode[errorCode]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.renderVariableIssues(calculation.sortedErrorsByCountPerVariable)}
            {this.renderVariableIssues(calculation.sortedWarningsByCountPerVariable)}
          </tbody>
        </HTMLTable>
        <ul>
          <li>Rows with errors aren't calculated</li>
          <li>
            Invalid and missing values are assigned to the population mean. See documentation for
            further discussion about how missing data affects calculations
          </li>
        </ul>
      </div>
    );
  }

  private renderVariableIssues(errors: VariableCount) {
    const { analysisDataFile } = this.props;
    const calculation = analysisDataFile.selectedCalculation!;
    const filteredRowCount = analysisDataFile.selectedCalculation!.values.length;

    return errors.map(([variable, count]) => {
      const isRequiredVariable = analysisDataFile.isRequiredVariableByName(variable);
      const icon = isRequiredVariable ? 'error' : 'warning-sign';
      const intent = isRequiredVariable ? Intent.DANGER : Intent.WARNING;
      const errorsOrWarnings = isRequiredVariable ? calculation.errors : calculation.warnings;
      const variableLabel = analysisDataFile.getVariableLabels(variable);

      return (
        <tr key={variable}>
          <td>
            <Icon icon={icon} intent={intent} />
          </td>
          <td>{variable}</td>
          <td>{variableLabel.label}</td>
          <td>{WorkerUtil.toPrecision((count / filteredRowCount) * 100)}%</td>
          {allErrorCodes.map((errorCode) => (
            <td key={errorCode}>
              {this.getErrorCodeCountForVariable(errorsOrWarnings, variable, errorCode)}
            </td>
          ))}
        </tr>
      );
    });
  }

  private getErrorCodeCountForVariable(
    errors: CalculationError[],
    variable: string,
    errorCode: ErrorCode
  ) {
    return errors.reduce((previousValue, calculationError) => {
      if (calculationError.variable === variable) {
        if (calculationError.codes.includes(errorCode)) return previousValue + 1;
      }
      return previousValue;
    }, 0);
  }
}
