import './intervention-scenario.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { HTMLTable } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { ScenarioMethods } from '@ottawamhealth/pbl-calculator-engine/lib/scenario';
import { Store } from '../../../store/store';
import { Intervention } from '../../../store/domain/intervention';
import { ScenarioTypes } from '../../../store/domain/scenario';
import { TutorialSteps } from '../../../store/domain/tutorial-step';
import { TutorialStep } from '../../dumb/Tutorial/TutorialStep';
import { InterventionTypeSelect } from '../../dumb/InterventionTypeSelect';
import { InterventionAmountInput } from '../../dumb/InterventionAmountInput';

export interface IInterventionScenarioProps {
  analysisDataFile: AnalysisDataFile;
  interventions?: Intervention[];
}

const recognizedScenarioMethods: ScenarioMethods[] = [
  ScenarioMethods.Absolute,
  ScenarioMethods.Target,
  ScenarioMethods.Relative
];

@inject((store: Store) => {
  return {
    interventions: store.interventions
  };
})
@observer
@autobind
export class InterventionScenario extends React.Component<IInterventionScenarioProps> {
  render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation: calculation } = analysisDataFile;

    const matchingInterventions = this.props.interventions!.filter(intervention =>
      intervention.scenarios.some(scenario => recognizedScenarioMethods.includes(scenario.method))
    );

    return (
      <TutorialStep
        stepComponent={TutorialSteps.InterventionScenario}
        isTutorialStepComplete={() => true}
        showContinueButton={false}
      >
        <HTMLTable bordered condensed striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {matchingInterventions.map(intervention => {
              const matchingScenarioIndex = this.getSelectedScenarioIndexForIntervention(
                intervention
              );
              const defaultValue =
                matchingScenarioIndex < 0
                  ? ScenarioTypes.None
                  : calculation.scenarios[matchingScenarioIndex].method;

              return (
                <tr key={intervention.subject}>
                  <td>{intervention.subject}</td>
                  <td>
                    <InterventionTypeSelect
                      defaultValue={defaultValue}
                      calculation={calculation}
                      intervention={intervention}
                    />
                  </td>
                  <td>
                    <InterventionAmountInput
                      calculation={calculation}
                      intervention={intervention}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </TutorialStep>
    );
  }

  private getSelectedScenarioIndexForIntervention(intervention: Intervention) {
    return this.props.analysisDataFile.activeCalculation.scenarios.findIndex(scenario =>
      intervention.scenarios.includes(scenario.originalScenario)
    );
  }
}
