import './download-sample-files-button.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { isProduction } from '@ottawamhealth/pbl-utils/lib/env';

const sampleDataDirectory = isProduction() ? '/dist/sample-data/' : '/sample-data/';

export interface IDownloadSampleFilesButtonProps {
  analysisDataFiles: AnalysisDataFile[];
}

@observer
@autobind
export class DownloadSampleFilesButton extends React.Component<IDownloadSampleFilesButtonProps> {
  render() {
    return (
      <div>
        <Popover
          popoverClassName='standard-popover'
          portalContainer={document.body}
          content={this.renderDownloadMenu()}
          minimal
          position={Position.BOTTOM_LEFT}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          <Button icon='download' text='Download sample files...' small />
        </Popover>
      </div>
    );
  }

  private renderDownloadMenu() {
    return (
      <Menu>
        {this.props.analysisDataFiles.map(analysisDataFile => (
          <MenuItem
            key={analysisDataFile.name}
            text={analysisDataFile.name}
            icon='download'
            href={sampleDataDirectory + analysisDataFile.name}
            download
          />
        ))}
      </Menu>
    );
  }
}
