import './calculation-copy.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { Store } from '../../../store/store';
import { AnalysisData } from '../../../store/domain/analysis-data';
import { Calculation } from '../../../store/domain/calculation';
import { Tutorial } from '../../../store/domain/tutorial';

const CalculationSelect = Select.ofType<Calculation>();

export interface ICalculationCopyProps {
  analysisData?: AnalysisData;
  onItemSelect: (calculation: Calculation) => void;
  tutorial?: Tutorial;
}

interface ICalculationCopyState {
  activeCalculation: Calculation | null;
}

@inject((store: Store) => {
  return {
    analysisData: store.analysisData,
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class CalculationCopy extends React.Component<ICalculationCopyProps, ICalculationCopyState> {
  constructor(props: ICalculationCopyProps) {
    super(props);

    this.state = {
      activeCalculation: null
    };
  }

  render() {
    const calculations: Calculation[] = [];

    this.props.analysisData!.analysisDataFiles.forEach((analysisDataFile) =>
      calculations.push(...analysisDataFile.calculations)
    );

    return (
      <CalculationSelect
        items={calculations}
        filterable={false}
        itemRenderer={this.renderItem}
        onItemSelect={this.onItemSelect}
        activeItem={this.state.activeCalculation}
        onActiveItemChange={this.onActiveItemChange}
        noResults='There are no calculations to copy.'
        popoverProps={{
          targetClassName: 'calculation-select',
          portalContainer: document.body,
          minimal: true,
          popoverClassName: 'standard-select'
        }}
      >
        <Button icon='duplicate' text={this.props.tutorial!.copyCalculationButtonText} />
      </CalculationSelect>
    );
  }

  private renderItem(calculation: Calculation) {
    const analysisDataFile = this.props.analysisData!.analysisDataFiles.find((analysisDataFile) =>
      analysisDataFile.calculations.includes(calculation)
    )!;

    return (
      <MenuItem
        key={calculation.name}
        text={calculation.name}
        label={analysisDataFile.name}
        active={calculation === this.state.activeCalculation}
        onClick={() => this.onItemSelect(calculation)}
      />
    );
  }

  private onItemSelect(calculation: Calculation) {
    this.props.onItemSelect(calculation);
  }

  private onActiveItemChange(calculation: Calculation | null) {
    this.setState({
      activeCalculation: calculation
    });
  }
}
