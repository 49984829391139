import { observable, computed } from 'mobx';
import { CalculationState } from './calculation-state';
import { Measures } from './measures';
import { autobind } from 'core-decorators';
import { CalculationValue } from './calculation-value';
import { CalculationError } from './calculation-errors';
import { CalculationWarning } from './calculation-warnings';
import { ErrorCode } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/error-code';
import { Filter } from './filter';
import { Strata } from './stratum';
import { IModelJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json';
import { algorithms } from './algorithms';
import { WorkerUtil } from './utils/worker-util';
import { Scenario, ScenarioTypes } from './scenario';
import { IScenario } from '@ottawamhealth/pbl-calculator-engine/lib/scenario/scenario';
import { c } from './c';
import { NotificationUtil } from './utils/notification-util';
import { store } from '../store';
import { ICoxSurvivalAlgorithmJson } from '@ottawamhealth/pbl-calculator-engine/lib/parsers/json/json-cox-survival-algorithm';
import { SummaryCalculationValue } from './summary-calculation-value';

const minimumSuggestedSampleSize = 1000;

@autobind
export class Calculation {
  @observable
  state: CalculationState = CalculationState.NotStarted;
  @observable
  name: string = '';
  @observable
  description: string = '';
  @observable.shallow
  model: IModelJson<ICoxSurvivalAlgorithmJson> = algorithms.modelJsons[0];
  @observable
  measures: Measures[] = [];
  @observable
  values: CalculationValue[] = [];
  @observable
  summaryValues!: SummaryCalculationValue;
  @observable
  isSelected: boolean = false;
  @observable
  progress: number = 0;
  @observable
  population: number = 0;
  @observable
  stratifications: string[] = [];
  @observable
  filters: Filter[] = [];
  @observable
  strata: Strata = [];
  @observable
  scenarios: Scenario[] = [];
  @observable
  scenarioType: ScenarioTypes = ScenarioTypes.None;
  @observable
  filteredOutRowCount: number = 0;
  @observable
  worker: Worker | null = null;
  @observable
  error: any;

  @observable
  areFiltersEnabled: boolean = false;
  @observable
  areStratificationsEnabled: boolean = false;
  @observable
  isScenarioEnabled: boolean = false;

  removeStratification(variableName: string) {
    this.stratifications.splice(this.stratifications.indexOf(variableName), 1);
  }

  removeFilter(filter: Filter) {
    this.filters.splice(this.filters.indexOf(filter), 1);
  }

  addMeasure(measure: Measures) {
    if (this.measures.indexOf(measure) === -1) {
      this.measures.push(measure);
    }
  }

  removeMeasure(measure: Measures) {
    this.measures = this.measures.filter((currentMeasure) => currentMeasure !== measure);
  }

  getByRowMean(measure: Measures) {
    if (this.values.length === 0) return 0;

    const result =
      this.values.reduce(
        (previousValue, currentValue) => previousValue + currentValue.measureValues[measure],
        0
      ) / this.values.length;

    return WorkerUtil.toPrecision(result);
  }

  completeCalculatingMeasures(
    summaryValues: SummaryCalculationValue,
    population: number,
    filteredOutRowCount: number
  ) {
    this.summaryValues = summaryValues;
    this.population = population;
    this.filteredOutRowCount = filteredOutRowCount;
    this.complete();
  }

  completeStratifying(strata: Strata) {
    this.strata = strata;
    this.complete();
  }

  getSummaryMeasureValue(measure: Measures) {
    return this.summaryValues.measureValues[measure];
  }

  getScenarioMeasureValue(measure: Measures) {
    return this.summaryValues.scenarioValues[measure];
  }

  getMeasureMean(measure: Measures) {
    if (this.valuesWithoutErrors.length === 0) return 0;

    const result =
      this.valuesWithoutErrors.reduce(
        (previousValue, currentValue) => previousValue + currentValue.measureValues[measure],
        0
      ) / this.valuesWithoutErrors.length;

    return WorkerUtil.toPrecision(result);
  }

  setIsProcessing() {
    this.state = CalculationState.Processing;
    this.error = null;
  }

  hasScenario(scenario: IScenario) {
    return this.scenarios.some(
      (calculationScenario) => calculationScenario.originalScenario === scenario
    );
  }

  cancel() {
    this.state = CalculationState.Cancelled;
    this.worker!.terminate();
    this.worker = null;
    if (this.error) console.error('Calculation processing error:\n', this.error);
  }

  private complete() {
    this.state = CalculationState.Finished;
    this.worker = null;

    NotificationUtil.notifyIfGranted(c.notifications.calculationComplete, this.name);
  }

  private sortIssuesByCount(issues: CalculationError[]) {
    const resultMap: Map<ErrorCode, number> = new Map();

    issues.forEach((issue) =>
      issue.codes.forEach((code) => {
        if (resultMap.has(code)) resultMap.set(code, resultMap.get(code)! + 1);
        else resultMap.set(code, 1);
      })
    );

    return this.sortMapByValues(resultMap);
  }

  private sortIssuesByCountAndVariables(issues: CalculationError[]) {
    const resultMap: Map<string, number> = new Map();

    issues.forEach((error) => {
      if (resultMap.has(error.variable))
        resultMap.set(error.variable, resultMap.get(error.variable)! + 1);
      else resultMap.set(error.variable, 1);
    });

    return this.sortMapByValues(resultMap);
  }

  private sortMapByValues(map: Map<any, number>) {
    return [...map].sort(([_aKey, aValue], [_bKey, bValue]) => bValue - aValue);
  }

  @computed
  get sampleSize() {
    if (this.isStratified) {
      return this.strata.reduce(
        (previousValue, currentValue) => previousValue + currentValue.sampleSize,
        0
      );
    }
    return this.valuesWithoutErrors.length;
  }

  @computed
  get errors() {
    const errors: CalculationError[] = [];
    this.values.forEach((value) => {
      if (value.errors) errors.push(...value.errors);
    });
    return errors;
  }

  @computed
  get warnings() {
    const warnings: CalculationWarning[] = [];
    this.values.forEach((value) => {
      if (value.warnings) warnings.push(...value.warnings);
    });
    return warnings;
  }

  @computed
  get valuesWithoutErrors() {
    return this.values.filter((value) => !value.errors);
  }

  @computed
  get activeFilters() {
    return this.filters.filter((filter) => !filter.errorMessage && filter.variableName);
  }

  @computed
  get isStratified() {
    return this.stratifications.length > 0;
  }

  @computed
  get isProcessing() {
    return this.state === CalculationState.Processing;
  }

  @computed
  get isFinished() {
    return this.state === CalculationState.Finished;
  }

  @computed
  get hasSummaryMeasures() {
    return store.algorithmMeasure.SummaryMeasures.some((measure) =>
      this.measures.includes(measure)
    );
  }

  @computed
  get hasByRowMeasures() {
    return store.algorithmMeasure.ByRowMeasures.some((measure) => this.measures.includes(measure));
  }

  @computed
  get hasSmallSampleSize() {
    return this.smallestSampleSize < minimumSuggestedSampleSize;
  }

  @computed
  get hasEmptySampleSize() {
    return this.smallestSampleSize === 0;
  }

  @computed
  get smallestSampleSize() {
    if (this.isStratified) {
      let smallestSampleSize = 0;

      if (this.strata.length > 0) {
        const stratumWithSmallestSampleSize = this.strata.reduce((previousValue, currentValue) =>
          currentValue.sampleSize < previousValue.sampleSize ? currentValue : previousValue
        );

        return stratumWithSmallestSampleSize.sampleSize;
      }

      return smallestSampleSize;
    }

    return this.valuesWithoutErrors.length;
  }

  @computed
  get sortedErrorsByCount() {
    return this.sortIssuesByCount(this.errors);
  }

  @computed
  get sortedWarningsByCount() {
    return this.sortIssuesByCount(this.warnings);
  }

  @computed
  get sortedErrorsByCountPerVariable() {
    return this.sortIssuesByCountAndVariables(this.errors);
  }

  @computed
  get sortedWarningsByCountPerVariable() {
    return this.sortIssuesByCountAndVariables(this.warnings);
  }

  @computed
  get hasIssues() {
    return this.errors.concat(this.warnings).length > 0;
  }

  @computed
  get activeScenarios() {
    switch (this.scenarioType) {
      case ScenarioTypes.None:
        return [];

      case ScenarioTypes.Intervention:
        return this.scenarios.filter((scenario) => scenario.isIntervention);

      case ScenarioTypes.HealthBehaviour:
        return this.scenarios.filter((scenario) => scenario.isHealthBehaviourAttribution);
    }
  }

  @computed
  get activeMeasuresUsableForScenarios() {
    return this.activeMeasures.filter((measure) => c.usableMeasuresForScenarios.includes(measure));
  }

  @computed
  get canRunScenarios() {
    return this.activeMeasures.some((measure) => c.usableMeasuresForScenarios.includes(measure));
  }

  @computed
  get activeMeasures() {
    if (this.isStratified) {
      return this.measures.filter((measure) =>
        store.algorithmMeasure.SummaryMeasures.includes(measure)
      );
    }
    return this.measures;
  }

  @computed
  get activeScenariosWithDescriptions() {
    return this.activeScenarios.filter((scenario) => scenario.description);
  }

  @computed
  get isCancelled() {
    return this.state === CalculationState.Cancelled;
  }
}
