import './intervention-type-select.scss';

import React, { ChangeEvent } from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { ScenarioMethods } from '@ottawamhealth/pbl-calculator-engine/lib/scenario';
import { Intervention } from '../../../store/domain/intervention';
import { Scenario, ScenarioTypes } from '../../../store/domain/scenario';
import { Calculation } from '../../../store/domain/calculation';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Tutorial } from '../../../store/domain/tutorial';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IInterventionScenarioProps {
  calculation: Calculation;
  defaultValue: string;
  intervention: Intervention;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class InterventionTypeSelect extends React.Component<IInterventionScenarioProps> {
  render() {
    const { intervention, calculation, defaultValue } = this.props;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.InterventionTypeSelection}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={false}
        isTutorialStepComplete={this.isTutorialStepComplete}
      >
        <select
          onChange={e => this.onScenarioChange(e, intervention)}
          disabled={!calculation.canRunScenarios}
          defaultValue={defaultValue}
        >
          <option value={ScenarioTypes.None} label={ScenarioTypes.None} />
          {intervention.scenarios
            .filter(scenario => scenario.method !== ScenarioMethods.Attribution)
            .map(scenario => (
              <option key={scenario.method} value={scenario.method} label={scenario.method} />
            ))}
        </select>
      </TutorialStep>
    );
  }

  /**
   * @description When selecting a new scenario for an intervention, remove the previously
   * selected scenario from the calculation. If the new scenario type is not None, then add
   * the new scenario to the calculation
   * @param e Event
   * @param intervention Intervention
   */
  private onScenarioChange(e: ChangeEvent<HTMLSelectElement>, intervention: Intervention) {
    const { calculation } = this.props;
    const selectedScenarioType = e.currentTarget.value as ScenarioMethods | ScenarioTypes.None;

    const matchingScenarioIndex = this.getSelectedScenarioIndexForIntervention(intervention);
    if (matchingScenarioIndex >= 0) calculation.scenarios.splice(matchingScenarioIndex, 1);

    if (selectedScenarioType !== ScenarioTypes.None) {
      const matchingScenario = intervention.scenarios.find(
        scenario => scenario.method === selectedScenarioType
      )!;
      calculation.scenarios.push(new Scenario(matchingScenario));
    }
  }

  private getSelectedScenarioIndexForIntervention(intervention: Intervention) {
    return this.props.calculation.scenarios.findIndex(scenario =>
      intervention.scenarios.includes(scenario.originalScenario)
    );
  }

  private isTutorialStepComplete() {
    const { calculation } = this.props;

    return (
      calculation.scenarios.length === 1 &&
      calculation.scenarios[0].method === this.props.tutorial!.scenarioMethod
    );
  }

  private renderTutorialMessage() {
    const tutorial = this.props.tutorial!;

    return (
      <div>
        <div>
          Each intervention has a name, which describes which group of variables will be modified by
          that intervention.
        </div>
        <div>
          There are three types of intervention scenarios: <strong>Target</strong>,{' '}
          <strong>Absolute</strong>, and <strong>Relative</strong>.
        </div>
        <br />
        <div>
          <i>Absolute</i>: each individual in the population changes their health behaviour by a
          value of x.
        </div>
        <div>
          <i>Relative</i>: each individual in the population changes their health behaviour by a
          ratio of y.
        </div>
        <div>
          <i>Target</i>: each individual in the population has a set value of z.
        </div>
        <br />
        <div>
          Select the <i>{tutorial.scenarioMethod}</i> type for the <i>{tutorial.scenarioName}</i>{' '}
          intervention.
        </div>
      </div>
    );
  }
}
