import { Data } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data';
import { Strata } from '../../store/domain/stratum';
import { Measures } from '../../store/domain/measures';

export interface LongTableInitialMessage {
    file: File | Data[];
    strata: Strata;
    measures: Measures[];
    stratifications: string[];
}

export enum LongTableMessageEventType {
    ProgressUpdate,
    Done,
}

export interface LongTableDoneMessage {
    type: LongTableMessageEventType.Done;
}

export interface LongTableProgressUpdateMessage {
    type: LongTableMessageEventType.ProgressUpdate;
    row: string;
}

export type LongTableExportFilePostMessage =
    | LongTableDoneMessage
    | LongTableProgressUpdateMessage;
