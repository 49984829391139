import './calculation-selection.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { CalculationsTable } from '../CalculationsTable';
import { CalculationBuilderDialog } from '../CalculationBuilderDialog';
import { Button, Intent, H5, Card, Divider } from '@blueprintjs/core';
import { CalculationImportButton } from '../CalculationImportButton';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Tutorial } from '../../../store/domain/tutorial';
import { CalculationCopy } from '../CalculationCopy';
import { Calculation } from '../../../store/domain/calculation';
import { CloneUtil } from '../../../store/domain/utils/clone-util';
import { TutorialPosition } from '../Tutorial/TutorialStatusModal';
import { NotificationStatus } from '../../smart/NotificationStatus';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculationSelectionProps {
  analysisDataFile: AnalysisDataFile;
  tutorial?: Tutorial;
}

interface ICalculationSelectionState {
  isCalculationBuilderDialogOpen: boolean;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class CalculationSelection extends React.Component<
  ICalculationSelectionProps,
  ICalculationSelectionState
> {
  constructor(props: ICalculationSelectionProps) {
    super(props);

    this.state = {
      isCalculationBuilderDialogOpen: false
    };
  }

  render() {
    const { analysisDataFile } = this.props;
    const hasCalculations = analysisDataFile.calculations.length > 0;

    return (
      <Card>
        <H5>Calculations for: {analysisDataFile.name}</H5>
        <div className='calculation-selection-header'>
          <div className='create-calculation-button-container'>
            <TutorialStep
              stepComponent={TutorialSteps.NewCalculationButton}
              tutorialMessage={this.renderTutorialMessage()}
              showContinueButton={false}
              isTutorialStepComplete={() => this.state.isCalculationBuilderDialogOpen}
              position={TutorialPosition.TopLeft}
            >
              <Button
                icon='plus'
                text={this.props.tutorial!.newCalculationButtonText}
                onClick={this.openCalculationBuilder}
                intent={Intent.SUCCESS}
              />
            </TutorialStep>
            <Divider className='standard-vertical-rule' />
            <CalculationImportButton
              analysisDataFile={analysisDataFile}
              onLoad={this.openCalculationBuilder}
            />
            <Divider className='standard-vertical-rule' />
            <CalculationCopy onItemSelect={this.onCopyCalculationSelect} />
          </div>
          <div>
            <NotificationStatus />
          </div>
        </div>
        {hasCalculations && <CalculationsTable analysisDataFile={this.props.analysisDataFile} />}
        <CalculationBuilderDialog
          isOpen={this.state.isCalculationBuilderDialogOpen}
          analysisDataFile={analysisDataFile!}
          onClose={this.closeCalculationBuilder}
        />
      </Card>
    );
  }

  private onCopyCalculationSelect(selectedCalculation: Calculation) {
    this.props.analysisDataFile.activeCalculation = CloneUtil.cloneCalculation(selectedCalculation);
    this.openCalculationBuilder();
  }

  private openCalculationBuilder() {
    this.setState({
      isCalculationBuilderDialogOpen: true
    });
  }

  private closeCalculationBuilder() {
    this.setState({
      isCalculationBuilderDialogOpen: false
    });
  }

  private renderTutorialMessage() {
    const tutorial = this.props.tutorial!;

    return (
      <div>
        <div>Now that we have a file selected, we can create a new calculation.</div>
        <div>
          You can create a calculation manually, or you can click the{' '}
          <i>{tutorial.loadCalculationButtonText}</i> button to load a calculation from your
          computer.
        </div>
        <br />
        <div>
          Once you've created a calculation, you can click{' '}
          <i>{tutorial.copyCalculationButtonText}</i> to duplicate an existing calculation.
        </div>
        <br />
        <div>
          Click the <i>{tutorial.newCalculationButtonText}</i> button to open the{' '}
          <i>Calculation Builder</i>.
        </div>
      </div>
    );
  }
}
