import './calculation-description-textbox.scss';

import React from 'react';
import { TextArea } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Calculation } from '../../../store/domain/calculation';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculationDescriptionTextboxProps {
  calculation: Calculation;
}

@observer
@autobind
export class CalculationDescriptionTextbox extends React.Component<
  ICalculationDescriptionTextboxProps
> {
  render() {
    const { calculation } = this.props;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.CalculationDescription}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={calculation.description.trim().length > 0}
        instructions={this.renderTutorialInstructions()}
      >
        <TextArea
          className='calculation-description-input'
          placeholder='Description'
          onChange={this.onCalculationDescriptionChange}
          value={calculation.description}
          small
          fill
        />
      </TutorialStep>
    );
  }

  private onCalculationDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.props.calculation.description = e.currentTarget.value;
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          You can also add a <strong>description</strong> to your calculation.
        </div>
        <div>This is also an optional field, but helps further identify this calculation.</div>
      </div>
    );
  }

  private renderTutorialInstructions() {
    return <div>Add a description now.</div>;
  }
}
