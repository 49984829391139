import { observable, computed } from 'mobx';
import { autobind } from 'core-decorators';

import { analysisData, AnalysisData } from './domain/analysis-data';
import { appToaster, AppToaster } from './ui/app-toaster';
import { Algorithms, algorithms } from './domain/algorithms';
import { Intervention } from './domain/intervention';
import { Debugger, debugger_ } from './domain/debugger';
import { NotificationPrompt } from './domain/notification-prompt';
import { Tutorial } from './domain/tutorial';
import { Algorithm } from './domain/algorithm';
import { AlgorithmType } from './domain/algorithm-type';
import { AlgorithmMeasure } from './domain/algorithm-measure';
import { c } from './domain/c';

const algorithmAppNameMap: { [algorithm in AlgorithmType]: string } = {
  [AlgorithmType.MPoRT]: c.appName,
  [AlgorithmType.RESPECT]: `RESPECT ${c.appName}`
};

const interventions: Intervention[] = [
  require('@ottawamhealth/pbl-calculator-engine-assets/MPoRTv2-PUMF/scenarios/activity.json'),
  require('@ottawamhealth/pbl-calculator-engine-assets/MPoRTv2-PUMF/scenarios/alcohol.json'),
  require('@ottawamhealth/pbl-calculator-engine-assets/MPoRTv2-PUMF/scenarios/diet.json'),
  require('@ottawamhealth/pbl-calculator-engine-assets/MPoRTv2-PUMF/scenarios/smoking.json')
];

@autobind
export class Store {
  @observable
  appToaster: AppToaster = appToaster;
  @observable
  algorithms: Algorithms = algorithms;
  @observable
  analysisData: AnalysisData = analysisData;
  @observable
  interventions: Intervention[] = interventions;
  @observable
  debugger: Debugger = debugger_;
  @observable
  notificationPrompt: NotificationPrompt = new NotificationPrompt();
  @observable
  tutorial: Tutorial;
  @observable
  algorithm: Algorithm = new Algorithm();
  @observable
  algorithmMeasure: AlgorithmMeasure = new AlgorithmMeasure();

  /**
   * List of file names being actively built. This is used to prevent the user from clicking
   * the download button when the file is already being built and will be downloaded when complete.
   * All other files can be downloaded in parallel, even the same type from another calculation
   */
  @observable
  filesBeingDownloaded: string[] = [];

  appVersion: string = process.env.APP_VERSION as string;
  engineVersion: string = process.env.ENGINE_VERSION as string;

  constructor() {
    this.loadData();
    this.tutorial = new Tutorial(this.algorithm);
  }

  @computed
  get appName() {
    return algorithmAppNameMap[this.algorithm.type];
  }

  private async loadData() {
    await algorithms.fetchAlgorithms();
    await this.fetchSampleData();
  }

  private fetchSampleData() {
    return Promise.all(
      this.algorithm.config.sampleData.map((sampleDatum) =>
        analysisData.addNewAnalysisDataFileSampleFromUrl(`${location.origin}/${sampleDatum.url}`)
      )
    );
  }
}

export const store = new Store();
