import './variable-details-popover.scss';

import classNames from 'classnames';
import React from 'react';
import { Popover, HTMLTable, H5, H4, Button, Position } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { DataField } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/data-field';
import { autobind } from 'core-decorators';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { TutorialPosition } from '../Tutorial/TutorialStatusModal';
import { TutorialContext } from '../Tutorial/Tutorial';
import { TutorialSteps } from '../../../store/domain/tutorial-step';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';

export interface IVariableDetailsPopoverProps {
  variable: DataField;
  showVariableName?: boolean;
  onClick?: () => void;
  className?: string;
  analysisDataFile: AnalysisDataFile;
}

interface IVariableDetailsPopoverState {
  isClosed: boolean;
}

@autobind
@observer
export class VariableDetailsPopover extends React.Component<
  IVariableDetailsPopoverProps,
  IVariableDetailsPopoverState
> {
  constructor(props: IVariableDetailsPopoverProps) {
    super(props);

    this.state = {
      isClosed: false
    };
  }

  render() {
    const buttonText = this.props.showVariableName ? this.props.variable.name : '';

    return (
      <TutorialContext.Consumer>
        {({ getIsActive }) => (
          <div className='centered-content'>
            <Popover
              popoverClassName='standard-popover hover-popover'
              targetClassName={this.props.className}
              portalContainer={document.body}
              content={this.renderInfoPopoverContent()}
              canEscapeKeyClose={false}
              minimal
              position={Position.BOTTOM}
              onClose={this.onClose}
              portalClassName={classNames({
                'tutorial-is-modal': getIsActive()
              })}
              modifiers={{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'viewport'
                }
              }}
            >
              <Button icon='info-sign' small onClick={this.props.onClick} text={buttonText} />
            </Popover>
          </div>
        )}
      </TutorialContext.Consumer>
    );
  }

  private renderCategoryTable(variable: DataField) {
    const { analysisDataFile } = this.props;

    return (
      <div className='variable-table'>
        <H5>Categories</H5>
        <HTMLTable striped bordered condensed>
          <thead>
            <tr>
              <th>Value</th>
              <th>Label</th>
            </tr>
          </thead>
          <tbody>
            {variable.categories!.map((category) => (
              <tr key={category.description}>
                <td>{category.value}</td>
                <td>{analysisDataFile.getLabelForCategory(variable.name, category.value)}</td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </div>
    );
  }

  private renderRangeTable(variable: DataField) {
    return (
      <div className='variable-table'>
        <H5>Intervals</H5>
        <HTMLTable striped bordered condensed>
          <thead>
            <tr>
              <th>Description</th>
              <th>Min</th>
              <th>Max</th>
            </tr>
          </thead>
          <tbody>
            {variable.intervals!.map((interval) => {
              const lowerMargin = interval.lowerMargin ? interval.lowerMargin.margin : -Infinity;
              const higherMargin = interval.higherMargin ? interval.higherMargin.margin : Infinity;

              return (
                <tr key={interval.description}>
                  <td>{interval.description}</td>
                  <td>{lowerMargin}</td>
                  <td>{higherMargin}</td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </div>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          You've opened the <strong>Variable Popover</strong>.
        </div>
        <div>
          This area displays information about the selected variable, including its label,
          description, type, and recognized categories.
        </div>
        <br />
        <div>Click outside of the popover to close it.</div>
      </div>
    );
  }

  private renderInfoPopoverContent() {
    const { variable } = this.props;
    const type = variable.intervals ? 'Continuous' : 'Categorical';
    const variableLabel = this.props.analysisDataFile.getVariableLabels(variable.name);

    return (
      <TutorialStep
        stepComponent={TutorialSteps.VariablePopover}
        tutorialMessage={this.renderTutorialMessage()}
        position={TutorialPosition.TopLeft}
        isTutorialStepComplete={() => this.state.isClosed}
        showContinueButton={false}
      >
        <H4>{variable.name}</H4>
        Label: {variableLabel.shortLabel}
        <br />
        Description: {variableLabel.label}
        <br />
        Type: {type}
        {variable.categories && this.renderCategoryTable(variable)}
        {variable.intervals && this.renderRangeTable(variable)}
      </TutorialStep>
    );
  }

  private onClose() {
    this.setState({
      isClosed: true
    });
  }
}
