import './file-row.scss';

import classNames from 'classnames';
import React from 'react';
import { Intent, ProgressBar, Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { FileTypeSelect } from '../FileTypeSelect';
import { FileType } from '../../../store/domain/file-type';
import { autobind } from 'core-decorators';

export interface IFileRowProps {
  analysisDataFile: AnalysisDataFile;
  onClick(): void;
}

@observer
@autobind
export class FileRow extends React.Component<IFileRowProps> {
  render() {
    const { analysisDataFile } = this.props;

    let fileName = analysisDataFile.name;
    const selectElement = analysisDataFile.hasReadFileValues
      ? this.renderSelectButton()
      : this.renderAnalyzingProgressBar();

    return (
      <tr
        className={classNames({
          'bp3-intent-success bp3-callout': analysisDataFile.isSelected
        })}
      >
        <td>
          <div className='centered-content'>{selectElement}</div>
        </td>
        <td>
          <div className='centered-content'>
            <FileTypeSelect analysisDataFile={analysisDataFile} />
          </div>
        </td>
        <td>{fileName}</td>
        <td>{analysisDataFile.calculations.length}</td>
      </tr>
    );
  }

  private renderSelectButton() {
    const { analysisDataFile } = this.props;
    let intent: Intent;
    let icon: 'error' | 'warning-sign' | null;

    if (analysisDataFile.fileType === FileType.None) {
      intent = Intent.DANGER;
      icon = 'error';
    } else if (analysisDataFile.hasAllVariables) {
      intent = Intent.SUCCESS;
      icon = null;
    } else if (analysisDataFile.hasRequiredVariables) {
      intent = Intent.WARNING;
      icon = 'warning-sign';
    } else {
      intent = Intent.DANGER;
      icon = 'error';
    }

    return (
      <div>
        <Button text='Select' icon={icon} intent={intent} onClick={this.props.onClick} small />
      </div>
    );
  }

  private renderAnalyzingProgressBar() {
    return (
      <div>
        Analyzing...
        <ProgressBar
          value={this.props.analysisDataFile.dataLoadingProgress}
          intent={Intent.PRIMARY}
        />
      </div>
    );
  }
}
