import { AnalysisDataFile } from '../../store/domain/analysis-data-file';
//@ts-ignore
import ReadMportFileWorker from './read-mport-file.worker';
import { WorkerUtil } from '../../store/domain/utils/worker-util';
import {
  IReadMportFileInputMessage,
  IReadInputFilesMessage,
  ReadMportFileEventType,
  ReadMportFileRequestType
} from './read-mport-file-events';
import { RenderUtil } from '../../store/domain/utils/render-util';

export abstract class ReadMportFileHandler {
  static analyzeFile(analysisDataFile: AnalysisDataFile): Promise<void> {
    return new Promise((resolve, reject) => {
      const worker: Worker = new ReadMportFileWorker();
      const onWorkerError = WorkerUtil.onError(worker, () => {
        analysisDataFile.dataLoadingProgress = 1;
        reject();
      });
      worker.onerror = onWorkerError;

      worker.postMessage({
        fileOrUrl: analysisDataFile.fileOrUrl,
        type: ReadMportFileRequestType.GetUniqueVariableValues
      } as IReadMportFileInputMessage);

      worker.onmessage = (message: { data: IReadInputFilesMessage }) => {
        const { data } = message;

        switch (data.type) {
          case ReadMportFileEventType.Progress: {
            if (!analysisDataFile.hasLoadedVariables) {
              analysisDataFile.variables = data.headers;
              analysisDataFile.hasLoadedVariables = true;
              analysisDataFile.dataLoadingProgress = 1;

              // If the file doesn't have the minimum required fields, stop reading the file
              if (!analysisDataFile.hasRequiredVariables) {
                worker.terminate();
                return resolve();
              }
            }

            if (
              RenderUtil.shouldUpdateProgress(analysisDataFile.dataLoadingProgress, data.progress)
            ) {
              analysisDataFile.dataLoadingProgress = data.progress;
            }
            break;
          }

          case ReadMportFileEventType.Done: {
            analysisDataFile.categoricalVariableValues = data.categoricalVariableValues;
            analysisDataFile.dataLoadingProgress = 1;
            worker.terminate();
            return resolve();
          }
          default: {
            return onWorkerError(new Error(`Unknown message type: ${message.data.type}`));
          }
        }
      };
    });
  }
}
