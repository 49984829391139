import './scenario-manager.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Callout, Intent } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Collapsible } from '../Collapsible';
import { InterventionScenario } from '../../smart/InterventionScenario';
import { ScenarioTypes } from '../../../store/domain/scenario';
import { HealthBehaviourScenario } from '../../smart/HealthBehaviourScenario';
import { c } from '../../../store/domain/c';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { InterventionImportButton } from '../../smart/InterventionImportButton';
import { ScenarioDescription } from '../ScenarioDescription';
import { Store } from '../../../store/store';
import { Algorithm } from '../../../store/domain/algorithm';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IScenarioManagerProps {
  analysisDataFile: AnalysisDataFile;
  algorithm?: Algorithm;
}

const scenarioTypes = Object.values(ScenarioTypes);

@inject((store: Store) => {
  return {
    algorithm: store.algorithm
  };
})
@observer
@autobind
export class ScenarioManager extends React.Component<IScenarioManagerProps> {
  render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;
    const isDisabled = !activeCalculation.canRunScenarios;
    const shouldShowScenarioDescriptions =
      activeCalculation.activeScenariosWithDescriptions.length > 0;

    if (!this.props.algorithm!.isMport) return null;

    return (
      <Collapsible
        header='Scenarios'
        isDisabled={isDisabled}
        summaryMessage={activeCalculation.scenarioType}
        startOpen
      >
        {isDisabled && this.renderDisabledScenarioWarning()}
        <InterventionImportButton isDisabled={isDisabled} />
        <TutorialStep
          stepComponent={TutorialSteps.ScenarioTypeSelection}
          tutorialMessage={this.renderTutorialMessage()}
          showContinueButton={false}
          isTutorialStepComplete={this.isTutorialStepComplete}
        >
          {this.renderScenarioTypeOptions()}
          {this.renderScenario()}
          {shouldShowScenarioDescriptions && (
            <ScenarioDescription
              analysisDataFile={analysisDataFile}
              calculation={activeCalculation}
            />
          )}
        </TutorialStep>
      </Collapsible>
    );
  }

  private renderScenarioTypeOptions() {
    const { activeCalculation } = this.props.analysisDataFile;

    return (
      <div className='scenario-selection-container'>
        <div>Scenario type:</div>
        <select
          className='scenario-selection'
          onChange={this.setScenarioType}
          disabled={!activeCalculation.canRunScenarios}
          value={activeCalculation.scenarioType}
        >
          {scenarioTypes.map(scenarioType => (
            <option key={scenarioType} label={scenarioType} value={scenarioType} />
          ))}
        </select>
      </div>
    );
  }

  private renderDisabledScenarioWarning() {
    return (
      <Callout intent={Intent.WARNING}>
        At least one of these measures must be selected to enable Scenarios:
        {c.usableMeasuresForScenarios.map(measure => (
          <div key={measure}>{measure}</div>
        ))}
      </Callout>
    );
  }

  private renderScenario() {
    const { activeCalculation } = this.props.analysisDataFile;

    switch (activeCalculation.scenarioType) {
      case ScenarioTypes.HealthBehaviour:
        return <HealthBehaviourScenario calculation={activeCalculation} />;

      case ScenarioTypes.Intervention:
        return <InterventionScenario analysisDataFile={this.props.analysisDataFile} />;

      case ScenarioTypes.None:
        return;
    }
  }

  private setScenarioType(e: React.ChangeEvent<HTMLSelectElement>) {
    this.props.analysisDataFile.activeCalculation.scenarioType = e.currentTarget
      .value as ScenarioTypes;
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          <strong>Scenarios</strong> allow you to ask what-if questions about the population, and
          see how that affects the outcome(s).
        </div>
        <div>For example, what if people exercised more, and ate more vegetables?</div>
        <br />
        <div>
          <i>None</i> disables scenarios.
        </div>
        <div>
          <i>Intervention</i> uses pre-built interventions.
        </div>
        <div>
          <i>Health behaviour attribution</i> for theoretical scenarios like what if the population
          never smoked.
        </div>
        <br />
        <div>
          Click the <i>Intervention</i> button to show the Intervention scenarios.
        </div>
      </div>
    );
  }

  private isTutorialStepComplete() {
    return (
      this.props.analysisDataFile.activeCalculation.scenarioType === ScenarioTypes.Intervention
    );
  }
}
