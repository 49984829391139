import './calculation-toggles.scss';

import React from 'react';
import { Switch } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { Store } from '../../../store/store';
import { Algorithm } from '../../../store/domain/algorithm';
import { computed } from 'mobx';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculationTogglesProps {
  analysisDataFile: AnalysisDataFile;
  algorithm?: Algorithm;
}

@inject((store: Store) => {
  return {
    algorithm: store.algorithm
  };
})
@observer
@autobind
export class CalculationToggles extends React.Component<ICalculationTogglesProps> {
  render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.CalculationToggles}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={false}
        isTutorialStepComplete={this.isTutorialStepComplete}
      >
        <div className='calculation-toggles'>
          <Switch
            checked={activeCalculation.areFiltersEnabled}
            label='Filters'
            onChange={() =>
              (activeCalculation.areFiltersEnabled = !activeCalculation.areFiltersEnabled)
            }
          />
          {this.shouldShowStratifications && (
            <Switch
              checked={activeCalculation.areStratificationsEnabled}
              label='Stratifications'
              onChange={() =>
                (activeCalculation.areStratificationsEnabled = !activeCalculation.areStratificationsEnabled)
              }
            />
          )}
          {this.shouldShowScenarios && (
            <Switch
              checked={activeCalculation.isScenarioEnabled}
              label='Scenario'
              onChange={() =>
                (activeCalculation.isScenarioEnabled = !activeCalculation.isScenarioEnabled)
              }
              disabled
            />
          )}
        </div>
      </TutorialStep>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          This menu also has toggles for <i>Filters</i>, <i>Stratifications</i>, and{' '}
          <i>Scenarios</i>.
        </div>
        <br />
        <div>
          <i>Filters</i> are used to select a subset of the data for calculations.
        </div>
        {this.shouldShowStratifications && (
          <div>
            <i>Stratifications</i> are used to stratify results.
          </div>
        )}
        {this.shouldShowScenarios && (
          <div>
            <i>Scenarios</i> allow you to modify the data in meaningful ways, and see how those
            changes affect the outcomes.
          </div>
        )}
        <br />
        <div>Click each of the toggles to enable them all.</div>
      </div>
    );
  }

  private isTutorialStepComplete() {
    const {
      areFiltersEnabled,
      areStratificationsEnabled,
      isScenarioEnabled
    } = this.props.analysisDataFile.activeCalculation;
    return areFiltersEnabled && areStratificationsEnabled && isScenarioEnabled;
  }

  @computed
  private get shouldShowStratifications() {
    return this.props.algorithm!.isMport;
  }

  @computed
  private get shouldShowScenarios() {
    return this.props.algorithm!.isMport;
  }
}
