import './calculation-builder-dialog.scss';

import classNames from 'classnames';
import React from 'react';
import { Dialog, Classes } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { CalculateButton } from '../CalculateButton';
import { CalculationBuilder } from '../CalculationBuilder/CalculationBuilder';
import { CalculateButtonCallout } from '../CalculateButtonCallout';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { TutorialContext } from '../Tutorial/Tutorial';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculationBuilderDialogProps {
  isOpen: boolean;
  analysisDataFile: AnalysisDataFile;
  onClose: () => void;
}

@autobind
@observer
export class CalculationBuilderDialog extends React.Component<ICalculationBuilderDialogProps> {
  render() {
    const { analysisDataFile } = this.props;
    const title = `New calculation for ${analysisDataFile.name}`;

    return (
      <TutorialContext.Consumer>
        {({ getIsActive }) => (
          <Dialog
            className='standard-dialog calculation-builder-dialog'
            isOpen={this.props.isOpen}
            title={title}
            onClose={this.props.onClose}
            portalContainer={document.body}
            canOutsideClickClose={false}
            lazy
            portalClassName={classNames({
              'tutorial-is-modal': getIsActive()
            })}
          >
            <TutorialStep
              stepComponent={TutorialSteps.CalculationBuilder}
              tutorialMessage={this.renderTutorialMessage()}
              isInteractive={false}
              showContinueButton
            >
              <div className='standard-dialog-content'>
                <CalculationBuilder
                  analysisDataFile={analysisDataFile}
                  isCalculationNameDuplicate={this.isCalculationNameDuplicate}
                />
              </div>
              <CalculateButtonCallout
                analysisDataFile={analysisDataFile}
                isCalculationNameDuplicate={this.isCalculationNameDuplicate}
              />
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <div className='bottom-button-container'>
                    <CalculateButton
                      isCalculationNameDuplicate={this.isCalculationNameDuplicate}
                      analysisDataFile={analysisDataFile}
                      onClick={this.props.onClose}
                    />
                  </div>
                </div>
              </div>
            </TutorialStep>
          </Dialog>
        )}
      </TutorialContext.Consumer>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          The <strong>Calculation Builder</strong> allows you to create new calculations.
        </div>
        <div>Here, you can setup the calculations you'd like to perform on the file.</div>
      </div>
    );
  }

  private isCalculationNameDuplicate() {
    const { activeCalculation } = this.props.analysisDataFile;

    const currentCalculationName = activeCalculation.name.trim();

    return this.props.analysisDataFile.calculations.some(
      (calculation) => calculation.name === currentCalculationName
    );
  }
}
