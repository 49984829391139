import './collapsible.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { H5, Collapse, Card, Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import { c } from '../../../store/domain/c';

export interface ICollapsibleProps {
  header: string;
  summaryMessage?: string;
  isDisabled?: boolean;
  startOpen?: boolean;
  className?: string;
  onClick?: (isOpen: boolean) => void;
}

interface ICollapsibleState {
  isOpen: boolean;

  /**
   * Whether the cursor is hovering over the header of the Card. We want only the header of the
   * Card to be interactive (clickable), otherwise the entire Card will be clickable. This
   * is used to only set the Card to be interactive while the cursor is over the header
   */
  isHovering: boolean;
}

@observer
@autobind
export class Collapsible extends React.Component<ICollapsibleProps, ICollapsibleState> {
  constructor(props: ICollapsibleProps) {
    super(props);

    this.state = {
      isOpen: !!this.props.startOpen,
      isHovering: false
    };
  }

  render() {
    let summaryMessage = c.genericEmptyMessage;
    if (this.props.summaryMessage) summaryMessage = this.props.summaryMessage;
    if (this.props.isDisabled) summaryMessage = `${c.genericEmptyMessage} (disabled)`;

    return (
      <Card
        className={classNames('collapsible-card', this.props.className)}
        interactive={this.state.isHovering}
      >
        <div
          className={classNames('collapsible-header', {
            'collapsible-header-open': this.state.isOpen
          })}
          onClick={this.onCollapseButtonClick}
          onMouseEnter={() => this.setIsHovering(true)}
          onMouseLeave={() => this.setIsHovering(false)}
        >
          <div>
            <Icon icon={this.state.isOpen ? 'caret-up' : 'caret-down'} />
          </div>
          <H5>{this.props.header}:</H5>
          <p className='summary-text'>{summaryMessage}</p>
        </div>
        <Collapse isOpen={this.state.isOpen} keepChildrenMounted>
          <div className='collapsible-body'>{this.props.children}</div>
        </Collapse>
      </Card>
    );
  }

  /**
   * @description Toggle whether the user is hovering over header
   * @param isHovering Whether the user is hovering over header
   */
  private setIsHovering(isHovering: boolean) {
    this.setState({
      isHovering
    });
  }

  private onCollapseButtonClick() {
    const isOpen = !this.state.isOpen;

    this.setState({
      isOpen
    });

    if (this.props.onClick) this.props.onClick(isOpen);
  }
}
