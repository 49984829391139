import './calculation-row-results.scss';

import React from 'react';
import { Intent, Button, Spinner, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { Calculation } from '../../../store/domain/calculation';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { TutorialStep } from '../../dumb/Tutorial/TutorialStep';
import { Tutorial } from '../../../store/domain/tutorial';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';
import { c } from '../../../store/domain/c';

export interface ICalculationRowResultsProps {
  calculation: Calculation;
  onClick: () => void;
  tutorial?: Tutorial;
}

interface ICalculationRowResultsState {
  hasViewedResults: boolean;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@autobind
@observer
export class CalculationRowResults extends React.Component<
  ICalculationRowResultsProps,
  ICalculationRowResultsState
> {
  constructor(props: ICalculationRowResultsProps) {
    super(props);

    this.state = {
      hasViewedResults: false
    };
  }

  render() {
    const { calculation } = this.props;

    if (calculation.isProcessing) return this.renderProgressBar();
    else if (calculation.isCancelled) return this.renderCancelledPopover();
    return this.renderViewButton();
  }

  private renderProgressBar() {
    return (
      <div className='progress-container'>
        <Button icon='stop' small intent={Intent.DANGER} onClick={this.props.calculation.cancel} />
        <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_SMALL} />
      </div>
    );
  }

  private renderViewButton() {
    return (
      <TutorialStep
        stepComponent={TutorialSteps.SelectCalculation}
        tutorialMessage={this.renderViewButtonTutorialMessage()}
        isTutorialStepComplete={() => this.state.hasViewedResults}
        showContinueButton={false}
      >
        <Button
          text={this.props.tutorial!.calculationViewButtonText}
          intent={Intent.SUCCESS}
          onClick={this.onViewClick}
          small
        />
      </TutorialStep>
    );
  }

  private renderViewButtonTutorialMessage() {
    return (
      <div>
        <div>The calculation is complete!</div>
        <br />
        <div>
          Click the <i>{this.props.tutorial!.calculationViewButtonText}</i> button to see the
          results.
        </div>
      </div>
    );
  }

  private onViewClick() {
    this.setState({
      hasViewedResults: true
    });

    this.props.onClick();
  }

  private renderCancelledPopover() {
    const wasCancelledAutomatically = !!this.props.calculation.error;
    const content = wasCancelledAutomatically ? (
      <div>
        <div>Calculation was cancelled due to an error.</div>
        <div>
          Due to the nature of {c.theAppName} and the sensitivity of the your data, please{' '}
          <a href={`mailto:${c.contactEmail}?subject=${c.appName}: Calculation error`}>
            contact us
          </a>{' '}
          to resolve this issue.
        </div>
      </div>
    ) : (
      'Calculation was cancelled manually.'
    );

    return (
      <Popover
        popoverClassName='standard-popover'
        portalContainer={document.body}
        content={content}
        interactionKind={PopoverInteractionKind.HOVER}
        minimal
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        {wasCancelledAutomatically ? 'Error' : 'Cancelled'}
      </Popover>
    );
  }
}
