import './stratifications-chart.scss';

import React from 'react';

import { Calculation } from '../../../store/domain/calculation';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import Plot from 'react-plotly.js';
import { PlotData, Layout, LayoutAxis } from 'plotly.js';
import { Measures } from '../../../store/domain/measures';

// Distance between charts an a layout
const domainFactor = 0.08;
// x-axis text angle
const tickAngle = 30;
const graphType: PlotData['type'] = 'bar';

const colors = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf'
];

const measuresYTitleMap: { [summaryMeasure: string]: string } = {
  [Measures.DeathsFiveYears]: 'Deaths',
  [Measures.LifeExpectancySummary]: 'Years'
};

export interface IStratificationsChartProps {
  analysisDataFile: AnalysisDataFile;
  calculation: Calculation;
  selectedMeasure: Measures;
}

@observer
@autobind
export class StratificationsChart extends React.Component<IStratificationsChartProps> {
  render() {
    const strataCount = this.props.calculation.stratifications.length;

    return (
      <div>
        {strataCount === 1 && this.renderChart1()}
        {strataCount === 2 && this.renderChart2()}
        {strataCount >= 3 && this.renderChart3()}
      </div>
    );
  }

  private renderChart1() {
    const { strata } = this.props.calculation;
    const data: Partial<PlotData>[] = [];
    const layout = this.buildLayout();

    strata.forEach((stratum, stratumIndex) => {
      const { label } = stratum.stratifications[stratum.stratifications.length - 1];

      data.push({
        type: graphType,
        x: [label],
        y: [stratum.measureValues[this.props.selectedMeasure]],
        name: label,
        legendgroup: label,
        showlegend: true,
        marker: {
          color: colors[stratumIndex % (colors.length - 1)]
        }
      } as Partial<PlotData>);
    });

    return <Plot data={data} layout={layout} />;
  }

  private renderChart2() {
    const { calculation, analysisDataFile } = this.props;
    const [primaryStratification] = calculation.stratifications;

    const data: Partial<PlotData>[] = [];
    const layout = this.buildLayout();

    const primaryCategories = analysisDataFile.getFoundCategoriesForVariable(primaryStratification);

    primaryCategories.forEach((primaryCategory, primaryIndex) => {
      const primaryLabel = analysisDataFile.getLabelForCategory(
        primaryStratification,
        primaryCategory
      );
      const axisIndex = this.buildAxisIndex(primaryIndex);

      const matchingStrata = this.getMatchingStrata(primaryStratification, primaryCategory);

      matchingStrata.forEach((stratum, stratumIndex) => {
        const { label } = stratum.stratifications[stratum.stratifications.length - 1];

        data.push({
          xaxis: `x${axisIndex}`,
          type: graphType,
          x: [label],
          y: [stratum.measureValues[this.props.selectedMeasure]],
          name: label,
          legendgroup: label,
          showlegend: primaryIndex === 0,
          marker: {
            color: colors[stratumIndex % (colors.length - 1)]
          }
        } as Partial<PlotData>);
      });

      this.setXAxis(
        layout,
        axisIndex,
        primaryLabel,
        this.buildDomain(primaryIndex, primaryCategories)
      );
    });

    return <Plot data={data} layout={layout} />;
  }

  private renderChart3() {
    const { calculation, analysisDataFile } = this.props;
    const [primaryStratification, secondaryStratification] = calculation.stratifications;

    const data: Partial<PlotData>[] = [];
    const layout = this.buildLayout();

    const primaryCategories = analysisDataFile.getFoundCategoriesForVariable(primaryStratification);
    const secondaryCategories = analysisDataFile.getFoundCategoriesForVariable(
      secondaryStratification
    );

    primaryCategories.forEach((primaryCategory, primaryIndex) => {
      const primaryLabel = analysisDataFile.getLabelForCategory(
        primaryStratification,
        primaryCategory
      );
      const axisIndex = this.buildAxisIndex(primaryIndex);

      secondaryCategories.forEach((secondaryCategory, secondaryCategoryIndex) => {
        const matchingStrata = this.getMatchingStrata(
          primaryStratification,
          primaryCategory,
          secondaryStratification,
          secondaryCategory
        );
        const secondaryLabel = analysisDataFile.getLabelForCategory(
          secondaryStratification,
          secondaryCategory
        );

        data.push({
          xaxis: `x${axisIndex}`,
          type: graphType,
          x: matchingStrata.map(
            (stratum) => stratum.stratifications[stratum.stratifications.length - 1].label
          ),
          y: matchingStrata.map((stratum) => stratum.measureValues[this.props.selectedMeasure]),
          name: secondaryLabel,
          legendgroup: secondaryLabel,
          showlegend: primaryIndex === 0,
          marker: {
            color: colors[secondaryCategoryIndex % (colors.length - 1)]
          }
        } as Partial<PlotData>);
      });

      this.setXAxis(
        layout,
        axisIndex,
        primaryLabel,
        this.buildDomain(primaryIndex, primaryCategories)
      );
    });

    return <Plot data={data} layout={layout} />;
  }

  private buildLayout(): Partial<Layout> {
    const { selectedMeasure, calculation } = this.props;
    const stratificationLabels = calculation.stratifications.map((stratification) => {
      this.props.analysisDataFile.getVariableLabels(stratification).label;
    });
    let title = selectedMeasure.toString().concat(' by ');

    if (stratificationLabels.length <= 2) {
      title += stratificationLabels.join(' and ');
    } else {
      title += stratificationLabels
        .map((stratification, index) => {
          if (index < stratificationLabels.length - 1) return `${stratification}, `;
          return `and ${stratification}`;
        })
        .join('');
    }

    return {
      title,
      hovermode: 'closest',
      yaxis: {
        anchor: 'x',
        title: measuresYTitleMap[selectedMeasure]
      },
      xaxis: {
        tickangle: tickAngle
      }
    };
  }

  /**
   * @description Get strata that match stratification constraints
   * For example, get all strata where sex = Male, age = High School Graduate
   */
  private getMatchingStrata(
    primaryStratification: string,
    primaryCategory: string,
    secondaryStratification?: string,
    secondaryCategory?: string
  ) {
    return this.props.calculation.strata.filter((stratum) => {
      const primaryMatch = stratum.stratifications.some(
        (stratification) =>
          primaryStratification === stratification.variableName &&
          stratification.category === primaryCategory
      );

      if (primaryMatch) {
        if (secondaryStratification) {
          return stratum.stratifications.some(
            (stratification) =>
              secondaryStratification === stratification.variableName &&
              stratification.category === secondaryCategory
          );
        }
      }

      return primaryMatch;
    });
  }

  /**
   * @description Set x axis of existing layout
   */
  private setXAxis(layout: Partial<Layout>, axisIndex: string, title: string, domain: number[]) {
    // @ts-ignore
    layout[`xaxis${axisIndex}`] = {
      title,
      domain,
      tickangle: tickAngle,
      anchor: `y${axisIndex}`
    } as Partial<LayoutAxis>;
  }

  /**
   * @description Build axis index for layout to use the format that Plotly uses:
   * xaxis, xaxis2, ..., xaxis9, where 0 is blank and 1 is skipped
   */
  private buildAxisIndex(categoryIndex: number) {
    return categoryIndex === 0 ? '' : (categoryIndex + 1).toString();
  }

  /**
   * @description Build domain for axis. This calculates where a graph should be placed in a
   * layout based on how many graphs the layout will hold, adding a space between each graph
   * @param categoryIndex Index of current category
   * @param categories List of all categories, each being it's own graph
   */
  private buildDomain(categoryIndex: number, categories: string[]) {
    let domainStart = categoryIndex / categories.length;
    if (domainStart !== 0) domainStart += domainFactor;
    return [domainStart, (categoryIndex + 1) / categories.length];
  }
}
