import './filter-options.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { CategoricalOptions } from '../CategoricalOptions';
import { ContinuousOptions } from '../ContinuousOptions';
import { Filter, VariableType } from '../../../store/domain/filter';

export interface IFilterOptionsProps {
  analysisDataFile: AnalysisDataFile;
  filter: Filter;
}

@observer
@autobind
export class FilterOptions extends React.Component<IFilterOptionsProps> {
  public render() {
    const { filter } = this.props;

    return (
      <div className='filter-options'>
        {filter.type === VariableType.Categorical ? (
          <CategoricalOptions analysisDataFile={this.props.analysisDataFile} filter={filter} />
        ) : (
          <ContinuousOptions analysisDataFile={this.props.analysisDataFile} filter={filter} />
        )}
      </div>
    );
  }
}
