import './change-log.scss';

import React from 'react';
import { Button, Dialog, H5, Divider, H4 } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { inject } from 'mobx-react';
import { Store } from '../../../store/store';
import { changes } from './change-history';
import { Algorithm } from '../../../store/domain/algorithm';

interface IChangeLogProps {
  algorithm?: Algorithm;
}

interface IChangeLogState {
  isOpen: boolean;
}

@inject((store: Store) => {
  return {
    algorithm: store.algorithm
  };
})
@autobind
export class ChangeLog extends React.Component<IChangeLogProps, IChangeLogState> {
  constructor(props: IChangeLogProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public render() {
    return (
      <div>
        <Button text='Change log' onClick={this.onClick} icon='info-sign' />
        <Dialog
          isOpen={this.state.isOpen}
          title='Change log'
          onClose={this.onClose}
          portalContainer={document.body}
        >
          {this.renderDialogContent()}
        </Dialog>
      </div>
    );
  }

  private onClick() {
    this.setState({
      isOpen: true
    });
  }

  private renderDialogContent() {
    const algorithmChanges = changes[this.props.algorithm!.type];

    return (
      <div className='change-log-content'>
        {algorithmChanges.map((change, index) => {
          const { updates } = change;
          const shouldShowFeatures = updates.features.length > 0;
          const shouldShowFixes = updates.fixes.length > 0;

          return (
            <div className='change-log-entry' key={change.date}>
              <H4>{change.date}</H4>
              <div>App version: {change.appVersion}</div>
              <div>Engine version: {change.engineVersion}</div>
              <br />
              {shouldShowFeatures && (
                <div>
                  <H5>Features</H5>
                  <div>
                    <ul>{updates.features.map(this.renderChange)}</ul>
                  </div>
                </div>
              )}
              {shouldShowFixes && (
                <div>
                  <H5>Fixes</H5>
                  <div>
                    <ul>{updates.fixes.map(this.renderChange)}</ul>
                  </div>
                </div>
              )}
              {index < algorithmChanges.length - 1 && <Divider />}
            </div>
          );
        })}
      </div>
    );
  }

  private onClose() {
    this.setState({
      isOpen: false
    });
  }

  private renderChange(change: string) {
    return <li key={change}>{change}</li>;
  }
}
