import './file-type-select.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { MenuItem, Button, Position, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { FileType } from '../../../store/domain/file-type';
import { c } from '../../../store/domain/c';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

const FileTypeSelection = Select.ofType<FileType>();

export interface IFileTypeSelectProps {
  analysisDataFile: AnalysisDataFile;
}

interface IFileTypeSelectState {
  activeFileType: FileType;
}

@observer
@autobind
export class FileTypeSelect extends React.Component<IFileTypeSelectProps, IFileTypeSelectState> {
  constructor(props: IFileTypeSelectProps) {
    super(props);

    this.state = {
      activeFileType: FileType.None
    };
  }

  render() {
    const { analysisDataFile } = this.props;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.FileTypeSelection}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton
      >
        {/* Don't allow the file to be selected when file type is clicked */}
        <span onClick={(e) => e.stopPropagation()}>
          <FileTypeSelection
            disabled={this.props.analysisDataFile.isSampleData}
            items={c.selectableFileTypes}
            itemRenderer={this.renderItem}
            onItemSelect={this.onItemSelect}
            activeItem={this.state.activeFileType}
            onActiveItemChange={this.onActiveItemChange}
            filterable={false}
            popoverProps={{
              portalContainer: document.body,
              minimal: true,
              popoverClassName: 'standard-select',
              position: Position.BOTTOM
            }}
          >
            <Button
              small
              text={analysisDataFile.fileType}
              value={analysisDataFile.fileType}
              rightIcon='caret-down'
              disabled={analysisDataFile.isSampleData}
            />
          </FileTypeSelection>
        </span>
      </TutorialStep>
    );
  }

  private renderItem(fileType: FileType) {
    const isDisabled = !c.enabledFileTypes.includes(fileType);

    const menuItem = (
      <MenuItem
        key={fileType}
        text={fileType}
        disabled={isDisabled}
        onClick={() => this.onItemSelect(fileType)}
        active={fileType === this.state.activeFileType}
        label={c.fileTypeDescriptions[fileType]}
      />
    );

    if (isDisabled) {
      return (
        <Popover
          key={fileType}
          popoverClassName='standard-popover'
          portalContainer={document.body}
          content='File type is not currently supported'
          canEscapeKeyClose={false}
          minimal
          interactionKind={PopoverInteractionKind.HOVER}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {menuItem}
        </Popover>
      );
    }

    return menuItem;
  }

  private onItemSelect(fileType: FileType) {
    this.props.analysisDataFile.fileType = fileType;
  }

  private onActiveItemChange(fileType: FileType | null) {
    if (fileType) {
      this.setState({
        activeFileType: fileType
      });
    }
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          When you load a local file, you'll need to set the <i>file type</i>. This allows the tool
          to properly read the file.
        </div>
        <div>Sample files already have their types set, so we can ignore this step for now.</div>
      </div>
    );
  }
}
