export abstract class RenderUtil {
  /**
   * @description Limit the number of updates from a worker to once per percent. Too many updates
   * can slow the main thread
   * @param currentProgress Current progress
   * @param newProgress New progress
   */
  static shouldUpdateProgress(currentProgress: number, newProgress: number) {
    return currentProgress.toFixed(2) !== newProgress.toFixed(2);
  }

  /**
   * @description Workaround for issue where browser doesn't trigger onChange when user selects
   * same file as last select
   * @param e Event
   */
  static clearFileInput(e: React.FormEvent<HTMLInputElement>) {
    e.currentTarget.value = '';
  }
}
