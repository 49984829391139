import './file-manager.scss';

import React from 'react';
import { H5, FileInput, Button, Card, Divider, Callout, Intent } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';
import { AnalysisData } from '../../../store/domain/analysis-data';
import { autobind } from 'core-decorators';
import { FilesTable } from '../FilesTable/FilesTable';
import { DownloadSampleFilesButton } from '../DownloadSampleFilesButton';
import { Store } from '../../../store/store';
import { Algorithm } from '../../../store/domain/algorithm';
import { MPoRTInputFileCallout } from '../MPoRTInputFileCallout';
import { RESPECTInputFileCallout } from '../RESPECTInputFileCallout';

export interface IFileManagerProps {
  onFileInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  analysisData: AnalysisData;
  algorithm?: Algorithm;
}

@inject((store: Store) => {
  return {
    algorithm: store.algorithm
  };
})
@observer
@autobind
export class FileManager extends React.Component<IFileManagerProps> {
  private fileInputRef: HTMLInputElement | null = null;

  render() {
    return (
      <Card>
        <H5>Select a file to use for calculations</H5>
        <FilesTable analysisData={this.props.analysisData} />
        <div className='separated-buttons-container'>
          <Button icon='upload' text='Load file' small onClick={() => this.fileInputRef!.click()} />
          <Divider className='standard-vertical-rule' />
          <DownloadSampleFilesButton analysisDataFiles={this.props.analysisData.sampleData} />
        </div>
        <FileInput
          hidden
          onInputChange={this.props.onFileInputChange}
          inputProps={{
            accept: '.csv',
            multiple: true,
            ref: (ref) => (this.fileInputRef = ref)
          }}
        />
        {this.renderFileSelectionStatusCallout()}
      </Card>
    );
  }

  private renderFileSelectionStatusCallout() {
    const { analysisData } = this.props;

    if (analysisData.selectedAnalysisDataFile) {
      return this.props.algorithm!.isMport ? (
        <MPoRTInputFileCallout analysisData={analysisData} />
      ) : (
        <RESPECTInputFileCallout analysisData={analysisData} />
      );
    } else {
      return this.renderSelectFileCallout();
    }
  }

  private renderSelectFileCallout() {
    return (
      <Callout intent={Intent.NONE} icon='info-sign'>
        Please select a file.
      </Callout>
    );
  }
}
