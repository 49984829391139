export enum TutorialSteps {
  Inactive = -1,
  Start,
  FileManager,
  FileTypeSelection,
  OpenVariableManagerButton,
  VariableManager,
  VariablePopover,
  VariableManagerClose,
  NewCalculationButton,
  CalculationBuilder,
  CalculationName,
  CalculationDescription,
  SelectMeasure,
  CalculationOptions,
  CalculationToggles,
  FilterManager,
  SelectFilterVariable,
  SelectFilterCategories,
  SetStratification,
  ScenarioTypeSelection,
  InterventionScenario,
  InterventionTypeSelection,
  InterventionAmount,
  CalculateButton,
  CalculationTable,
  NotificationButton,
  WaitForCalculation,
  SelectCalculation,
  Result,
  Complete
}

/**
 * @description Title displayed in tutorial modal for each tutorial step
 */
export const TutorialStepTitles: { [component in TutorialSteps]: string } = {
  [TutorialSteps.Inactive]: 'Inactive',
  [TutorialSteps.Start]: 'Tutorial',
  [TutorialSteps.FileManager]: 'File Manager',
  [TutorialSteps.FileTypeSelection]: 'File type selection',
  [TutorialSteps.OpenVariableManagerButton]: 'Open Variable Manager button',
  [TutorialSteps.VariableManager]: 'Variable Manager',
  [TutorialSteps.VariablePopover]: 'Variable popover',
  [TutorialSteps.VariableManagerClose]: 'Variable Manager close',
  [TutorialSteps.NewCalculationButton]: 'New calculation',
  [TutorialSteps.CalculationBuilder]: 'Calculation Builder',
  [TutorialSteps.SelectMeasure]: 'Select measure',
  [TutorialSteps.CalculationOptions]: 'Calculation Options',
  [TutorialSteps.CalculationToggles]: 'Calculation toggles',
  [TutorialSteps.FilterManager]: 'Filter Manager',
  [TutorialSteps.SelectFilterVariable]: 'Select filter variable',
  [TutorialSteps.SelectFilterCategories]: 'Select filter categories',
  [TutorialSteps.SetStratification]: 'Set stratifications',
  [TutorialSteps.ScenarioTypeSelection]: 'Scenarios',
  [TutorialSteps.InterventionScenario]: 'Interventions',
  [TutorialSteps.InterventionTypeSelection]: 'Intervention types',
  [TutorialSteps.InterventionAmount]: 'Intervention amount',
  [TutorialSteps.CalculationName]: 'Calculation name',
  [TutorialSteps.CalculationDescription]: 'Calculation description',
  [TutorialSteps.CalculateButton]: 'Calculate',
  [TutorialSteps.CalculationTable]: 'Calculation Table',
  [TutorialSteps.NotificationButton]: 'Notifications',
  [TutorialSteps.WaitForCalculation]: 'Processing...',
  [TutorialSteps.SelectCalculation]: 'Select calculation',
  [TutorialSteps.Result]: 'Results',
  [TutorialSteps.Complete]: 'Complete'
};

/**
 * @description Tutorial steps that create new stacking contexts, specifying which steps are
 * contained within a step that creates the context.
 *
 * When a tutorial step creates a stacking context, we will move it above the main tutorial grey
 * box, and have it create it's own grey box to grey-out it's own contents. We'll then move the
 * active child above this inner grey box
 *
 * Stacking contexts: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
export const TutorialContainerChildren: { [component: string]: TutorialSteps[] } = {
  [TutorialSteps.FilterManager]: [
    TutorialSteps.SelectFilterVariable,
    TutorialSteps.SelectFilterCategories
  ],
  [TutorialSteps.CalculationBuilder]: [
    TutorialSteps.CalculationOptions,
    TutorialSteps.CalculationName,
    TutorialSteps.CalculationDescription,
    TutorialSteps.SelectMeasure,
    TutorialSteps.CalculationToggles,
    TutorialSteps.FilterManager,
    TutorialSteps.SelectFilterVariable,
    TutorialSteps.SelectFilterCategories,
    TutorialSteps.SetStratification,
    TutorialSteps.ScenarioTypeSelection,
    TutorialSteps.InterventionTypeSelection,
    TutorialSteps.InterventionAmount,
    TutorialSteps.CalculateButton
  ],
  [TutorialSteps.InterventionScenario]: [
    TutorialSteps.InterventionTypeSelection,
    TutorialSteps.InterventionAmount
  ]
};
