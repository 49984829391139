import './intervention-import-button.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Intervention } from '../../../store/domain/intervention';
import { Button, FileInput } from '@blueprintjs/core';
import { RenderUtil } from '../../../store/domain/utils/render-util';
import { InvalidImportDialog } from '../../dumb/InvalidImportDialog';
import { HelperContainer } from '../../dumb/HelperContainer';
import { c } from '../../../store/domain/c';
import { Store } from '../../../store/store';

export interface IInterventionImportButtonProps {
  isDisabled: boolean;
  interventions?: Intervention[];
}

interface IInterventionImportButtonState {
  isLoading: boolean;
  isLoadedFileInvalid: boolean;
}

@inject((store: Store) => {
  return {
    interventions: store.interventions
  };
})
@observer
@autobind
export class InterventionImportButton extends React.Component<
  IInterventionImportButtonProps,
  IInterventionImportButtonState
> {
  constructor(props: IInterventionImportButtonProps) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadedFileInvalid: false
    };
  }

  private fileInputRef: HTMLInputElement | null = null;

  render() {
    return (
      <HelperContainer content={this.renderHelperContent()} alignment='center'>
        <Button
          className='intervention-import-button'
          icon='upload'
          text='Load'
          onClick={() => this.fileInputRef!.click()}
          disabled={this.props.isDisabled}
          loading={this.state.isLoading}
        />
        <FileInput
          hidden
          onInputChange={this.onFileInputChange}
          inputProps={{
            accept: '.json',
            multiple: true,
            ref: ref => (this.fileInputRef = ref)
          }}
        />
        <InvalidImportDialog
          title='Invalid JSON'
          error='The selected file is invalid JSON. Please check the format of the file and try again.'
          isOpen={this.state.isLoadedFileInvalid}
          onClose={() => this.setIsLoadedFileInvalid(false)}
        />
      </HelperContainer>
    );
  }

  private async onFileInputChange(e: React.FormEvent<HTMLInputElement>) {
    const { files } = e.currentTarget;
    if (!files || !files.length) return;

    const file = files[0];
    RenderUtil.clearFileInput(e);

    this.setIsLoading(true);

    try {
      await this.importIntervention(file);
    } catch (e) {
      this.setIsLoadedFileInvalid(true);
    }

    this.setIsLoading(false);
  }

  private async importIntervention(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent) => {
        try {
          const loadedIntervention = JSON.parse((e.target as any).result as string);
          this.props.interventions!.unshift(loadedIntervention);
          resolve();
        } catch (e) {
          reject(e);
        }
      };

      reader.onerror = reject;
      reader.readAsText(file);
    });
  }

  private setIsLoading(isLoading: boolean) {
    this.setState({
      isLoading
    });
  }

  private setIsLoadedFileInvalid(isLoadedFileInvalid: boolean) {
    this.setState({
      isLoadedFileInvalid
    });
  }

  private renderHelperContent() {
    return (
      <div>
        <a href={c.interventionWikiUrl} target='_blank'>
          Click here
        </a>{' '}
        to learn how to build custom interventions.
      </div>
    );
  }
}
