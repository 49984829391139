import { Measures } from './measures';
import { FileType } from './file-type';

const measureToCsvHeaderMap: { [measure in Measures]: string } = {
  // MPoRT
  [Measures.FiveYearMortalityRiskByRow]: '5YearMortalityRiskByRow',
  [Measures.FiveYearMortalityRiskSummary]: '5YearMortalityRiskSummary',
  [Measures.LifeExpectancyByRow]: 'RemainingLifeExpectancyIndividual',
  [Measures.LifeExpectancySummary]: 'LifeExpectancyAtBirthPopulation',
  [Measures.DeathsFiveYears]: 'Deaths5Years',

  // RESPECT
  [Measures.TwentyFifthPercentileSurvivalTime]: '25thPercentileSurvivalTime',
  [Measures.SeventyFifthPercentileSurvivalTime]: '75thPercentileSurvivalTime',
  [Measures.SurvivalTime]: 'SurvivalTime',
  [Measures.ThreeMonthRisk]: '3MonthRisk',
  [Measures.SixMonthRisk]: '6MonthRisk',
  [Measures.OneYearRisk]: '1YearRisk'
};

const scenarioCsvHeaderPrefix = 'Scenario';

const variables = {
  weight: 'WTS_M',
  sex: 'DHH_SEX',
  age: 'DHHGAGE'
};

const mportMinAge = 4;

const fileTypeDescriptions: { [fileType in FileType]: string } = {
  [FileType.None]: '',
  [FileType.PUMF2013]: 'CCHS-82M0013-E-2013-2014-Annual',
  [FileType.Shared2013]: 'CCHS Shared'
};

const selectableFileTypes: FileType[] = [FileType.PUMF2013, FileType.Shared2013];

const enabledFileTypes: FileType[] = [FileType.PUMF2013];

const usableMeasuresForScenarios: Measures[] = [
  Measures.DeathsFiveYears,
  Measures.LifeExpectancySummary,
  Measures.FiveYearMortalityRiskSummary
];

const notifications = {
  calculationComplete: 'Calculation complete'
};

const respectIdVariables = ['LastName', 'FirstName', 'Resident_ID'];
const respectDateVariables = ['AA3a', 'A3'];
const respectRecognizedVariablesWithNumberValues = [
  'AA8',
  'AB7',
  'I1d',
  'I1u',
  'I1f',
  'I1h',
  'I1y',
  'I1aa',
  'I1v',
  'I1r',
  'I1rr',
  'I1uu',
  'I1jj',
  'I1kk',
  'P1aa',
  'P1ab',
  'P1ag',
  'P1al',
  'J1o',
  'J1g',
  'J1l',
  'J1d',
  'K3a',
  'J5c',
  'B6',
  'G9',
  'G1jA',
  'G1iA',
  'G1eA',
  'G1hA'
];

const respectRecognizedVariablesWithStringValues = ['AA2'];
const allRecognizedRespectVariables = [
  ...respectIdVariables,
  ...respectDateVariables,
  ...respectRecognizedVariablesWithNumberValues,
  ...respectRecognizedVariablesWithStringValues
];

const maxFileSizeMb = 50;

export const c = {
  contactEmail: 'projectbiglife@toh.ca',
  variables,
  mportMinAge,
  maxStratifications: 3,
  groupName: 'Project Big Life',
  appName: 'Planning Tool',
  theAppName: 'The Planning Tool',
  documentationUrl: 'https://big-life-lab.github.io/pbl-planning-tool-guide/',
  interventionWikiUrl:
    'https://github.com/Big-Life-Lab/pbl-planning-tool-documentation/tree/master/interventions',
  scenarioUserDefinedField: 'user-defined',
  scenarioValueField: 'scenarioValue',
  genericEmptyMessage: 'None',
  stringifySpaceCount: 4,
  noDescription: 'No description',
  workerPostBackPauseMs: 10,
  na: 'NA',
  downloadResultsText: 'Download results',
  maxFileSizeBytes: maxFileSizeMb * 1024 * 1024,
  maxFileSizeMb,
  measureToCsvHeaderMap,
  scenarioCsvHeaderPrefix,
  fileTypeDescriptions,
  selectableFileTypes,
  enabledFileTypes,
  usableMeasuresForScenarios,
  notifications,
  respectIdVariables,
  respectDateVariables,
  respectRecognizedVariablesWithNumberValues,
  respectRecognizedVariablesWithStringValues,
  allRecognizedRespectVariables
};
