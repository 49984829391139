import './mport-input-file-callout.scss';

import React from 'react';
import { Intent, Callout } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { AnalysisData } from '../../../store/domain/analysis-data';
import { autobind } from 'core-decorators';
import { FileType } from '../../../store/domain/file-type';
import { OpenVariableManagerButton } from '../OpenVariableManagerButton';
import { c } from '../../../store/domain/c';

export interface IMPoRTInputFileCalloutProps {
  analysisData: AnalysisData;
}

@observer
@autobind
export class MPoRTInputFileCallout extends React.Component<IMPoRTInputFileCalloutProps> {
  render() {
    const selectedAnalysisDataFile = this.props.analysisData.selectedAnalysisDataFile!;
    const shouldShowVariableManagerButton = selectedAnalysisDataFile.hasLoadedVariables;
    const warnings = this.buildWarnings();

    let intent: Intent;
    let message: string;
    let icon: 'error' | 'warning-sign' | 'tick' | 'time';

    if (selectedAnalysisDataFile.hasLoadedVariables) {
      if (selectedAnalysisDataFile.fileType === FileType.None) {
        intent = Intent.DANGER;
        icon = 'error';
        message = 'The file type must be set on this file before it can be used.';
      } else {
        if (selectedAnalysisDataFile.hasAllVariables) {
          intent = Intent.SUCCESS;
          icon = 'tick';
          message = 'The selected file has all required and recommended variables.';
        } else if (selectedAnalysisDataFile.hasRequiredVariables) {
          intent = Intent.WARNING;
          icon = 'warning-sign';
          message =
            'The selected file is missing recommended variables. Calculations may be less accurate.';
        } else {
          intent = Intent.DANGER;
          icon = 'error';
          message =
            "The selected file is missing required variables, and can't be used for calculations.";
        }
      }
    } else {
      intent = Intent.NONE;
      icon = 'time';
      message = 'Loading file...';
    }

    let warningMessage;
    const shouldShowWarnings = intent !== Intent.DANGER && warnings.length > 0;
    if (shouldShowWarnings) {
      if (intent === Intent.SUCCESS) {
        intent = Intent.WARNING;
        icon = 'warning-sign';
      }

      if (warnings.length === 1) {
        warningMessage = `Warning: ${warnings[0]}.`;
      } else {
        warningMessage = (
          <div>
            Warning:
            <ul>
              {warnings.map((warning, index) => (
                <li key={index}>{warning}</li>
              ))}
            </ul>
          </div>
        );
      }
    }

    return (
      <Callout intent={intent} icon={icon} title={selectedAnalysisDataFile.name}>
        <div>{message}</div>
        {warningMessage}
        {shouldShowVariableManagerButton && (
          <div>
            <OpenVariableManagerButton analysisDataFile={selectedAnalysisDataFile!} />
          </div>
        )}
      </Callout>
    );
  }

  private buildWarnings() {
    const selectedAnalysisDataFile = this.props.analysisData.selectedAnalysisDataFile!;
    const warnings: string[] = [];

    if (selectedAnalysisDataFile.isLargeFile)
      warnings.push(
        `This is a large file (> ${c.maxFileSizeMb}mb). Results for calculations that use this file can still be downloaded, but can't be viewed in ${c.theAppName}`
      );

    return warnings;
  }
}
