import './variable-row.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { DataField } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/data-field';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { VariableFoundPopover } from '../VariableFoundPopover/VariableFoundPopover';
import { VariableDetailsPopover } from '../VariableDetailsPopover';

export interface IVariableRowProps {
  variable: DataField;
  analysisDataFile: AnalysisDataFile;
  onDetailsClick(): void;
}

@autobind
@observer
export class VariableRow extends React.Component<IVariableRowProps> {
  render() {
    const { variable, analysisDataFile } = this.props;
    const variableLabel = analysisDataFile.getVariableLabels(variable.name);

    return (
      <tr>
        <td>
          <div className='centered-content'>
            <VariableFoundPopover
              analysisDataFile={this.props.analysisDataFile}
              variable={variable}
            />
          </div>
        </td>
        <td>{variable.name}</td>
        <td>{variableLabel.shortLabel}</td>
        <td>{variableLabel.label}</td>
        <td>
          <div className='centered-content'>
            <VariableDetailsPopover
              className='centered-content'
              variable={variable}
              onClick={this.props.onDetailsClick}
              analysisDataFile={analysisDataFile}
            />
          </div>
        </td>
      </tr>
    );
  }
}
