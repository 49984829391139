import './duplicate-files-dialog.scss';

import React from 'react';
import { Dialog, Classes, Button, Intent } from '@blueprintjs/core';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { observer, inject } from 'mobx-react';
import { Store } from '../../../store/store';
import { AnalysisData } from '../../../store/domain/analysis-data';

export interface IDuplicateFilesDialogProps {
  onCancelClick: () => void;
  onAcceptClick: () => void;
  existingFilesWithSameNames: AnalysisDataFile[];
  analysisData?: AnalysisData;
}

@inject((store: Store) => {
  return {
    analysisData: store.analysisData
  }
})
@observer
export class DuplicateFilesDialog extends React.Component<IDuplicateFilesDialogProps> {
  public render () {
    const { existingFilesWithSameNames } = this.props;

    return (
      <Dialog
        isOpen={existingFilesWithSameNames.length > 0}
        portalContainer={document.body}
        title='Files already uploaded'
        onClose={this.props.onCancelClick}
        lazy={true}
        canEscapeKeyClose={false}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>
            Files with these names have already been uploaded:
            <ul>
              {existingFilesWithSameNames.map(file =>
                <li
                  key={file.name}
                >
                  {file.name} ({this.props.analysisData!.findUserDataFileWithName(file.name)!
                    .calculations.length} calculations)
                </li>
                )
              }
            </ul>
            Do you want to replace the existing files?
            <br />
            All calculations for these files will be lost.
            A file should only be replaced if you've started calculations using the
            file, then made changes to the file, or are loading a different file with the
            same name.
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                intent={Intent.WARNING}
                text='Cancel'
                onClick={this.props.onCancelClick}
              />
              <Button
                intent={Intent.SUCCESS}
                text='Replace'
                onClick={this.props.onAcceptClick}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
