import { AlgorithmType } from '../../../store/domain/algorithm-type';

/**
 * In reverse chronological order. Add new entries at the top.
 */
interface IAppChange {
  date: string;
  appVersion: string;
  engineVersion: string;
  updates: {
    features: string[];
    fixes: string[];
  };
}

export const changes: { [algorithmType in AlgorithmType]: IAppChange[] } = {
  [AlgorithmType.MPoRT]: [
    {
      date: 'January 20, 2021',
      appVersion: '1.6.7',
      engineVersion: '1.19.4',
      updates: {
        features: [
          'Add warning when using large files',
          'Add algorithm name and version to output files'
        ],
        fixes: [
          'Fix issue where viewing results for large calculations could cause app to crash. Results for large files can no longer be viewed in the app, but can still be downloaded'
        ]
      }
    },
    {
      date: 'December 11, 2020',
      appVersion: '1.6.6',
      engineVersion: '1.19.4',
      updates: {
        features: [
          'Significantly reduce calculation time when using filters',
          'Reduce time to download output files',
          'Display error message when downloading results fails',
          'Calculation completion notifications must now be closed manually instead of closing automatically after a few seconds'
        ],
        fixes: [
          'Fix issue where app would crash for larger files',
          'Fix issue where downloading Input results would continuously display a loading icon and silently fail',
          'Fix file type selection issues',
          'Fix memory usage and calculation speed issues'
        ]
      }
    },
    {
      date: 'December 1, 2020',
      appVersion: '1.6.5',
      engineVersion: '1.19.4',
      updates: {
        features: ['Update variable labels'],
        fixes: []
      }
    },
    {
      date: 'November 18, 2020',
      appVersion: '1.6.4',
      engineVersion: '1.19.4',
      updates: {
        features: [
          'Update variable labels',
          'Simplify output download process, advanced download options still available',
          'Add name and version of algorithm to output files',
          'Improve calculation accuracy'
        ],
        fixes: []
      }
    },
    {
      date: 'October 21, 2020',
      appVersion: '1.6.3',
      engineVersion: '1.18.0',
      updates: {
        features: [
          'Users can now cancel calculations',
          "Display 'Cancelled' message for calculations that were cancelled manually",
          "Display 'Error' message for calculations that were cancelled automatically due to an error"
        ],
        fixes: ['Fix issue where calculations with errors would appear to be loading indefinitely']
      }
    },
    {
      date: 'September 30, 2020',
      appVersion: '1.6.2',
      engineVersion: '1.18.0',
      updates: {
        features: [
          'Update sample data to include GEODPMF',
          'Add more metadata for existing variables'
        ],
        fixes: []
      }
    },
    {
      date: 'September 2, 2020',
      appVersion: '1.6.1',
      engineVersion: '1.18.0',
      updates: {
        features: [
          'Display scenario descriptions to clarify scenarios in calculation builder',
          'Display units for intervention scenarios in calculation builder'
        ],
        fixes: []
      }
    },
    {
      date: 'July 8, 2020',
      appVersion: '1.6.0',
      engineVersion: '1.18.0',
      updates: {
        features: [],
        fixes: ['Fix SDCGCGT (Ethnicity) warnings in calculation results']
      }
    },
    {
      date: 'March 16, 2020',
      appVersion: '1.4.10',
      engineVersion: '1.16.2',
      updates: {
        features: [],
        fixes: ['Minor bug fixes and improvements']
      }
    },
    {
      date: 'March 5, 2020',
      appVersion: '1.4.9',
      engineVersion: '1.16.2',
      updates: {
        features: ['Add diet intervention scenario'],
        fixes: []
      }
    },
    {
      date: 'February 20, 2020',
      appVersion: '1.4.8',
      engineVersion: '1.16.2',
      updates: {
        features: [
          'Update tutorial step for clarification',
          'Add link to main Project Big Life site',
          'Add license information in footer',
          'Move feedback button to footer'
        ],
        fixes: []
      }
    },
    {
      date: 'February 13, 2020',
      appVersion: '1.4.7',
      engineVersion: '1.16.2',
      updates: {
        features: [],
        fixes: ['Fix issue where notification status could display incorrect status']
      }
    },
    {
      date: 'February 7, 2020',
      appVersion: '1.4.6',
      engineVersion: '1.16.2',
      updates: {
        features: ['Add notifications option to notify users when calculations are complete'],
        fixes: []
      }
    },
    {
      date: 'January 31, 2020',
      appVersion: '1.4.5',
      engineVersion: '1.16.2',
      updates: {
        features: ['Improve error messages when result sample size is 0'],
        fixes: [
          'Fix issue where calculations with errors would break downloading result files',
          'Fix issue where sample sizes and error count could be calculated incorrectly'
        ]
      }
    },
    {
      date: 'January 30, 2020',
      appVersion: '1.4.4',
      engineVersion: '1.16.2',
      updates: {
        features: [
          'Update calculation loading bar',
          'Disable Shared file type until fully supported',
          'Always display calculation description in calculation'
        ],
        fixes: ['Fix slowness issues when calculations were completing']
      }
    },
    {
      date: 'January 28, 2020',
      appVersion: '1.4.3',
      engineVersion: '1.16.2',
      updates: {
        features: [
          'Update tutorial steps and added new steps',
          'Update debug mode, can be enabled without restarting the app'
        ],
        fixes: [
          'Fix tutorial issues',
          "Fix calculation error issues, including issues where rows with errors weren't in output files",
          'Fix issue where Activity scenario was unusable',
          'Fix slowness issues when loading sample data'
        ]
      }
    },
    {
      date: 'January 10, 2020',
      appVersion: '1.4.2',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Add copy button to duplicate previously run calculations',
          'Add button to download sample files',
          'Add description field to calculations',
          'Update fonts',
          'Update tutorial'
        ],
        fixes: ['Fix issue where scenario results would display even if no scenario was selected']
      }
    },
    {
      date: 'November 1, 2019',
      appVersion: '1.4.1',
      engineVersion: '1.15.0',
      updates: {
        features: ['Move variable list into popout', 'Move calculation builder'],
        fixes: ['Prevent By Row measures from being selected when stratifying']
      }
    },
    {
      date: 'September 25, 2019',
      appVersion: '1.4.0',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Add stratification tab with stratification charts, visible only when calculation has stratifications',
          'Add ability to reorder stratifications in charts',
          'Add file type column to files table (PUMF or Shared), a new required field when selecting a local file'
        ],
        fixes: ['Fix issue with filter step in tutorial']
      }
    },
    {
      date: 'September 4, 2019',
      appVersion: '1.3.1',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Prompt user before navigating away from site to prevent possible data loss',
          'Changed file tags, variable tags, and calculation tags to tables to improve readability and usability',
          'Downloaded files are now zip files, containing previous result files and calculation description file'
        ],
        fixes: ['Fix issue where download button could be clicked twice in a row']
      }
    },
    {
      date: 'August 15, 2019',
      appVersion: '1.3.0',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Add helper icon near filter button to explain how data will be filtered',
          'Update sample data sets with new variables',
          'Update Calculation Manager UI',
          'Add Scenarios UI to calculations',
          'Add Intervention scenario UI',
          'Add Health behaviour attribution scenario UI'
        ],
        fixes: [
          'Fix readability issues with filter and stratification menus',
          'Remove duplicate variable in Variable Manager'
        ]
      }
    },
    {
      date: 'July 31, 2019',
      appVersion: '1.2.1',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Added download dialog. Allows users to view and select new output columns and file types',
          'In Results manager, only display tabs that apply to the selected calculation. For example, if no summary measures were selected, the Summary tab will be hidden'
        ],
        fixes: [
          'Fixed ignored variables count in variable manager',
          'Minor bug fixes and performance improvements'
        ]
      }
    },
    {
      date: 'July 25, 2019',
      appVersion: '1.2.0',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Added tutorial to help users understand app layout and workflow',
          'Added Deaths (Five years) summary measure to calculate estimated number of deaths',
          'Added button for users to leave feedback',
          'Only display tabs in Results that are relevant to calculation type'
        ],
        fixes: [
          'Fixed output files when using sample data',
          'Minor bug fixes and performance improvements'
        ]
      }
    },
    {
      date: 'April 25, 2019',
      appVersion: '1.1.0',
      engineVersion: '1.13.2',
      updates: {
        features: [
          'Added filters to allow users to filter input files',
          'Added stratifications',
          'Added helper icons for required and recommended variables',
          'Added footer with app and engine versions',
          'General UI updates'
        ],
        fixes: [
          'Fixed variable type and label wording',
          "Fixed issue where users couldn't download output files when using sample data",
          'Minor bug fixes and performance improvements'
        ]
      }
    }
  ],
  [AlgorithmType.RESPECT]: [
    {
      date: 'April 21, 2020',
      appVersion: '1.5.0',
      engineVersion: '1.16.2',
      updates: {
        features: ['RESPECT alpha version'],
        fixes: []
      }
    },
    {
      date: 'March 16, 2020',
      appVersion: '1.4.10',
      engineVersion: '1.16.2',
      updates: {
        features: [],
        fixes: ['Minor bug fixes and improvements']
      }
    },
    {
      date: 'February 20, 2020',
      appVersion: '1.4.8',
      engineVersion: '1.16.2',
      updates: {
        features: [
          'Update tutorial step for clarification',
          'Add link to main Project Big Life site',
          'Add license information in footer',
          'Move feedback button to footer'
        ],
        fixes: []
      }
    },
    {
      date: 'February 13, 2020',
      appVersion: '1.4.7',
      engineVersion: '1.16.2',
      updates: {
        features: [],
        fixes: ['Fix issue where notification status could display incorrect status']
      }
    },
    {
      date: 'February 7, 2020',
      appVersion: '1.4.6',
      engineVersion: '1.16.2',
      updates: {
        features: ['Add notifications option to notify users when calculations are complete'],
        fixes: []
      }
    },
    {
      date: 'January 31, 2020',
      appVersion: '1.4.5',
      engineVersion: '1.16.2',
      updates: {
        features: ['Improve error messages when result sample size is 0'],
        fixes: [
          'Fix issue where calculations with errors would break downloading result files',
          'Fix issue where sample sizes and error count could be calculated incorrectly'
        ]
      }
    },
    {
      date: 'January 30, 2020',
      appVersion: '1.4.4',
      engineVersion: '1.16.2',
      updates: {
        features: [
          'Update calculation loading bar',
          'Always display calculation description in calculation'
        ],
        fixes: ['Fix slowness issues when calculations were completing']
      }
    },
    {
      date: 'January 28, 2020',
      appVersion: '1.4.3',
      engineVersion: '1.16.2',
      updates: {
        features: [
          'Update tutorial steps and added new steps',
          'Update debug mode, can be enabled without restarting the app'
        ],
        fixes: [
          'Fix tutorial issues',
          "Fix calculation error issues, including issues where rows with errors weren't in output files",
          'Fix slowness issues when loading sample data'
        ]
      }
    },
    {
      date: 'January 10, 2020',
      appVersion: '1.4.2',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Add copy button to duplicate previously run calculations',
          'Add button to download sample files',
          'Add description field to calculations',
          'Update fonts',
          'Update tutorial'
        ],
        fixes: ['Fix issue where scenario results would display even if no scenario was selected']
      }
    },
    {
      date: 'November 1, 2019',
      appVersion: '1.4.1',
      engineVersion: '1.15.0',
      updates: {
        features: ['Move variable list into popout', 'Move calculation builder'],
        fixes: ['Prevent By Row measures from being selected when stratifying']
      }
    },
    {
      date: 'September 25, 2019',
      appVersion: '1.4.0',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Add file type column to files table (PUMF or Shared), a new required field when selecting a local file'
        ],
        fixes: ['Fix issue with filter step in tutorial']
      }
    },
    {
      date: 'September 4, 2019',
      appVersion: '1.3.1',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Prompt user before navigating away from site to prevent possible data loss',
          'Changed file tags, variable tags, and calculation tags to tables to improve readability and usability',
          'Downloaded files are now zip files, containing previous result files and calculation description file'
        ],
        fixes: ['Fix issue where download button could be clicked twice in a row']
      }
    },
    {
      date: 'August 15, 2019',
      appVersion: '1.3.0',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Add helper icon near filter button to explain how data will be filtered',
          'Update sample data sets with new variables',
          'Update Calculation Manager UI'
        ],
        fixes: [
          'Fix readability issues with filter and stratification menus',
          'Remove duplicate variable in Variable Manager'
        ]
      }
    },
    {
      date: 'July 31, 2019',
      appVersion: '1.2.1',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Added download dialog. Allows users to view and select new output columns and file types',
          'In Results manager, only display tabs that apply to the selected calculation. For example, if no summary measures were selected, the Summary tab will be hidden'
        ],
        fixes: [
          'Fixed ignored variables count in variable manager',
          'Minor bug fixes and performance improvements'
        ]
      }
    },
    {
      date: 'July 25, 2019',
      appVersion: '1.2.0',
      engineVersion: '1.15.0',
      updates: {
        features: [
          'Added tutorial to help users understand app layout and workflow',
          'Added Deaths (Five years) summary measure to calculate estimated number of deaths',
          'Added button for users to leave feedback',
          'Only display tabs in Results that are relevant to calculation type'
        ],
        fixes: [
          'Fixed output files when using sample data',
          'Minor bug fixes and performance improvements'
        ]
      }
    },
    {
      date: 'April 25, 2019',
      appVersion: '1.1.0',
      engineVersion: '1.13.2',
      updates: {
        features: [
          'Added filters to allow users to filter input files',
          'Added helper icons for required and recommended variables',
          'Added footer with app and engine versions',
          'General UI updates'
        ],
        fixes: [
          'Fixed variable type and label wording',
          "Fixed issue where users couldn't download output files when using sample data",
          'Minor bug fixes and performance improvements'
        ]
      }
    }
  ]
};
