import './variable-found-popover.scss';

import React from 'react';
import { Intent, Popover, Icon, PopoverInteractionKind } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { DataField } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/data-field';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';

type VariableIcon = 'tick' | 'warning-sign' | 'error' | 'time';

export interface IVariableFoundPopoverProps {
  variable: DataField;
  analysisDataFile: AnalysisDataFile;
}

@autobind
@observer
export class VariableFoundPopover extends React.Component<IVariableFoundPopoverProps> {
  render() {
    const { analysisDataFile, variable } = this.props;
    let intent: Intent;
    let icon: VariableIcon;

    if (analysisDataFile.variables.includes(variable.name)) {
      intent = Intent.SUCCESS;
      icon = 'tick';
    } else {
      if (variable.isRequired) {
        intent = Intent.DANGER;
        icon = 'error';
      } else {
        intent = Intent.WARNING;
        icon = 'warning-sign';
      }
    }

    return (
      <div className='centered-content'>
        <Popover
          popoverClassName='standard-popover'
          portalContainer={document.body}
          content={this.renderFoundPopoverContent()}
          canEscapeKeyClose={false}
          minimal
          interactionKind={PopoverInteractionKind.HOVER}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          <Icon icon={icon} intent={intent} />
        </Popover>
      </div>
    );
  }

  private renderFoundPopoverContent() {
    const { analysisDataFile, variable } = this.props;
    let intent: Intent;
    let icon: VariableIcon;
    let message: string = '';
    let isMissingMessage = '';

    if (analysisDataFile.hasLoadedVariables) {
      if (analysisDataFile.variables.includes(variable.name)) {
        message = 'Variable was found in the file';
        icon = 'tick';
        intent = Intent.SUCCESS;
      } else {
        message = 'Variable was not found in the file';

        if (variable.isRequired) {
          icon = 'error';
          isMissingMessage = "The selected file can't be used because this variable is missing.";
          intent = Intent.DANGER;
        } else {
          icon = 'warning-sign';
          isMissingMessage =
            'The selected file can still be used, but the results may be less accurate.';
          intent = Intent.WARNING;
        }
      }
    } else {
      message = 'Please wait, the file is being analyzed...';
      icon = 'time';
      intent = Intent.NONE;
    }

    return (
      <div>
        <div>
          <Icon icon={icon} intent={intent} className='popover-icon' />
          {message}
        </div>
        {isMissingMessage && <div>{isMissingMessage}</div>}
      </div>
    );
  }
}
