import { Calculation } from './calculation';
import { observable, computed } from 'mobx';
import { algorithms } from './algorithms';
import { DataField } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/data-field';
import { autobind } from 'core-decorators';
import { ArrayUtil } from './utils/array-util';
import { IFilter } from './filter';
import { FileType } from './file-type';
import { StratifyHandler } from '../../workers/stratify/stratify-handler';
import { CalculateMeasuresHandler } from '../../workers/calculate-measures/calculate-measures-handler';
import { VariableLabelHandler } from '../../workers/variable-labels/variable-label-handler';
import { c } from './c';
import { ReadMportFileHandler } from '../../workers/read-mport-file/read-mport-file-handler';
import { ReadRespectFileHandler } from '../../workers/read-respect-file/read-respect-file-handler';
import { store } from '../store';
import { IVariableValueMap } from './record-variable-map';
import { ScenarioTypes } from './scenario';
import { VariableLabel } from './variable-label';

export interface IVariableValue {
  [variable: string]: Set<string>;
}

@autobind
export class AnalysisDataFile {
  @observable
  isSelected: boolean = false;
  @observable
  isSampleData: boolean = false;
  @observable
  hasLoadedVariables: boolean = false;
  @observable
  hasLoadedVariableLabels: boolean = false;
  @observable
  dataLoadingProgress: number = 0;
  @observable
  fileError: string = '';
  @observable
  categoricalVariableValues: IVariableValue = {};
  @observable
  activeCalculation: Calculation = new Calculation();
  @observable
  calculations: Calculation[] = [];
  @observable
  variables: string[] = [];
  @observable
  url: string = '';
  @observable
  name: string;
  @observable
  variableLabels: VariableLabel[] = [];

  // Respect
  @observable
  recordCount = 0;
  @observable
  expectedRecordCount = 0;
  @observable
  variableValueMap!: IVariableValueMap;
  @observable
  recordsWithVariableMap!: Map<string, number>;

  file?: File;

  @observable
  private _fileType: FileType = FileType.None;

  constructor(name: string, fileOrUrl: string | File, isSampleData: boolean = false) {
    this.name = name;
    this.isSampleData = isSampleData;

    if (this.isSampleData) this.url = fileOrUrl as string;
    else this.file = fileOrUrl as File;

    this.analyzeData();
  }

  async analyzeData() {
    if (store.algorithm.isMport) await ReadMportFileHandler.analyzeFile(this);
    else if (store.algorithm.isRespect) await ReadRespectFileHandler.analyzeFile(this);

    if (this.isSampleData) {
      const configData = store.algorithm.config.sampleData.find((data) => data.name === this.name);
      if (configData) this.fileType = configData.fileType;
    } else {
      if (this._fileType !== FileType.None) await VariableLabelHandler.buildVariableLabels(this);
    }
  }

  /**
   * @description Get all variables that are similar to a query
   */
  getMatchingVariables(query: string) {
    const normalizedQuery = query.toLowerCase();
    const variablesWithMatchingLabel: Set<string> = new Set();

    this.foundKnownVariables.forEach((variable) => {
      const { metadata } = variable;

      if (
        metadata.label.toLowerCase().includes(normalizedQuery) ||
        metadata.shortLabel.toLowerCase().includes(normalizedQuery)
      ) {
        variablesWithMatchingLabel.add(variable.name);
      }
    });

    this.variables.forEach((variable) => {
      if (variable.toLowerCase().includes(normalizedQuery)) {
        variablesWithMatchingLabel.add(variable);
      }
    });

    return [...variablesWithMatchingLabel].sort();
  }

  getVariableLabels(variableName: string) {
    return (
      this.variableLabels.find((variableLabel) => variableLabel.variableName === variableName) ||
      new VariableLabel(variableName)
    );
  }

  /**
   * @description Get all categories that were found in the file for a variable
   */
  getFoundCategoriesForVariable(variableName: string) {
    const variableCategories = this.categoricalVariableValues[variableName] || [];

    return [...variableCategories].sort();
  }

  getVariableMin(variableName: string) {
    return Number(this.getSortedContinuousVariableValues(variableName)[0]) || 0;
  }

  getVariableMax(variableName: string) {
    const variableValues = this.getSortedContinuousVariableValues(variableName);

    return Number(variableValues[variableValues.length - 1]) || 0;
  }

  startActiveCalculation() {
    const { activeCalculation } = this;
    activeCalculation.setIsProcessing();

    this.calculations.push(activeCalculation);
    this.activeCalculation = new Calculation();
  }

  /**
   * @description Set a calculation as selected based on calculation name
   * @param name Calculation name
   */
  setCalculationIsSelectedByName(name: string) {
    this.calculations.forEach(
      (calculation) => (calculation.isSelected = calculation.name === name)
    );
  }

  getLabelForCategory(variableName: string, categoryValue: string) {
    const variableLabel = this.getVariableLabels(variableName);
    const matchingCategory = variableLabel.categories.find(
      (category) => category.categoryValue === categoryValue
    );
    if (matchingCategory) return matchingCategory.categoryLabel;
    return '';
  }

  getVariableByName(variableName: string) {
    return this.variableLabels.find((variable) => variable.variableName === variableName);
  }

  private getSortedContinuousVariableValues(variableName: string) {
    return ArrayUtil.sortAscending(this.getFoundCategoriesForVariable(variableName));
  }

  /**
   * @description Filter out variable categories based on a set of filters
   */
  buildFilteredCategoricalVariableValues(filters: IFilter[]) {
    const categoricalVariableValues: IVariableValue = {
      ...this.categoricalVariableValues
    };

    filters.forEach((filter) => {
      const categories = [...categoricalVariableValues[filter.variableName]].filter((category) =>
        filter.categories.includes(category)
      );

      categoricalVariableValues[filter.variableName] = new Set(categories);
    });

    return categoricalVariableValues;
  }

  executeCalculation() {
    const calculation = this.activeCalculation;

    // Trim name and description or set defaults
    calculation.name = calculation.name.trim() || this.getNextCalculationName();
    calculation.description = calculation.description.trim() || c.noDescription;

    /* While calculation is being built, it can have a scenario type selected, but have no
    scenarios selected. If so, set scenario type to None */
    if (calculation.scenarios.length === 0) calculation.scenarioType = ScenarioTypes.None;
    /* While calculation is being built, it can have scenario type to None, but have scenarios. If
    so, clear scenarios */
    if (calculation.scenarioType === ScenarioTypes.None) calculation.scenarios = [];

    this.startActiveCalculation();

    if (calculation.isStratified) {
      const { stratifications } = calculation;
      const index = stratifications.indexOf(c.variables.sex);

      /* If sex is a stratified variable, set it as the first stratification. This is for the
      stratification chart, where order of stratifications matters. We also want to
      ensure that sex is one of the top three stratifications selected if more than three
      are selected */
      if (index > 0) {
        stratifications.splice(index, 1);
        stratifications.unshift(c.variables.sex);
      }

      /* Although By Row measures are disabled when stratifications are selected, for
      usability purposes, By Row measures that were already selected remain selected.
      Once the calculation is started, any disabled measures should be removed entirely */
      calculation.measures = calculation.activeMeasuresUsableForScenarios;

      StratifyHandler.stratifyCalculation(this, calculation);
    } else {
      CalculateMeasuresHandler.calculateMeasures(this.fileOrUrl, calculation);
    }
  }

  isRequiredVariableByName(variableName: string) {
    const matchingVariable = this.requiredVariables.find(
      (variable) => variable.name === variableName
    );

    return matchingVariable ? matchingVariable.isRequired : false;
  }

  /**
   * @description Build a new calculation name. Continue trying new names until a unique name is
   * found.
   */
  private getNextCalculationName() {
    let calculationCount = 1;
    let calculationName = `Calculation_${this.calculations.length + calculationCount}`;

    while (1) {
      const calculationNameExists = this.calculations.some(
        (calculation) => calculation.name === calculationName
      );
      if (!calculationNameExists) break;

      calculationCount += 1;
      calculationName = `Calculation_${this.calculations.length + calculationCount}`;
    }

    return calculationName;
  }

  @computed
  get hasReadFileValues() {
    return this.dataLoadingProgress === 1;
  }

  @computed
  get requiredVariables() {
    const variables: DataField[] = [];
    const variableNames: Set<string> = new Set();

    algorithms.models.forEach((model) => {
      model.getModelRequiredFields().forEach((field) => {
        if (!variableNames.has(field.name)) {
          variables.push(field);
          variableNames.add(field.name);
        }
      });

      model.algorithms.forEach((algorithm) =>
        algorithm.algorithm.getRequiredVariables().forEach((field) => {
          if (!variableNames.has(field.name)) {
            variables.push(field);
            variableNames.add(field.name);
          }
        })
      );
    });

    return variables.sort((a, b) => a.name.localeCompare(b.name));
  }

  @computed
  get recommendedOnlyVariables() {
    const variables: DataField[] = [];
    const variableNames: Set<string> = new Set();

    algorithms.models.forEach((model) => {
      model.getModelRecommendedFields().forEach((field) => {
        if (!variableNames.has(field.name)) {
          variables.push(field);
          variableNames.add(field.name);
        }
      });

      model.algorithms.forEach((algorithm) =>
        algorithm.algorithm.getRecommendedVariables().forEach((field) => {
          if (!variableNames.has(field.name)) {
            variables.push(field);
            variableNames.add(field.name);
          }
        })
      );
    });

    return (
      variables
        // Variables can be both required and recommended. For this list, use variables that
        // are recommended and are not required
        .filter((variable) => !variable.isRequired)
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }

  @computed
  get allKnownVariables() {
    return this.requiredVariables.concat(this.recommendedOnlyVariables);
  }

  /**
   * @description Get list of variables that were found in file, but are not recognized
   */
  @computed
  get ignoredVariables() {
    const unrecognizedVariables: string[] = [];

    this.variables.forEach((field) => {
      const isRecognized = this.allKnownVariables.some((variable) => variable.name === field);

      if (!isRecognized) unrecognizedVariables.push(field);
    });

    return unrecognizedVariables;
  }

  @computed
  get covariates() {
    return algorithms.models[0].algorithms[0].algorithm.covariates;
  }

  @computed
  get selectedCalculation() {
    return this.calculations.find((calculation) => calculation.isSelected);
  }

  @computed
  get hasRequiredVariables() {
    return this.requiredVariables.every((variable) => this.variables.includes(variable.name));
  }

  @computed
  get hasRecommendedVariables() {
    return this.recommendedOnlyVariables.every((variable) =>
      this.variables.includes(variable.name)
    );
  }

  @computed
  get hasAllVariables() {
    return this.hasRequiredVariables && this.hasRecommendedVariables;
  }

  @computed
  get foundKnownVariables() {
    const matchedVariables: DataField[] = [];

    this.allKnownVariables.forEach((variable) => {
      if (this.variables.includes(variable.name)) {
        matchedVariables.push(variable);
      }
    });

    return matchedVariables;
  }

  @computed
  get canRunCalculations() {
    return this.hasRequiredVariables && this.fileType !== FileType.None;
  }

  @computed
  get fileOrUrl() {
    return this.isSampleData ? this.url : this.file!;
  }

  @computed
  get fileType() {
    return this._fileType;
  }

  @computed
  get isLargeFile() {
    if (this.file) return this.file.size > c.maxFileSizeBytes;
    return false;
  }

  /**
   * @description Set the file type. When the file type is changed, build variable labels because
   * file type can change how the variables are labelled.
   */
  set fileType(fileType: FileType) {
    const isDifferentFileType = fileType !== this._fileType;
    this._fileType = fileType;

    if (isDifferentFileType) VariableLabelHandler.buildVariableLabels(this);
  }
}
