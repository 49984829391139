import './calculate-button.scss';

import React from 'react';
import { Intent, Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface ICalculateButtonProps {
  isCalculationNameDuplicate: () => boolean;
  analysisDataFile: AnalysisDataFile;
  onClick: () => void;
}

interface ICalculateButtonState {
  hasClickedCalculateButton: boolean;
}

@observer
@autobind
export class CalculateButton extends React.Component<ICalculateButtonProps, ICalculateButtonState> {
  constructor(props: ICalculateButtonProps) {
    super(props);

    this.state = {
      hasClickedCalculateButton: false
    };
  }

  render() {
    const { analysisDataFile } = this.props;

    const canCalculate =
      !this.props.isCalculationNameDuplicate() &&
      analysisDataFile.activeCalculation.activeMeasures.length > 0;

    /**
     * Set the calculation button have no intent if it's disabled. If it's enabled, set it to
     * Warning if the file is missing recommended variables, or Success if it has all recommended
     * variables
     */
    let calculateButtonIntent: Intent = Intent.NONE;

    if (canCalculate) {
      if (analysisDataFile.hasAllVariables) {
        calculateButtonIntent = Intent.SUCCESS;
      } else {
        calculateButtonIntent = Intent.WARNING;
      }
    }

    return (
      <TutorialStep
        stepComponent={TutorialSteps.CalculateButton}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={false}
        isTutorialStepComplete={this.isTutorialStepComplete}
      >
        <Button
          disabled={!canCalculate}
          text='Calculate'
          onClick={this.onCalculateButtonClick}
          intent={calculateButtonIntent}
          icon='calculator'
        />
      </TutorialStep>
    );
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          We're all set! Click the <strong>Calculate</strong> button to start the calculation.
        </div>
      </div>
    );
  }

  private isTutorialStepComplete() {
    return this.state.hasClickedCalculateButton;
  }

  private onCalculateButtonClick() {
    const { analysisDataFile } = this.props;

    this.setState({
      hasClickedCalculateButton: true
    });

    analysisDataFile.executeCalculation();
    this.props.onClick();
  }
}
