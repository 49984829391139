import { IScenario } from '@ottawamhealth/pbl-calculator-engine/lib/scenario/scenario';
import {
  ISexScenarioConfig,
  ScenarioMethods,
  VariableMethods,
  isVariableRange
} from '@ottawamhealth/pbl-calculator-engine/lib/scenario';
import { c } from './c';
import { observable, computed } from 'mobx';
import { InfinityRange } from '@ottawamhealth/pbl-calculator-engine/lib/scenario/scenario-variable';

export enum ScenarioTypes {
  None = 'None',
  Intervention = 'Intervention',
  HealthBehaviour = 'Health behaviour attribution'
}

export class Scenario implements IScenario {
  @observable
  name: string;
  @observable
  method: ScenarioMethods;
  @observable
  subject: string;
  @observable
  description?: string = '';
  @observable
  descriptionDetails?: string = '';
  @observable
  units?: string;
  @observable
  male: ISexScenarioConfig;
  @observable
  female: ISexScenarioConfig;
  @observable
  readonly originalScenario: IScenario;

  constructor(scenario: IScenario) {
    this.originalScenario = scenario;
    const scenarioCopy: IScenario = JSON.parse(JSON.stringify(scenario));
    this.name = scenarioCopy.name;
    this.method = scenarioCopy.method;
    this.subject = scenarioCopy.subject;
    this.method = scenario.method;
    this.description = scenario.description;
    this.descriptionDetails = scenario.descriptionDetails;
    this.male = scenarioCopy.male;
    this.female = scenarioCopy.female;

    this.setValues(0);
  }

  /**
   * @description Set all scenario values, and any other values that mirror scenario value changes
   * @param scenarioValue Scenario value
   */
  setValues(scenarioValue: number) {
    [this.originalScenario.male, this.originalScenario.female].forEach(originalSex => {
      const matchingSex = originalSex === this.originalScenario.male ? this.male : this.female;

      originalSex.variables.forEach((originalVariable, originalVariableIndex) => {
        if (originalVariable.scenarioValue.toString() === c.scenarioUserDefinedField) {
          const matchingVariable = matchingSex.variables[originalVariableIndex];
          matchingVariable.scenarioValue = scenarioValue;

          if (originalVariable.postScenarioRange) {
            matchingVariable.postScenarioRange = this.maybeUpdateRangeWithScenarioValue(
              scenarioValue,
              originalVariable.postScenarioRange
            );
          }

          if (isVariableRange(originalVariable.targetPop)) {
            originalVariable.targetPop.forEach(population => {
              population.range = this.maybeUpdateRangeWithScenarioValue(
                scenarioValue,
                population.range
              );
            });
          } else {
            matchingVariable.targetPop = this.maybeUpdateRangeWithScenarioValue(
              scenarioValue,
              originalVariable.targetPop
            );
          }
        }
      });
    });
  }

  /**
   * @description Whether the scenario can be updated. It can't be updated if every variable
   * has a preset value
   */
  canUpdate() {
    const { male, female } = this.originalScenario;

    return [male, female].some(sex =>
      sex.variables.some(
        variable => variable.scenarioValue.toString() === c.scenarioUserDefinedField
      )
    );
  }

  private maybeUpdateRangeWithScenarioValue(
    scenarioValue: number,
    range: InfinityRange
  ): InfinityRange {
    let [min, max] = range;
    if (min === c.scenarioValueField) min = scenarioValue;
    if (max === c.scenarioValueField) max = scenarioValue;
    return [min, max];
  }

  @computed
  get isRelative() {
    const relativeScenarioMethods = [
      VariableMethods.RelativeScenario,
      VariableMethods.RelativeScenarioCat
    ];
    return relativeScenarioMethods.includes(this.male.variables[0].method);
  }

  @computed
  get isHealthBehaviourAttribution() {
    return this.method === ScenarioMethods.Attribution;
  }

  @computed
  get isIntervention() {
    return !this.isHealthBehaviourAttribution;
  }
}
