import { AlgorithmType } from './algorithm-type';
import { Measures } from './measures';
import { computed } from 'mobx';
import { store } from '../store';

enum MeasureTypes {
  ByRow = 'ByRow',
  Summary = 'Summary'
}

type AlgorithmMeasureTypeMap = {
  [algorithm in AlgorithmType]: { [measureType in MeasureTypes]: Measures[] };
};

const algorithmMeasureMap: AlgorithmMeasureTypeMap = {
  [AlgorithmType.MPoRT]: {
    [MeasureTypes.ByRow]: [Measures.LifeExpectancyByRow, Measures.FiveYearMortalityRiskByRow],
    [MeasureTypes.Summary]: [
      Measures.LifeExpectancySummary,
      Measures.DeathsFiveYears,
      Measures.FiveYearMortalityRiskSummary
    ]
  },
  [AlgorithmType.RESPECT]: {
    [MeasureTypes.ByRow]: [
      Measures.SurvivalTime,
      Measures.ThreeMonthRisk,
      Measures.SixMonthRisk,
      Measures.OneYearRisk,
      Measures.TwentyFifthPercentileSurvivalTime,
      Measures.SeventyFifthPercentileSurvivalTime
    ],
    [MeasureTypes.Summary]: []
  }
};

export class AlgorithmMeasure {
  @computed
  get SummaryMeasures() {
    return algorithmMeasureMap[store.algorithm.type].Summary;
  }

  @computed
  get ByRowMeasures() {
    return algorithmMeasureMap[store.algorithm.type].ByRow;
  }
}
