import './stratifications-tab.scss';

import React from 'react';

import { Calculation } from '../../../store/domain/calculation';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Button, H5, Callout } from '@blueprintjs/core';
import { Measures } from '../../../store/domain/measures';
import { c } from '../../../store/domain/c';
import { StratificationsChart } from '../StratificationChart';

export interface IStratificationsTabProps {
  analysisDataFile: AnalysisDataFile;
  calculation: Calculation;
}

interface IStratificationsTabState {
  selectedMeasure: Measures;
}

@observer
@autobind
export class StratificationsTab extends React.Component<
  IStratificationsTabProps,
  IStratificationsTabState
> {
  constructor(props: IStratificationsTabProps) {
    super(props);

    this.state = {
      selectedMeasure: this.props.calculation.measures[0]
    };
  }

  render() {
    const { calculation } = this.props;
    const strataCount = calculation.stratifications.length;

    if (strataCount === 0) return <div>No stratifications were selected.</div>;

    return (
      <div>
        {this.renderMeasureSelection()}
        {this.renderStratificationOrder()}
        <StratificationsChart
          analysisDataFile={this.props.analysisDataFile}
          calculation={calculation}
          selectedMeasure={this.state.selectedMeasure}
        />
      </div>
    );
  }

  /**
   * @description If the user has the stratification tab open for one calculation, then selects a
   * different calculation, ensure that the new calculation has the same selected measure
   * available. If not, select the new calculation's first measure
   * @param prevProps Previous props
   * @param prevState Previous state
   */
  componentDidUpdate(_prevProps: IStratificationsTabProps, prevState: IStratificationsTabState) {
    const knownMeasures = this.props.calculation.measures;

    if (!knownMeasures.includes(prevState.selectedMeasure)) {
      this.setState({
        selectedMeasure: knownMeasures[0]
      });
    }
  }

  private renderMeasureSelection() {
    return (
      <div className='stratification-measure-selection'>
        <H5>Select measure</H5>
        <select onChange={this.onMeasureChange}>
          {this.props.calculation.measures.map(measure => (
            <option key={measure} label={measure} value={measure} />
          ))}
        </select>
      </div>
    );
  }

  private onMeasureChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      selectedMeasure: e.currentTarget.value as Measures
    });
  }

  private renderStratificationOrder() {
    const { stratifications } = this.props.calculation;
    const hasSexStratification = stratifications.includes(c.variables.sex);

    return (
      <div>
        <H5>Order stratifications</H5>
        {stratifications.length > c.maxStratifications &&
          this.renderMaxStratificationsCountWarning()}
        <ol>
          {stratifications.map((stratification, index) => {
            /**
             * Disable reorder button if stratification is already at the top, or if its second
             * from the top and the top is sex. If stratifying by sex, sex is fixed at the top
             * and can't be moved
             */
            const isDisabled = index === 0 || (index === 1 && hasSexStratification);

            return (
              <li key={stratification}>
                <Button
                  className='ordered-stratifications-button'
                  disabled={isDisabled}
                  icon={isDisabled ? 'disable' : 'arrow-up'}
                  onClick={() => this.onStratificationClick(stratification)}
                />
                {stratification}
              </li>
            );
          })}
        </ol>
      </div>
    );
  }

  private renderMaxStratificationsCountWarning() {
    return (
      <Callout icon='info-sign'>
        Only first {c.maxStratifications} stratifications are used in charts
      </Callout>
    );
  }

  private onStratificationClick(stratification: string) {
    const { stratifications, strata } = this.props.calculation;
    const index = stratifications.indexOf(stratification);

    stratifications.splice(index, 1);
    stratifications.splice(index - 1, 0, stratification);

    strata.forEach(
      stratum =>
        (stratum.stratifications = stratum.stratifications
          .slice()
          .sort(
            (a, b) =>
              stratifications.indexOf(a.variableName) - stratifications.indexOf(b.variableName)
          ))
    );
  }
}
