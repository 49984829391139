import './open-variable-manager-button.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Button } from '@blueprintjs/core';
import { Tutorial } from '../../../store/domain/tutorial';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { VariableManager } from '../VariableManager/VariableManager';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IOpenVariableManagerButtonProps {
  analysisDataFile: AnalysisDataFile;
  tutorial?: Tutorial;
}

interface IOpenVariableManagerButtonState {
  isOpen: boolean;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class OpenVariableManagerButton extends React.Component<
  IOpenVariableManagerButtonProps,
  IOpenVariableManagerButtonState
> {
  constructor(props: IOpenVariableManagerButtonProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  render() {
    const { analysisDataFile } = this.props;

    return (
      <TutorialStep
        stepComponent={TutorialSteps.OpenVariableManagerButton}
        tutorialMessage={this.renderTutorialMessage()}
        showContinueButton={false}
        isTutorialStepComplete={this.isTutorialStepComplete}
      >
        <Button
          text={this.props.tutorial!.variableManagerButtonText}
          onClick={this.onVariableManagerOpen}
          disabled={!analysisDataFile.hasLoadedVariableLabels}
        />
        <VariableManager
          analysisDataFile={analysisDataFile}
          isOpen={this.state.isOpen}
          onClose={this.onVariableManagerClose}
        />
      </TutorialStep>
    );
  }

  private isTutorialStepComplete() {
    return this.state.isOpen;
  }

  private renderTutorialMessage() {
    return (
      <div>
        <div>
          Now that we've selected a file, let's take a look at the <strong>Variable Manager</strong>
          .
        </div>
        <div>
          The Variable Manager shows which variables were found and which were missing in the
          selected file.
        </div>
        <br />
        <div>
          Click the <i>{this.props.tutorial!.variableManagerButtonText}</i> button to show the
          Variable Manager.
        </div>
      </div>
    );
  }

  private onVariableManagerOpen() {
    this.setState({
      isOpen: true
    });
  }

  private onVariableManagerClose() {
    this.setState({
      isOpen: false
    });
  }
}
