import { AnalysisDataFile } from './analysis-data-file';
import { observable, action, computed } from 'mobx';

export class AnalysisData {
  @observable.shallow
  analysisDataFiles: AnalysisDataFile[] = [];

  addNewAnalysisDataFileSampleFromUrl(url: string) {
    const analysisDataFile = new AnalysisDataFile(this.getFileNameFromUrl(url), url, true);
    analysisDataFile.url = url;
    this.analysisDataFiles.push(analysisDataFile);
  }

  addNewDataAnalysisFile(file: File) {
    const analysisDataFile = new AnalysisDataFile(file.name, file);
    this.analysisDataFiles.push(analysisDataFile);
    return analysisDataFile;
  }

  removeUserDataAnalysisFileByName(name: string) {
    const fileIndex = this.analysisDataFiles.findIndex(
      file => !file.isSampleData && file.name === name
    );

    if (fileIndex >= 0) this.analysisDataFiles.splice(fileIndex, 1);
  }

  findDataFileWithName(dataFileName: string): AnalysisDataFile | undefined {
    return this.analysisDataFiles.find(analysisDataFile => analysisDataFile.name === dataFileName);
  }

  findUserDataFileWithName(dataFileName: string): AnalysisDataFile | undefined {
    return this.analysisDataFiles.find(
      analysisDataFile => !analysisDataFile.isSampleData && analysisDataFile.name === dataFileName
    );
  }

  private getFileNameFromUrl(url: string) {
    return url.split('/').slice(-1)[0];
  }

  @computed
  get sampleData() {
    return this.analysisDataFiles.filter(file => file.isSampleData);
  }

  @computed
  get userData() {
    return this.analysisDataFiles.filter(file => !file.isSampleData);
  }

  @computed
  get sortedAnalysisDataFiles() {
    return this.userData.concat(this.sampleData);
  }

  @computed
  get selectedAnalysisDataFile() {
    return this.analysisDataFiles.find(file => file.isSelected);
  }

  @action
  setSelectedAnalysisDataFile(analysisDataFile: AnalysisDataFile) {
    if (this.selectedAnalysisDataFile) this.selectedAnalysisDataFile.isSelected = false;
    analysisDataFile.isSelected = true;
  }
}

export const analysisData = new AnalysisData();
