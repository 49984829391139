import './add-filter-button.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Button, Intent } from '@blueprintjs/core';
import { Filter } from '../../../store/domain/filter';
import { Calculation } from '../../../store/domain/calculation';
import { Store } from '../../../store/store';
import { Tutorial } from '../../../store/domain/tutorial';

export interface IAddFilterButtonProps {
  calculation: Calculation;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class AddFilterButton extends React.Component<IAddFilterButtonProps> {
  render() {
    return (
      <Button
        icon='plus'
        text={this.props.tutorial!.addFilterButtonText}
        intent={Intent.SUCCESS}
        onClick={this.onClick}
      />
    );
  }

  private onClick() {
    this.props.calculation.filters.push(new Filter());
  }
}
