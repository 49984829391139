import { Calculation } from '../domain/calculation';
import { AnalysisDataFile } from '../domain/analysis-data-file';

export class CalculationCompleteToast {
    calculation: Calculation;
    analysisDataFile: AnalysisDataFile;

    constructor(calculation: Calculation, analysisDataFile: AnalysisDataFile) {
        this.calculation = calculation;
        this.analysisDataFile = analysisDataFile;
    }
}
