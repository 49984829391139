import './calculation-row.scss';

import classNames from 'classnames';
import React from 'react';
import { Calculation } from '../../../store/domain/calculation';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { CalculationDescriptionPopover } from '../CalculationDescriptionPopover';
import { c } from '../../../store/domain/c';
import { TutorialSteps } from '../../../store/domain/tutorial-step';
import { CalculationRowResults } from '../../smart/CalculationRowResults';

export interface ICalculationRowProps {
  calculation: Calculation;
  onClick: () => void;
  isMostRecent: boolean;
}

@autobind
@observer
export class CalculationRow extends React.Component<ICalculationRowProps> {
  render() {
    const { calculation } = this.props;

    const measures = calculation.measures.join(', ');
    const filters =
      calculation.activeFilters.map(filter => filter.variableName).join(', ') ||
      c.genericEmptyMessage;
    const stratifications = calculation.stratifications.join(', ') || c.genericEmptyMessage;

    return (
      <tr
        className={classNames({
          'bp3-intent-success bp3-callout': calculation.isSelected
        })}
      >
        <td>
          <TutorialStep
            stepComponent={TutorialSteps.WaitForCalculation}
            tutorialMessage={this.renderProgressBarTutorialMessage()}
            isTutorialStepComplete={() => this.props.isMostRecent && calculation.isFinished}
            showContinueButton={false}
          >
            <div className='centered-content'>
              {<CalculationRowResults calculation={calculation} onClick={this.props.onClick} />}
            </div>
          </TutorialStep>
        </td>
        <td>
          {calculation.name}
          <CalculationDescriptionPopover description={calculation.description} />
        </td>
        <td>{measures}</td>
        <td>{filters}</td>
        <td>{stratifications}</td>
        <td>{calculation.scenarioType}</td>
      </tr>
    );
  }

  private renderProgressBarTutorialMessage() {
    return (
      <div>
        <div>The calculation is being processed, please wait.</div>
      </div>
    );
  }
}
