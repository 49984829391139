import { Calculation } from '../calculation';
import { Scenario, ScenarioTypes } from '../scenario';
import { observable } from 'mobx';

const calculationNameSuffix = '_copy';

/**
 * @description Clone objects. Used instead of a deep clone alternative to ensure that we only
 * clone certain properties and avoid others. For example, we don't want to clone a
 * _key = Math.random() property, or similar unique ID properties
 */
export abstract class CloneUtil {
  static cloneCalculation(oldCalculation: Calculation) {
    const newCalculation = new Calculation();
    newCalculation.name = oldCalculation.name + calculationNameSuffix;
    newCalculation.measures = [...oldCalculation.measures];
    newCalculation.stratifications = [...oldCalculation.stratifications];
    newCalculation.filters = this.deepClone(oldCalculation.filters);

    newCalculation.scenarioType = oldCalculation.scenarioType;
    newCalculation.scenarios = oldCalculation.scenarios.map(scenario =>
      this.cloneScenario(scenario)
    );

    newCalculation.areFiltersEnabled = newCalculation.filters.length > 0;
    newCalculation.areStratificationsEnabled = newCalculation.stratifications.length > 0;
    newCalculation.isScenarioEnabled = newCalculation.scenarioType !== ScenarioTypes.None;
    return newCalculation;
  }

  /**
   * @description Clone scenario. Maintain original scenario reference to match this scenario
   * to existing loaded scenarios
   */
  static cloneScenario(oldScenario: Scenario) {
    const newScenario = new Scenario(oldScenario.originalScenario);
    newScenario.female = this.deepClone(oldScenario.female);
    newScenario.male = this.deepClone(oldScenario.male);
    newScenario.name = oldScenario.name;
    newScenario.subject = oldScenario.subject;
    return newScenario;
  }

  /**
   * @description Clone an object and make it observable
   */
  static deepClone(object: any) {
    return observable(JSON.parse(JSON.stringify(object)));
  }
}
