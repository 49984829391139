export interface ISimpleCategory {
  categoryValue: string;
  categoryLabel: string;
}

export interface ISimpleInterval {
  upperLimit: number;
  lowerLimit: number;
}

export class VariableLabel {
  variableName: string = '';
  label: string = '';
  shortLabel: string = '';
  categories: ISimpleCategory[] = [];
  intervals: ISimpleInterval[] = [];

  constructor(variableName: string) {
    this.variableName = variableName;
  }
}
