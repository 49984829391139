import './notification-status.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { H4, Callout, Intent, Icon } from '@blueprintjs/core';
import { HelperContainer } from '../../dumb/HelperContainer';
import { Tutorial } from '../../../store/domain/tutorial';
import { TutorialStep } from '../../dumb/Tutorial/TutorialStep';
import { NotificationToggleButton } from '../NotificationToggleButton';
import { Store } from '../../../store/store';
import { NotificationPrompt } from '../../../store/domain/notification-prompt';
import { TutorialPosition } from '../../dumb/Tutorial/TutorialStatusModal';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface INotificationStatusProps {
  notificationPrompt?: NotificationPrompt;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    notificationPrompt: store.notificationPrompt,
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class NotificationStatus extends React.Component<INotificationStatusProps> {
  render() {
    const hasSetNotificationPermission = this.props.notificationPrompt!.permission !== 'default';

    return (
      <TutorialStep
        stepComponent={TutorialSteps.NotificationButton}
        tutorialMessage={this.renderDescriptionContent()}
        showContinueButton
        position={TutorialPosition.TopLeft}
      >
        <HelperContainer content={this.renderHelperContent()} alignment='center'>
          {hasSetNotificationPermission ? this.notificationMessage() : <NotificationToggleButton />}
        </HelperContainer>
      </TutorialStep>
    );
  }

  private renderHelperContent() {
    return (
      <div>
        <H4>Notifications</H4>
        {this.renderDescriptionContent()}
      </div>
    );
  }

  private notificationMessage() {
    const intent = Notification.permission === 'granted' ? Intent.SUCCESS : Intent.NONE;
    const status = Notification.permission === 'granted' ? 'on' : 'off';

    return (
      <Callout icon='notifications' intent={intent}>
        Notifications {status}
      </Callout>
    );
  }

  private renderDescriptionContent() {
    return (
      <div>
        <div>
          Calculations can take an hour to complete, or even longer, depending on the file selected,
          the calculation complexity, and your machine.
        </div>
        {this.getTutorialPromptText()}
      </div>
    );
  }

  private getTutorialPromptText() {
    switch (Notification.permission) {
      case 'default': {
        return (
          <div>
            If you want to receive notifications when calculations are completed, click the{' '}
            <i>{this.props.tutorial!.enableNotificationsText}</i> button and click <i>Allow</i> when
            prompted.
          </div>
        );
      }
      case 'denied': {
        return (
          <div>
            Notifications have been disabled. To enable notifications, click the{' '}
            <Icon icon='info-sign' /> icon in the address bar, and select{' '}
            <i>Notifications: Allow</i>.
          </div>
        );
      }
      case 'granted': {
        return (
          <div>
            You've enabled notifications already, so you'll receive notifications when calculations
            are completed.
          </div>
        );
      }
    }
  }
}
