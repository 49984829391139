import { computed } from 'mobx';
import { Config } from '../../json';
import { AlgorithmType } from './algorithm-type';

const algorithmPathNameMap: { [pathname: string]: AlgorithmType } = {
  '/': AlgorithmType.MPoRT,
  '/respect': AlgorithmType.RESPECT
};

const defaultAlgorithm = AlgorithmType.MPoRT;

export class Algorithm {
  @computed
  get type() {
    const pathname = location.pathname.toLowerCase();
    return algorithmPathNameMap[pathname] || defaultAlgorithm;
  }

  @computed
  get config() {
    return Config.algorithms.find((algorithm) => algorithm.name === this.type)!;
  }

  @computed
  get isMport() {
    return this.type === AlgorithmType.MPoRT;
  }

  @computed
  get isRespect() {
    return this.type === AlgorithmType.RESPECT;
  }
}
