import './result-tabs.scss';

import React from 'react';
import { Intent, Tabs, Tab, Icon, Callout } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { observer } from 'mobx-react';

import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { OverviewTab } from '../OverviewTab';
import { SummaryMeasuresTab } from '../SummaryMeasuresTab';
import { ByRowTab } from '../ByRowTab';
import { LogTab } from '../LogTab';
import { StratificationsTab } from '../StratificationsTab';
import { c } from '../../../store/domain/c';

enum TabIds {
  Overview = 'Overview',
  SummaryMeasures = 'Summary Measures',
  ByRowMeasures = 'By Row Measures',
  Stratifications = 'Stratifications',
  Log = 'Log'
}

const defaultTab = TabIds.Overview;

const alwaysDisplayedTabs: TabIds[] = [TabIds.Overview, TabIds.Log];
const stratificationOnlyTabs: TabIds[] = [TabIds.Stratifications];
const summaryCalculationOnlyTabs: TabIds[] = [TabIds.SummaryMeasures];
const byRowCalculationOnlyTabs: TabIds[] = [TabIds.ByRowMeasures];

interface IResultTabsState {
  selectedTabId: TabIds;
}

export interface IResultTabsProps {
  analysisDataFile: AnalysisDataFile;
}

@observer
@autobind
export class ResultTabs extends React.Component<IResultTabsProps, IResultTabsState> {
  constructor(props: IResultTabsProps) {
    super(props);

    this.state = {
      selectedTabId: TabIds.Overview
    };
  }

  render() {
    return this.props.analysisDataFile.isLargeFile
      ? this.renderLargeFileMessage()
      : this.renderTabs();
  }

  /**
   * @description If user had a tab selected that will no longer be visible due to the newly
   * selected calculation type, select the default tab
   * @param prevProps Previous props
   */
  componentDidUpdate(prevProps: IResultTabsProps) {
    const { selectedTabId } = this.state;

    if (!alwaysDisplayedTabs.includes(selectedTabId)) {
      const calculation = prevProps.analysisDataFile.selectedCalculation!;
      let canViewPreviousTab = true;

      if (calculation.isStratified) {
        canViewPreviousTab = stratificationOnlyTabs.includes(selectedTabId);
      } else {
        if (calculation.hasByRowMeasures && calculation.hasSummaryMeasures) {
          canViewPreviousTab = [
            ...byRowCalculationOnlyTabs,
            ...summaryCalculationOnlyTabs
          ].includes(selectedTabId);
        } else if (calculation.hasByRowMeasures) {
          canViewPreviousTab = byRowCalculationOnlyTabs.includes(selectedTabId);
        } else if (calculation.hasSummaryMeasures) {
          canViewPreviousTab = summaryCalculationOnlyTabs.includes(selectedTabId);
        }
      }

      if (!canViewPreviousTab) {
        this.setState({
          selectedTabId: defaultTab
        });
      }
    }
  }

  private renderLargeFileMessage() {
    return (
      <Callout intent={Intent.WARNING}>
        <div>This file is too large to display the results.</div>
        <div>
          Please press the <strong>{c.downloadResultsText}</strong> button to view the calculation
          results.
        </div>
      </Callout>
    );
  }

  private renderTabs() {
    const { analysisDataFile } = this.props;
    const calculation = analysisDataFile.selectedCalculation!;
    const { isStratified } = calculation;
    let displayByRowMeasuresTab = false;
    let displaySummaryMeasuresTab = false;

    if (!isStratified) {
      displayByRowMeasuresTab = calculation.hasByRowMeasures;
      displaySummaryMeasuresTab = calculation.hasSummaryMeasures;
    }

    return (
      <Tabs id='result-tabs' onChange={this.onTabChange} selectedTabId={this.state.selectedTabId}>
        <Tab
          id={TabIds.Overview}
          title={TabIds.Overview}
          panel={<OverviewTab calculation={calculation} analysisDataFile={analysisDataFile} />}
        />
        {displaySummaryMeasuresTab && this.renderSummaryMeasuresTab()}
        {displayByRowMeasuresTab && this.renderByRowMeasuresTab()}
        {isStratified && this.renderStratificationTab()}
        <Tab
          id={TabIds.Log}
          title={this.renderLogTabTitle()}
          panel={<LogTab analysisDataFile={analysisDataFile} />}
        />
      </Tabs>
    );
  }

  private onTabChange(selectedTabId: TabIds) {
    this.setState({
      selectedTabId
    });
  }

  private renderSummaryMeasuresTab() {
    const calculation = this.props.analysisDataFile.selectedCalculation!;

    return (
      <Tab
        id={TabIds.SummaryMeasures}
        title={TabIds.SummaryMeasures}
        panel={<SummaryMeasuresTab calculation={calculation} />}
      />
    );
  }

  private renderByRowMeasuresTab() {
    const calculation = this.props.analysisDataFile.selectedCalculation!;

    return (
      <Tab
        id={TabIds.ByRowMeasures}
        title={TabIds.ByRowMeasures}
        panel={<ByRowTab calculation={calculation} />}
      />
    );
  }

  private renderStratificationTab() {
    const calculation = this.props.analysisDataFile.selectedCalculation!;

    return (
      <Tab
        id={TabIds.Stratifications}
        title={TabIds.Stratifications}
        panel={
          <StratificationsTab
            analysisDataFile={this.props.analysisDataFile}
            calculation={calculation}
          />
        }
      />
    );
  }

  /**
   * @description Render Log Tab title, including error or warning icons if calculation
   * has errors or warnings
   */
  private renderLogTabTitle() {
    const { warnings, errors } = this.props.analysisDataFile.selectedCalculation!;

    return (
      <div>
        {TabIds.Log}
        {(errors.length > 0 || warnings.length > 0) && ' '}
        {errors.length > 0 && <Icon intent={Intent.DANGER} icon='error' />}
        {warnings.length > 0 && <Icon intent={Intent.WARNING} icon={'warning-sign'} />}
      </div>
    );
  }
}
