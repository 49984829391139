const precision = 3;

export abstract class WorkerUtil {
  /**
   * @description Build function that, when called, will terminate the supplied worker and call
   * a rejection function
   * @param worker Worker
   * @param reject Reject function
   */
  static onError(worker: Worker, reject: (err: any) => void) {
    return (err: any) => {
      worker.terminate();
      reject(err);
    };
  }

  static toPrecision(value: number) {
    return Number(Number(value.toFixed(precision)).toPrecision(precision));
  }
}
