import './polyfill';

import * as React from 'react';
import { App } from './components/smart/App/App';
import { render } from 'react-dom';

document.addEventListener(
    'DOMContentLoaded',
    () => {
        render(<App />, document.getElementById('react-app'));
    },
    false
);
