import './stratification-suggest.scss';

import classNames from 'classnames';
import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { MenuItem, Tag } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { TutorialContext } from '../Tutorial/Tutorial';

const VariableSuggest = MultiSelect.ofType<string>();

interface IStratificationSuggestState {
  variableQuery: string;
  activeVariable: string;
}

export interface IStratificationSuggestProps {
  analysisDataFile: AnalysisDataFile;
}

@observer
@autobind
export class StratificationSuggest extends React.Component<
  IStratificationSuggestProps,
  IStratificationSuggestState
> {
  constructor(props: IStratificationSuggestProps) {
    super(props);

    this.state = {
      variableQuery: '',
      activeVariable: ''
    };
  }

  public render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;
    const matchingVariables = this.getMatchingVariables();
    const disabled = !analysisDataFile.canRunCalculations;

    return (
      <TutorialContext.Consumer>
        {({ getIsActive }) => (
          <VariableSuggest
            selectedItems={activeCalculation.stratifications}
            itemDisabled={() => disabled}
            query={this.state.variableQuery}
            onQueryChange={this.onQueryChange}
            items={matchingVariables}
            itemRenderer={this.renderItem}
            tagRenderer={this.renderTag}
            onItemSelect={this.onItemSelect}
            activeItem={this.state.activeVariable}
            onActiveItemChange={this.onActiveItemChange}
            noResults='No matching variables found'
            popoverProps={{
              portalContainer: document.body,
              minimal: true,
              popoverClassName: 'standard-select',
              targetClassName: 'multi-select-large',
              portalClassName: classNames({
                'tutorial-is-modal': getIsActive()
              })
            }}
            placeholder='Search variables'
            tagInputProps={{
              disabled,
              onRemove: this.onRemoveTag
            }}
          />
        )}
      </TutorialContext.Consumer>
    );
  }

  private getMatchingVariables() {
    const { analysisDataFile } = this.props;

    return analysisDataFile.getMatchingVariables(this.state.variableQuery).filter((variable) => {
      // Remove recognized variables that don't have categories. Allow unknown, custom variables
      const matchingVariable = analysisDataFile.getVariableByName(variable);
      if (matchingVariable) {
        if (!matchingVariable.categories.length) return false;
      }

      // Remove variables have already been selected
      if (analysisDataFile.activeCalculation.stratifications.includes(variable)) return false;

      return true;
    });
  }

  private renderItem(variable: string) {
    const variableLabel = this.props.analysisDataFile.getVariableLabels(variable);

    return (
      <MenuItem
        key={variable}
        text={variable}
        disabled={!this.props.analysisDataFile.canRunCalculations}
        onClick={() => this.onItemSelect(variable)}
        active={variable === this.state.activeVariable}
        label={variableLabel.label}
      />
    );
  }

  private renderTag(variableName: string) {
    return (
      <Tag className='variable-name-tag' key={variableName}>
        {variableName}
      </Tag>
    );
  }

  private onQueryChange(query: string) {
    this.setState({
      variableQuery: query
    });
  }

  private onItemSelect(variable: string) {
    if (variable) {
      this.setState({
        variableQuery: '',
        activeVariable: ''
      });

      this.props.analysisDataFile.activeCalculation.stratifications.push(variable);
    }
  }

  private onActiveItemChange(variable: string | null) {
    if (variable) {
      this.setState({
        activeVariable: variable
      });
    }
  }

  private onRemoveTag(_category: string, index: number) {
    this.props.analysisDataFile.activeCalculation.stratifications.splice(index, 1);
  }
}
