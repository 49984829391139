import './summary-measures-tab.scss';

import React from 'react';

import { Calculation } from '../../../store/domain/calculation';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { H5, HTMLTable } from '@blueprintjs/core';
import { c } from '../../../store/domain/c';
import { ScenarioTypes } from '../../../store/domain/scenario';
import { AlgorithmMeasure } from '../../../store/domain/algorithm-measure';
import { Store } from '../../../store/store';

export interface ISummaryMeasuresTabProps {
  calculation: Calculation;
  algorithmMeasure?: AlgorithmMeasure;
}

@inject((store: Store) => {
  return {
    algorithmMeasure: store.algorithmMeasure
  };
})
@observer
@autobind
export class SummaryMeasuresTab extends React.Component<ISummaryMeasuresTabProps> {
  render() {
    const { calculation } = this.props;

    const summaryMeasures = this.props.algorithmMeasure!.SummaryMeasures.filter((measure) =>
      calculation.measures.includes(measure)
    );
    const renderScenarioRows = calculation.scenarioType !== ScenarioTypes.None;

    return (
      <div>
        <H5>Summary Measures</H5>
        * All summary measures are weighted
        <br />
        <HTMLTable bordered condensed striped>
          <thead>
            <tr>
              <th>Measure</th>
              <th>Label</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {summaryMeasures.map((measure) => (
              <tr key={measure}>
                <td>{measure}</td>
                <td>{c.measureToCsvHeaderMap[measure]}</td>
                <td>{calculation.getSummaryMeasureValue(measure)}</td>
              </tr>
            ))}
            {renderScenarioRows && this.renderScenarioRows()}
            <tr>
              <td>Population</td>
              <td>Population</td>
              <td>{calculation.population}</td>
            </tr>
            <tr>
              <td>Sample size</td>
              <td>SampleSize</td>
              <td>{calculation.sampleSize}</td>
            </tr>
          </tbody>
        </HTMLTable>
      </div>
    );
  }

  private renderScenarioRows() {
    const summaryMeasures = this.props.calculation.activeMeasuresUsableForScenarios;

    return summaryMeasures.map((measure) => (
      <tr key={measure}>
        <td>
          {c.scenarioCsvHeaderPrefix} - {measure}
        </td>
        <td>{c.scenarioCsvHeaderPrefix.concat(c.measureToCsvHeaderMap[measure])}</td>
        <td>{this.props.calculation.getScenarioMeasureValue(measure)}</td>
      </tr>
    ));
  }
}
