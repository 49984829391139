import { Calculation } from '../../store/domain/calculation';
import { AnalysisDataFile } from '../../store/domain/analysis-data-file';
import { toJS } from 'mobx';
//@ts-ignore
import StratifyWorker from './stratify.worker';
import { IStratifyInitialMessage, IStratifyMessage, StratifyEventType } from './stratify-events';
import { store } from '../../store/store';

export abstract class StratifyHandler {
  static stratifyCalculation(analysisDataFile: AnalysisDataFile, calculation: Calculation) {
    const worker: Worker = new StratifyWorker();
    calculation.worker = worker;

    const summaryMeasures = calculation.measures.filter((measure) =>
      store.algorithmMeasure.SummaryMeasures.includes(measure)
    );
    const categoricalVariableValues = analysisDataFile.buildFilteredCategoricalVariableValues(
      calculation.activeFilters
    );

    worker.postMessage({
      fileOrUrl: analysisDataFile.fileOrUrl,
      filters: toJS(calculation.activeFilters, { recurseEverything: true }),
      categoricalVariableValues: toJS(categoricalVariableValues, { recurseEverything: true }),
      stratifications: toJS(calculation.stratifications),
      measures: toJS(summaryMeasures),
      modelJson: toJS(calculation.model, { recurseEverything: true }),
      debugger: toJS(store.debugger, { recurseEverything: true })
    } as IStratifyInitialMessage);

    worker.onmessage = (message: { data: IStratifyMessage }) => {
      const { data } = message;

      switch (data.type) {
        case StratifyEventType.Done: {
          data.strata.forEach((stratum) =>
            stratum.stratifications.forEach(
              (stratification) =>
                (stratification.label = analysisDataFile.getLabelForCategory(
                  stratification.variableName,
                  stratification.category as string
                ))
            )
          );
          calculation.completeStratifying(data.strata);

          worker.terminate();
          break;
        }
        case StratifyEventType.Error:
          calculation.error = data.error;
          calculation.cancel();
          break;
      }
    };
  }
}
