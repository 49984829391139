import './calculation-complete-toast.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { Toast, Intent } from '@blueprintjs/core';

export interface ICalculationCompleteToastProps {
    calculationName: string;
    fileName: string;
    onDismiss: () => void;
}

export const CalculationCompleteToast: React.SFC<
    ICalculationCompleteToastProps
> = observer(
    ({
        calculationName,
        fileName,
        onDismiss
    }: ICalculationCompleteToastProps) => {
        return (
            <Toast
                className='calculation-complete-toast'
                message={
                    <div>
                        <p className='calculation-complete-toast__message'>
                            Calculation <b>{calculationName}</b>{' '}
                            completed on file <b>{fileName}</b>.
                        </p>
                    </div>
                }
                onDismiss={onDismiss}
                icon='tick'
                intent={Intent.SUCCESS}
            />
        );
    },
);
