import { Calculation } from '../../store/domain/calculation';
import { AnalysisDataFile } from '../../store/domain/analysis-data-file';
import { toJS } from 'mobx';
//@ts-ignore
import LongTableWorker from './long-table.worker';
import {
  LongTableMessageEventType,
  LongTableExportFilePostMessage,
  LongTableInitialMessage
} from './long-table-events';
import { Measures } from '../../store/domain/measures';
import { WorkerUtil } from '../../store/domain/utils/worker-util';

export abstract class LongTableHandler {
  public static makeLongTable(
    analysisDataFile: AnalysisDataFile,
    calculation: Calculation,
    measures: Measures[]
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const worker: Worker = new LongTableWorker();
      const onWorkerError = WorkerUtil.onError(worker, reject);
      worker.onerror = onWorkerError;
      let fileAsString = '';

      worker.postMessage({
        file: analysisDataFile.file,
        strata: toJS(calculation.strata, { recurseEverything: true }),
        stratifications: toJS(calculation.stratifications),
        measures: toJS(measures)
      } as LongTableInitialMessage);

      worker.onmessage = (message: { data: LongTableExportFilePostMessage }) => {
        const { data } = message;

        switch (data.type) {
          case LongTableMessageEventType.ProgressUpdate: {
            fileAsString += data.row;
            break;
          }

          case LongTableMessageEventType.Done:
            return resolve(fileAsString);
          default:
            onWorkerError(new Error(`Unknown message type: ${message.data.type}`));
            break;
        }
      };
    });
  }
}
