import './invalid-import-dialog.scss';

import React from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Dialog } from '@blueprintjs/core';

export interface IInvalidImportDialogProps {
  isOpen: boolean;
  title: string;
  error: string;
  onClose: () => void;
}

@observer
@autobind
export class InvalidImportDialog extends React.Component<IInvalidImportDialogProps> {
  render() {
    return (
      <Dialog
        className='standard-dialog'
        isOpen={this.props.isOpen}
        title={this.props.title}
        portalContainer={document.body}
        onClose={this.props.onClose}
        lazy
      >
        <div className='standard-dialog-content'>{this.props.error}</div>
      </Dialog>
    );
  }
}
``;
