import { toJS } from 'mobx';
//@ts-ignore
import VariableLabelWorker from './variable-label.worker';
import {
  IVariableLabelInitialMessage,
  VariableLabelMessageEventType,
  VariableLabelPostMessage
} from './variable-label-events';
import { AnalysisDataFile } from '../../store/domain/analysis-data-file';

export abstract class VariableLabelHandler {
  static buildVariableLabels(analysisDataFile: AnalysisDataFile): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!analysisDataFile.hasLoadedVariables) return resolve();

      const worker: Worker = new VariableLabelWorker();

      analysisDataFile.hasLoadedVariableLabels = false;

      worker.postMessage({
        variables: toJS(analysisDataFile.variables)
      } as IVariableLabelInitialMessage);

      worker.onmessage = (message: { data: VariableLabelPostMessage }) => {
        const { data } = message;

        switch (data.type) {
          case VariableLabelMessageEventType.Done: {
            analysisDataFile.variableLabels = data.variableLabels;
            resolve();
            break;
          }

          case VariableLabelMessageEventType.Error: {
            reject();
            break;
          }
        }

        analysisDataFile.hasLoadedVariableLabels = true;
        worker.terminate();
      };
    });
  }
}
