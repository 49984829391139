import { observable, computed } from 'mobx';

export class NotificationPrompt {
  @observable
  permission: typeof Notification.permission = Notification.permission;

  @computed
  get hasSetPermission() {
    return this.permission !== 'default';
  }
}
