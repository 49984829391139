import './notification-dialog.scss';

import React from 'react';
import { Dialog, Classes } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { NotificationToggleButton } from '../../smart/NotificationToggleButton';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { NotificationPrompt } from '../../../store/domain/notification-prompt';
import { Tutorial } from '../../../store/domain/tutorial';

export interface INotificationDialogProps {
  notificationPrompt: NotificationPrompt;
  tutorial: Tutorial;
  analysisDataFile?: AnalysisDataFile;
}

interface INotificationDialogState {
  hasBeenPrompted: boolean;
}

@autobind
@observer
export class NotificationDialog extends React.Component<
  INotificationDialogProps,
  INotificationDialogState
> {
  constructor(props: INotificationDialogProps) {
    super(props);

    this.state = {
      hasBeenPrompted: false
    };
  }

  render() {
    const { analysisDataFile } = this.props;
    const isOpen =
      !!analysisDataFile &&
      analysisDataFile.calculations.length === 1 &&
      this.props.notificationPrompt.permission === 'default' &&
      !this.props.notificationPrompt.hasSetPermission &&
      !this.state.hasBeenPrompted &&
      !this.props.tutorial.isActive;

    return (
      <Dialog
        isOpen={isOpen}
        title='Notifications'
        onClose={this.onClose}
        portalContainer={document.body}
        lazy
      >
        <div className='standard-dialog-content'>
          <div>
            Calculations can take an hour to complete, or even longer, depending on the file
            selected, the calculation complexity, and your machine.
          </div>
          <br />
          <div>Enable notifications to receive a notification when calculations are complete.</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <NotificationToggleButton />
          </div>
        </div>
      </Dialog>
    );
  }

  private onClose() {
    this.setState({
      hasBeenPrompted: true
    });
  }
}
