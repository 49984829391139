import './stratification-manager.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { AnalysisDataFile } from '../../../store/domain/analysis-data-file';
import { Collapsible } from '../Collapsible';
import { Tutorial } from '../../../store/domain/tutorial';
import { TutorialStep } from '../Tutorial/TutorialStep';
import { StratificationSuggest } from '../StratificationSuggest';
import { HelperContainer } from '../HelperContainer';
import { Store } from '../../../store/store';
import { TutorialSteps } from '../../../store/domain/tutorial-step';

export interface IStratificationManagerProps {
  analysisDataFile: AnalysisDataFile;
  tutorial?: Tutorial;
}

@inject((store: Store) => {
  return {
    tutorial: store.tutorial
  };
})
@observer
@autobind
export class StratificationManager extends React.Component<IStratificationManagerProps> {
  render() {
    const { analysisDataFile } = this.props;
    const { activeCalculation } = analysisDataFile;

    const stratificationNames = activeCalculation.stratifications.join(', ');

    return (
      <TutorialStep
        stepComponent={TutorialSteps.SetStratification}
        tutorialMessage={this.renderTutorialMessage()}
        isTutorialStepComplete={this.isTutorialStepComplete}
        showContinueButton={false}
      >
        <Collapsible header='Stratifications' summaryMessage={stratificationNames} startOpen>
          <HelperContainer content={this.renderHelperContent()} alignment='baseline'>
            <StratificationSuggest analysisDataFile={analysisDataFile} />
          </HelperContainer>
        </Collapsible>
      </TutorialStep>
    );
  }

  private renderTutorialMessage() {
    const tutorial = this.props.tutorial!;

    return (
      <div>
        <div>
          We can also add <i>Stratifications</i> to stratify results. Selected variables will be
          stratified on.
        </div>
        <div>
          Only <i>Summary measures</i> can be stratified. If any stratifications are selected, all{' '}
          <i>By Row measures</i> will be disabled.
        </div>
        <br />
        <div>
          Click the <i>{tutorial.searchVariablesText}</i> field and select the{' '}
          <i>{tutorial.stratificationToSelect}</i> variable. If you can't find the variable, begin
          typing the variable name to filter the results.
        </div>
      </div>
    );
  }

  private isTutorialStepComplete() {
    const { stratifications } = this.props.analysisDataFile.activeCalculation;
    return (
      stratifications.length === 1 &&
      stratifications.includes(this.props.tutorial!.stratificationToSelect)
    );
  }

  private renderHelperContent() {
    return (
      <div>
        <div>
          Stratifications only apply to <i>Summary measures</i>.
        </div>
        <div>
          If any stratifications are selected, all <i>By row</i> measures will be disabled.
        </div>
      </div>
    );
  }
}
